var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"session page"},[_c('v-card',{staticClass:"mx-auto",attrs:{"tile":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("說明：")]),_c('br'),_c('v-list-item-subtitle',[_vm._v("設定為當前屆數，此屆數當作為報名屆數。")])],1)],1)],1),_c('Add',{on:{"editData":_vm.editData}}),_c('div',{staticClass:"table-block"},[_c('div',{staticClass:"total"},[_vm._v(" 總共: "+_vm._s(_vm.total)+" 筆資料 ")]),_c('v-data-table',{staticClass:"data-table",attrs:{"headers":_vm.ApplyHeader,"items":_vm.list,"options":_vm.options,"items-per-page":10,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDate(item.startTime)))])]}},{key:"item.endTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDate(item.endTime)))])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-icon',{attrs:{"large":"","color":"green darken-2"}},[_vm._v(" mdi-check-circle-outline ")]):_vm._e()]}},{key:"item.operate",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"dark":"","small":"","depressed":"","disabled":_vm.isOperate || item.active,"color":"blue"},on:{"click":function($event){return _vm.getActive(item)}}},[_vm._v(" 設定為當前屆數 ")]),_c('v-btn',{staticClass:"mx-1",attrs:{"dark":"","small":"","depressed":"","disabled":_vm.isOperate,"color":"green"},on:{"click":function($event){return _vm.editData(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"mx-1 delete-btn",attrs:{"dark":"","small":"","depressed":"","disabled":_vm.isOperate,"color":"red"},on:{"click":function($event){return _vm.deleteData(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-trash-can ")])],1)]}}])})],1),_c('SessionDialog',{ref:"child",attrs:{"edit-dialog":_vm.editDialog},on:{"close":_vm.closeEdit,"saveEdit":_vm.saveEdit,"saveData":_vm.saveData,"search":_vm.search}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }