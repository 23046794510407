<template>
    <div class="nav">
        <div class="logo">
            <img
                src="../assets/logo.png"
                alt="Vision Future"
            >
        </div>
        <div class="menu">
            <div
                v-if="isPermission('judge')"
                class="group"
            >
                <div class="title">
                    評審帳號管理
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'JudgeInvention' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'JudgeInvention',
                        }"
                    >
                        慈悲科技
                    </router-link>
                    <router-link
                        :to="{ name: 'JudgeIncubator' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'JudgeIncubator',
                        }"
                    >
                        青年公益
                    </router-link>
                </div>
            </div>
            <div
                v-if="isPermission('member')"
                class="group"
            >
                <div class="title">
                    會員資料管理
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'Member' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'Member',
                        }"
                    >
                        會員資料
                    </router-link>
                </div>
            </div>
            <div
                v-if="isPermission('home')"
                class="group"
            >
                <div class="title">
                    首頁管理
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'HomeBanner' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'HomeBanner',
                        }"
                    >
                        Banner
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'HomeRecommend' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'HomeRecommend',
                        }"
                    >
                        好評推薦
                    </router-link>
                </div>
            </div>
            <!-- 慈悲科技創新競賽管理 -->
            <div
                v-if="isPermission('invention')"
                class="group"
            >
                <div class="title">
                    慈悲科技創新競賽管理
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'InventionContent' }"
                        class="item"
                        :class="{
                            'router-active':
                                activeMenuItem === 'InventionContent',
                        }"
                    >
                        內容
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'InventionPrize' }"
                        class="item"
                        :class="{
                            'router-active':
                                activeMenuItem === 'InventionPrize',
                        }"
                    >
                        獎勵方式
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'InventionQA' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'InventionQA',
                        }"
                    >
                        常見問題
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'InventionRecommend' }"
                        class="item"
                        :class="{
                            'router-active':
                                activeMenuItem === 'InventionRecommend',
                        }"
                    >
                        好評推薦
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'InventionHistoryTeam' }"
                        class="item"
                        :class="{
                            'router-active':
                                activeMenuItem === 'InventionHistoryTeam',
                        }"
                    >
                        歷屆團隊
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'InventionManageApply' }"
                        class="item"
                        :class="{
                            'router-active':
                                activeMenuItem === 'InventionManageApply',
                        }"
                    >
                        報名資料
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'InventionSession' }"
                        class="item"
                        :class="{
                            'router-active':
                                activeMenuItem === 'InventionSession',
                        }"
                    >
                        屆數
                    </router-link>
                </div>
            </div>
            <!-- 青年公益實踐計畫管理 -->
            <div
                v-if="isPermission('incubator')"
                class="group"
            >
                <div class="title">
                    青年公益實踐計畫管理
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'IncubatorContent' }"
                        class="item"
                        :class="{
                            'router-active':
                                activeMenuItem === 'IncubatorContent',
                        }"
                    >
                        內容
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'IncubatorQA' }"
                        class="item"
                        :class="{
                            'router-active':
                                activeMenuItem === 'IncubatorQA',
                        }"
                    >
                        常見問題
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'IncubatorRecommend' }"
                        class="item"
                        :class="{
                            'router-active':
                                activeMenuItem === 'IncubatorRecommend',
                        }"
                    >
                        好評推薦
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'IncubatorTeacher' }"
                        class="item"
                        :class="{
                            'router-active':
                                activeMenuItem === 'IncubatorTeacher',
                        }"
                    >
                        歷屆業師
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'IncubatorHistoryTeam' }"
                        class="item"
                        :class="{
                            'router-active':
                                activeMenuItem === 'IncubatorHistoryTeam',
                        }"
                    >
                        歷屆團隊
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'IncubatorManageApply' }"
                        class="item"
                        :class="{
                            'router-active':
                                activeMenuItem === 'IncubatorManageApply',
                        }"
                    >
                        報名資料
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'IncubatorSession' }"
                        class="item"
                        :class="{
                            'router-active':
                                activeMenuItem === 'IncubatorSession',
                        }"
                    >
                        屆數
                    </router-link>
                </div>
            </div>
            <!-- 公益教育 -->
            <div
                v-if="isPermission('academy')"
                class="group"
            >
                <div class="title">
                    公益教育
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'AcademyBanner' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'AcademyBanner',
                        }"
                    >
                        Banner
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'AcademyThink' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'AcademyThink',
                        }"
                    >
                        公益思塾
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'AcademyWalk' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'AcademyWalk',
                        }"
                    >
                        公益走讀
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'AcademyHistory' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'AcademyHistory',
                        }"
                    >
                        歷史活動
                    </router-link>
                </div>
            </div>
            <!-- 公益研究 -->
            <div
                v-if="isPermission('study')"
                class="group"
            >
                <div class="title">
                    公益研究
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'StudyBanner' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'StudyBanner',
                        }"
                    >
                        Banner
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'SORIReport' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'SORIReport',
                        }"
                    >
                        SORI報告
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'StudyReport' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'StudyReport',
                        }"
                    >
                        影響力報告書
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'StudyHistory' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'StudyHistory',
                        }"
                    >
                        歷屆團隊公益報告書
                    </router-link>
                </div>
            </div>
            <!-- 公益資訊 -->
            <div
                v-if="isPermission('news')"
                class="group"
            >
                <div class="title">
                    公益資訊
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'NewsBanner' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'NewsBanner',
                        }"
                    >
                        Banner
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'NewsTagging' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'NewsTagging',
                        }"
                    >
                        標籤
                    </router-link>
                </div>
                <div class="sub-menu">
                    <router-link
                        :to="{ name: 'NewsArticle' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'NewsArticle',
                        }"
                    >
                        文章
                    </router-link>
                </div>
            </div>
        </div>
        <div class="bottom">
            <v-btn
                elevation="2"
                color="#464646"
                class="change-password btn"
                @click="goto('ChangePassword')"
            >
                更改密碼
            </v-btn>
            <v-btn
                elevation="2"
                color="#464646"
                class="logout btn"
                @click="logout"
            >
                LOGOUT
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {},
    data() {
        return {
            activeMenuItem: '',
        };
    },
    computed: {
        ...mapGetters('mAuth', ['permissions']),
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        $route(to, from) {
            this.checkActiveRouter();
        },
    },
    created() {
        this.checkActiveRouter();
    },
    mounted() { },
    methods: {
        ...mapActions('mAuth', ['Logout']),
        isPermission(value) {
            return this.permissions.includes(value) || this.permissions.includes('*');
        },
        checkActiveRouter() {
            this.activeMenuItem = this.$route.name;
        },
        logout() {
            this.Logout();
            this.goto('Login');
        },
        goto(routerName) {
            this.$router.push({ name: routerName });
        },
    },
};
</script>

<style lang="scss" scoped>
.nav {
    width: 300px;
    height: 100vh;
    overflow: auto;
    // min-height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0,0,0,0.3) 3px 0 10px;

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 1rem;
        margin: 15px;
        padding: 20px;
        img{
            width: 100%;
        }
    }

    .menu {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        .group {
            width: 100%;
        }

        .title {
            color: white;
            font-size: 24px;
            line-height: 1.2;
            letter-spacing: 5px;
            padding: 15px 20px;
        }

        .sub-menu {
            .item {
                width: 100%;
                color: white;
                text-decoration: none;
                font-weight: 400;
                font-size: 20px;
                line-height: 1.2;
                letter-spacing: 5px;
                padding: 10px 40px;
                display: block;
            }
            .item:not(:last-child) {
                margin-bottom: 10px;
            }

            .router-active {
                background-color: white;
                color: black;
            }
        }
    }
    .bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        margin-bottom: 40px;
        margin-top: 40px;
        .btn {
            width: 245px;
            color: white;
        }

        .btn:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}
</style>
