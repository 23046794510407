<template>
    <div class="judge-invention page">
        <Add
            @editData="editData"
        />
        <div class="table-block">
            <div class="total">
                總共: {{ total }} 筆資料
            </div>
            <v-data-table
                :headers="ApplyHeader"
                :items="list"
                :options.sync="options"
                :items-per-page="10"
                :server-items-length="total"
                :loading="loading"
                class="data-table"
                :footer-props="footerProps"
            >
                <template v-slot:item.operate="{ item }">
                    <v-btn
                        class="mx-1"
                        dark
                        small
                        depressed
                        :disabled="isOperate"
                        color="green"
                        @click="editData(item)"
                    >
                        <v-icon dark>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                    <v-btn
                        class="mx-1 delete-btn"
                        dark
                        small
                        depressed
                        :disabled="isOperate"
                        color="red"
                        @click="deleteData(item)"
                    >
                        <v-icon dark>
                            mdi-trash-can
                        </v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.password="{ item }">
                    <v-btn
                        class="mx-1 delete-btn"
                        dark
                        small
                        depressed
                        :disabled="isOperate"
                        color="primary"
                        @click="resetData(item)"
                    >
                        <v-icon dark>
                            mdi-lock-reset
                        </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </div>
        <JudgeDialog
            ref="child"
            :edit-dialog="editDialog"
            :title="'incubator'"
            @close="closeEdit"
            @saveData="saveData"
        />
    </div>
</template>

<script>
import Mixin from '@/mixins/Mixin.vue';
import Add from '@/components/UI/Add.vue';
import JudgeDialog from '@/components/Dialog/JudgeDialog.vue';
import ApplyHeader from '@/static-data/judge-incubator-header.json';
import API from '@/api';

export default {
    components: { Add, JudgeDialog },
    mixins: [Mixin],
    data() {
        return {
            ApplyHeader,
        };
    },
    computed: {},
    watch: {
        options: {
            handler() {
                this.init();
            },
            deep: true,
        },
    },
    created() {
        this.init();
    },
    mounted() {},
    methods: {
        async init() {
            this.loading = true;
            try {
                const { page, itemsPerPage } = this.options;
                const res = await API.JudgeResource.PrivateList({
                    params: {
                        offset: (page - 1) * itemsPerPage,
                        limit: itemsPerPage,
                        filter: 'data.type:incubator',
                    },
                });
                this.list = res.data.raw;
                this.total = res.data.total;
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.handleError({
                    message: e || '搜尋異常',
                    color: 'error',
                });
            }
            this.isOperate = false;
        },
        async deleteData(item) {
            this.isOperate = true;
            try {
                // 刪除
                await API.JudgeResource.PrivateDeleteResource(item.id);
            } catch (e) {
                this.handleError({
                    message: e || '刪除異常',
                    color: 'error',
                });
            }
            this.init();
        },
        async saveData(item) {
            try {
                if (item.id) {
                // 修改
                    const data = {
                        name: item.name,
                        type: 'incubator',
                        groupIssues: {
                            '臺灣團隊-孵化組': item.group.incubation,
                            '臺灣團隊-加速組': item.group.acceleration,
                            亞太地區團隊: item.group.asia,
                        },
                    };
                    await API.JudgeResource.PrivateUpdateResource(item.id, { $set: data }, {
                        params: {
                            stage: 'judge-data',
                        },
                    });
                    this.setAlert({
                        message: '更新成功',
                        color: 'success',
                    });
                } else {
                // 新增
                    const data = {
                        account: item.account,
                        password: item.password,
                        name: item.name,
                        type: 'incubator',
                        groupIssues: {
                            '臺灣團隊-孵化組': item.group.incubation,
                            '臺灣團隊-加速組': item.group.acceleration,
                            亞太地區團隊: item.group.asia,
                        },
                    };
                    await API.JudgeResource.PrivateCreateResource(data);
                    this.setAlert({
                        message: '新增成功',
                        color: 'success',
                    });
                }
            } catch (e) {
                this.handleError({
                    message: e || '新增修改異常',
                    color: 'error',
                });
            }
            this.init();
        },
        async resetData(item) {
            try {
                const data = {
                    password: '000000',
                };
                await API.JudgeResource.PrivateUpdateResource(item.id, { $set: data }, {
                    params: {
                        stage: 'password',
                    },
                });
                this.setAlert({
                    message: '已恢復預設密碼',
                    color: 'success',
                });
            } catch (e) {
                this.handleError({
                    message: e || '異常',
                    color: 'error',
                });
            }
            this.init();
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
