<script>
import Editor from '@tinymce/tinymce-vue';
import tinymce from 'tinymce';

import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.css';

/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';

/* Import content css */
import contentUiCss from 'tinymce/skins/ui/oxide/content.css';
import contentCss from 'tinymce/skins/content/default/content.css';

export default {
    components: {
        Editor,
    },
    props: {
        menubar: {
            type: [String, Array],
            default: 'file edit view insert format tools table help',
        },
        plugins: {
            type: [String, Array],
            default: 'code link table advlist lists emoticons image imagetools',
        },
        toolbar: {
            type: [String, Array],
            default: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | link image',
        },
    },
    data() {
        return {
            init: {
                height: 300,
                width: '100%',
                plugins: this.plugins,
                toolbar: this.toolbar,
                branding: false,
                menubar: this.menubar,
                image_caption: true,
                contextmenu: 'link image imagetools table',
                image_title: true,
                file_picker_types: 'image',
                automatic_uploads: true,
                images_upload_handler: this.handleImageUpload,
                imagetools_cors_hosts: ['picsum.photos'],
                language: 'zh_TW',
                language_url: '/tinymce/lang/zh_TW.js',
                font_formats: 'Montserrat=Montserrat;Noto Sans TC=Noto Sans TC,Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
            },
        };
    },
    computed: {},
    created() {},
    mounted() {
        tinymce.init({
            selector: 'textarea#editor',
            skin: false,
            content_css: false,
            content_style: `@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@500&display=swap');\n${contentUiCss.toString()}\n${contentCss.toString()}`,
        });
    },
    methods: {
        async handleImageUpload(blobInfo, success, failure) {
            try {
                success(URL.createObjectURL(blobInfo.blob()));
            } catch (e) {
                console.log(e);
                failure('上傳失敗，圖片超過1MB，或網路連線異常。');
            }
        },
    },
};
</script>
