<template>
    <div class="judge-list">
        <div
            v-for="item in data"
            :key="item.author.account"
            class="item"
        >
            <div class="apply-form-block">
                <div class="apply-form-block">
                    <div class="left-block">
                        <div class="block-title">
                            評審員
                        </div>
                    </div>
                    <div class="form-content">
                        <div class="text">
                            {{ item.author.account }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="apply-form-block">
                    <div class="left-block">
                        <div class="block-title">
                            評分分數
                        </div>
                    </div>
                    <div
                        v-if="type==='incubator'"
                        class="form-content"
                    >
                        <div class="text">
                            社會影響力 -
                            {{ item.items.find((item) => item.name === '社會影響力').score }}分<br>
                            創新/前瞻性 -
                            {{ item.items.find((item) => item.name === '創新/前瞻性').score }}分<br>
                            計畫可行性 -
                            {{ item.items.find((item) => item.name === '計畫可行性').score }}分<br>
                            團隊執行力 -
                            {{ item.items.find((item) => item.name === '團隊執行力').score }}分<br>
                            總分數 - {{ item.total }}分
                        </div>
                    </div>
                    <div
                        v-if="type==='invention'"
                        class="form-content"
                    >
                        <div class="text">
                            設計理念及主題相符性 -
                            {{ item.items.find((item) => item.name === '設計理念及主題相符性').score }}分<br>
                            創新(獨創)性 -
                            {{ item.items.find((item) => item.name === '創新(獨創)性').score }}分<br>
                            實用性 -
                            {{ item.items.find((item) => item.name === '實用性').score }}分<br>
                            市場性 -
                            {{ item.items.find((item) => item.name === '市場性').score }}分<br>
                            完整性(書面審查資料) -
                            {{ item.items.find((item) => item.name === '完整性(書面審查資料)').score }}分<br>
                            總分數 - {{ item.total }}分
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="apply-form-block"
                style="margin-bottom: 0px;"
            >
                <div class="apply-form-block">
                    <div class="left-block">
                        <div
                            class="block-title"
                        >
                            評語
                        </div>
                    </div>
                    <div class="form-content">
                        <div
                            class="text"
                            style="white-space:pre-wrap;"
                        >
                            {{ item.note }}
                        </div>
                    </div>
                </div>
            </div>
            <v-btn
                class="mx-1 delete-btn"
                dark
                small
                depressed
                height="40"
                color="red"
                @click="deleteCommit(item.id)"
            >
                退回評分
            </v-btn>
        </div>
    </div>
</template>
<script>
import API from '@/api';

export default {
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: 'incubator',
        },
        id: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
        };
    },
    computed: {
    },
    created() {},
    mounted() {},
    methods: {
        async deleteCommit(commit) {
            await API.JudgeCommitUtil.CancelSubmitCommit(this.type, this.id, commit);
            this.$emit('getData');
        },
    },
};
</script>

<style lang="scss" >
.judge-list{
    .item{
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: 1px solid #B1B1B1;
    }
    .text{
        color: #404040;
        font-weight: 700;
        font-size: 16px;
        line-height: 2;
        letter-spacing: 0.1em;
    }
    .delete-btn .v-btn__content{
        font-size: 20px;
        padding: 20px;
    }
}

</style>
