<script>
import { mapMutations } from 'vuex';
import Search from '@/components/Search.vue';
import Add from '@/components/UI/Add.vue';
import moment from 'moment';

export default {
    components: {
        Search, Add,
    },
    data() {
        return {
            isOperate: false,
            editDialog: false,
            list: [],
            editItem: {},
            options: {
                page: 1,
                itemsPerPage: 10,
            },
            total: 0,
            loading: true,
            footerProps: {
                'items-per-page-options': [5, 10, 15, 20],
            },
        };
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        editData(item) {
            this.isOperate = true;
            this.$refs.child.editItem(item);
            this.editDialog = true;
        },
        closeEdit() {
            this.editDialog = false;
            this.isOperate = false;
        },
        saveEdit() {
            this.editDialog = false;
            this.isOperate = false;
        },
        image(item) {
            const file = `https://${process.env.VUE_APP_BASE_URL + item}`;
            return file;
        },
        getDate(date) {
            const Time = new Date(date);
            return moment(Time).format('YYYY-MM-DD HH:mm');
        },
    },
};
</script>
