import _ from 'lodash';
import API from '@/api';
import jwt from 'jsonwebtoken';
import router from '@/router';

const auth = {
    namespaced: true,
    state: {
        scorekeeperToken: '',
        token: '',
        data: null,
        type: 'invention',
    },
    mutations: {
        setToken(state, value) {
            state.token = value;
        },
        logout(state) {
            state.data = null;
            state.token = '';
        },
        setData(state, value) {
            state.data = value;
        },
        setType(state, value) {
            state.type = value;
        },
    },
    actions: {
        Sync({
            state, getters, dispatch, commit,
        }, token) {
            commit('mLoading/setLoading', true, { root: true });
            try {
                commit('setData', jwt.decode(token));
                if (!['manager', 'judge'].includes(state.data.role)) {
                    throw new Error('角色錯誤');
                }
                localStorage.setItem('role', state.data.role);
                commit('setToken', token);
                commit('mLoading/setLoading', false, { root: true });
            } catch (e) {
                dispatch('Logout');
                commit('mLoading/setLoading', false, { root: true });
            }
            if (!getters.isLogin) {
                if (token) {
                    commit('mNotification/handleError', {
                        message: new Error('自動登入失敗 or 權限過期，請重新登入'),
                        color: 'error',
                    }, { root: true });
                }
                dispatch('Logout');
            }
        },
        LoginManager({ commit, dispatch }, data) {
            commit('mLoading/setLoading', true, { root: true });
            return API.Manager.Login(data)
                .then((res) => {
                    const { token } = res.data;
                    if (token) {
                        localStorage.setItem('token', token);
                        commit('setToken', token);
                    }
                    commit('mLoading/setLoading', false, { root: true });
                    dispatch('Sync', token);
                    router.push({ name: 'Home' });
                })
                .catch((e) => {
                    commit('mLoading/setLoading', false, { root: true });

                    const message = _.get(e, 'response.data.message', e.toString());

                    if (message === '帳號未驗證') {
                        router.push({ name: 'RegisterEmailValidate', query: { account: data.account } });
                    }
                    commit('mNotification/handleError', {
                        message: e || '登入失敗',
                        color: 'error',
                    }, { root: true });
                    return e;
                });
        },
        // 評審登入
        LoginJudge({ commit, dispatch }, data) {
            commit('mLoading/setLoading', true, { root: true });
            return API.Judge.Login(data)
                .then((res) => {
                    const { token } = res.data;
                    if (token) {
                        localStorage.setItem('token', token);
                        commit('setToken', token);
                    }
                    commit('mLoading/setLoading', false, { root: true });
                    dispatch('Sync', token);
                    router.push({ name: 'Home' });
                })
                .catch((e) => {
                    commit('mLoading/setLoading', false, { root: true });

                    const message = _.get(e, 'response.data.message', e.toString());

                    if (message === '帳號未驗證') {
                        router.push({ name: 'RegisterEmailValidate', query: { account: data.account } });
                    }
                    commit('mNotification/handleError', {
                        message: e || '登入失敗',
                        color: 'error',
                    }, { root: true });
                    return e;
                });
        },
        Logout({ commit }) {
            localStorage.removeItem('token');
            commit('logout');
            router.push('/login');
        },
    },
    getters: {
        permissions: (state, getters) => {
            if (!getters.isLogin) return false;
            return _.get(state.data, 'permissions', []);
        },
        role: (state, getters) => {
            if (!getters.isLogin) return false;
            return _.get(state.data, 'role', '');
        },
        isLogin: (state) => {
            if (!state.data) return false;
            return Date.now() <= state.data.exp * 1000;
        },
        getRole: (state) => state.data.role,
    },
};

export default auth;
