<template>
    <div class="backstage-account">
        後台帳號管理
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>

</style>
