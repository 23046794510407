var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"study-banner page"},[_c('Add',{on:{"editData":_vm.editData}}),_c('div',{staticClass:"table-block"},[_c('div',{staticClass:"total"},[_vm._v(" 總共: "+_vm._s(_vm.total)+" 筆資料 ")]),_c('v-data-table',{staticClass:"data-table",attrs:{"headers":_vm.ApplyHeader,"items":_vm.list,"options":_vm.options,"items-per-page":10,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.imageDes.zhTw",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"hidden-img"},[_c('v-img',{staticClass:"data-img",attrs:{"src":_vm.image(item.imageDes.zhTw)}})],1)]}},{key:"item.imageMob.zhTw",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"hidden-mob-img"},[_c('v-img',{staticClass:"data-img",attrs:{"src":_vm.image(item.imageMob.zhTw)}})],1)]}},{key:"item.imageDes.en",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"hidden-img"},[_c('v-img',{staticClass:"data-img",attrs:{"src":_vm.image(item.imageDes.en)}})],1)]}},{key:"item.imageMob.en",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"hidden-mob-img"},[_c('v-img',{staticClass:"data-img",attrs:{"src":_vm.image(item.imageMob.en)}})],1)]}},{key:"item.operate",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"dark":"","small":"","depressed":"","disabled":_vm.isOperate,"color":"green"},on:{"click":function($event){return _vm.editData(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"mx-1 delete-btn",attrs:{"dark":"","small":"","depressed":"","disabled":_vm.isOperate,"color":"red"},on:{"click":function($event){return _vm.deleteData(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-trash-can ")])],1)]}}])})],1),_c('BannerDialog',{ref:"child",attrs:{"edit-dialog":_vm.editDialog},on:{"close":_vm.closeEdit,"saveEdit":_vm.saveEdit,"saveData":_vm.saveData,"search":_vm.search}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }