<template>
    <div class="news-article page">
        <Search
            :show-article-types="true"
            :show-tags="true"
            :text="'標題/描述'"
            @search="search"
        />
        <Add
            @editData="editData"
        />
        <div class="table-block">
            <div class="total">
                總共: {{ total }} 筆資料
            </div>
            <v-data-table
                :headers="ApplyHeader"
                :items="list"
                :options.sync="options"
                :items-per-page="10"
                :loading="loading"
                class="data-table"
                :footer-props="footerProps"
            >
                <template v-slot:item.image.zhTw="{ item }">
                    <v-img
                        :src="image(item.image.zhTw)"
                        class="data-img"
                    />
                </template>
                <template v-slot:item.image.en="{ item }">
                    <v-img
                        :src="image(item.image.en)"
                        class="data-img"
                    />
                </template>
                <template v-slot:item.operate="{ item }">
                    <v-btn
                        class="mx-1"
                        dark
                        small
                        depressed
                        :disabled="isOperate"
                        color="green"
                        @click="editData(item)"
                    >
                        <v-icon dark>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                    <v-btn
                        class="mx-1 delete-btn"
                        dark
                        small
                        depressed
                        :disabled="isOperate"
                        color="red"
                        @click="deleteData(item)"
                    >
                        <v-icon dark>
                            mdi-trash-can
                        </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </div>
        <TagRouterDialog
            ref="child"
            :edit-dialog="editDialog"
            @close="closeEdit"
            @saveEdit="saveEdit"
            @saveData="saveData"
            @search="search"
        />
    </div>
</template>

<script>
import API from '@/api';
import Mixin from '@/mixins/Mixin.vue';
import APIFormData from '@/api/libs/form-data';
import ApplyHeader from '@/static-data/news-manage-article-apply-header.json';
import TagRouterDialog from '@/components/Dialog/TagRouterDialog.vue';

export default {
    components: { TagRouterDialog },
    mixins: [Mixin],
    data() {
        return {
            ApplyHeader,
        };
    },
    watch: {
    },
    created() {
        this.search();
    },
    mounted() {},
    methods: {
        async deleteData(item) {
            this.isOperate = true;
            try {
                // 刪除
                await API.Article.PrivateDeleteResource(item.id);
            } catch (e) {
                this.handleError({
                    message: e || '刪除異常',
                    color: 'error',
                });
            }
            this.search();
        },
        async search(filterItem = {}) {
            // 搜尋
            const {
                articleType, tags, text,
            } = filterItem;
            this.loading = true;
            try {
                const res = await API.Article.PrivateList({
                    params: {
                        search: text || undefined,
                    },
                });
                // eslint-disable-next-line max-len
                this.list = res.data.raw.filter((value) => (typeof articleType !== 'undefined' && articleType.length ? articleType.includes(value.category) : true)).filter((value) => (typeof tags !== 'undefined' && tags.length ? (value.tags.zhTw.map((item) => (tags.includes(item)))).includes(true) : true));

                this.total = this.list.length;
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.handleError({
                    message: e || '搜尋異常',
                    color: 'error',
                });
            }
            this.isOperate = false;
        },
        async saveData(item) {
            const data = {
                category: item.category,
                image: {
                    zhTw: item.image.zhTw,
                    en: item.image.en,
                },
                title: {
                    zhTw: item.title.zhTw,
                    en: item.title.en,
                },
                description: {
                    zhTw: item.description.zhTw,
                    en: item.description.en,
                },
                content: {
                    zhTw: item.content.zhTw,
                    en: item.content.en,
                },
                tags: {
                    zhTw: item.tags.zhTw,
                    en: item.tags.en,
                },
                images: item.images,
            };

            const form = new APIFormData(data, [{
                urls: [
                    item.image.zhTw,
                    item.image.en,
                    ...item.images,
                ],
            }]);
            try {
                if (item.id) {
                // 修改
                    await API.Article.PrivateUpdateResource(item.id, form);
                    this.setAlert({
                        message: '更新成功',
                        color: 'success',
                    });
                } else {
                // 新增
                    await API.Article.PrivateCreateResource(form);
                    this.setAlert({
                        message: '新增成功',
                        color: 'success',
                    });
                }
            } catch (e) {
                this.handleError({
                    message: e || '新增修改異常',
                    color: 'error',
                });
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
