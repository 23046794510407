var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav"},[_vm._m(0),_c('div',{staticClass:"menu"},[(_vm.isPermission('judge'))?_c('div',{staticClass:"group"},[_c('div',{staticClass:"title"},[_vm._v(" 評審帳號管理 ")]),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'JudgeInvention',
                    },attrs:{"to":{ name: 'JudgeInvention' }}},[_vm._v(" 慈悲科技 ")]),_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'JudgeIncubator',
                    },attrs:{"to":{ name: 'JudgeIncubator' }}},[_vm._v(" 青年公益 ")])],1)]):_vm._e(),(_vm.isPermission('member'))?_c('div',{staticClass:"group"},[_c('div',{staticClass:"title"},[_vm._v(" 會員資料管理 ")]),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'Member',
                    },attrs:{"to":{ name: 'Member' }}},[_vm._v(" 會員資料 ")])],1)]):_vm._e(),(_vm.isPermission('home'))?_c('div',{staticClass:"group"},[_c('div',{staticClass:"title"},[_vm._v(" 首頁管理 ")]),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'HomeBanner',
                    },attrs:{"to":{ name: 'HomeBanner' }}},[_vm._v(" Banner ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'HomeRecommend',
                    },attrs:{"to":{ name: 'HomeRecommend' }}},[_vm._v(" 好評推薦 ")])],1)]):_vm._e(),(_vm.isPermission('invention'))?_c('div',{staticClass:"group"},[_c('div',{staticClass:"title"},[_vm._v(" 慈悲科技創新競賽管理 ")]),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active':
                            _vm.activeMenuItem === 'InventionContent',
                    },attrs:{"to":{ name: 'InventionContent' }}},[_vm._v(" 內容 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active':
                            _vm.activeMenuItem === 'InventionPrize',
                    },attrs:{"to":{ name: 'InventionPrize' }}},[_vm._v(" 獎勵方式 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'InventionQA',
                    },attrs:{"to":{ name: 'InventionQA' }}},[_vm._v(" 常見問題 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active':
                            _vm.activeMenuItem === 'InventionRecommend',
                    },attrs:{"to":{ name: 'InventionRecommend' }}},[_vm._v(" 好評推薦 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active':
                            _vm.activeMenuItem === 'InventionHistoryTeam',
                    },attrs:{"to":{ name: 'InventionHistoryTeam' }}},[_vm._v(" 歷屆團隊 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active':
                            _vm.activeMenuItem === 'InventionManageApply',
                    },attrs:{"to":{ name: 'InventionManageApply' }}},[_vm._v(" 報名資料 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active':
                            _vm.activeMenuItem === 'InventionSession',
                    },attrs:{"to":{ name: 'InventionSession' }}},[_vm._v(" 屆數 ")])],1)]):_vm._e(),(_vm.isPermission('incubator'))?_c('div',{staticClass:"group"},[_c('div',{staticClass:"title"},[_vm._v(" 青年公益實踐計畫管理 ")]),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active':
                            _vm.activeMenuItem === 'IncubatorContent',
                    },attrs:{"to":{ name: 'IncubatorContent' }}},[_vm._v(" 內容 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active':
                            _vm.activeMenuItem === 'IncubatorQA',
                    },attrs:{"to":{ name: 'IncubatorQA' }}},[_vm._v(" 常見問題 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active':
                            _vm.activeMenuItem === 'IncubatorRecommend',
                    },attrs:{"to":{ name: 'IncubatorRecommend' }}},[_vm._v(" 好評推薦 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active':
                            _vm.activeMenuItem === 'IncubatorTeacher',
                    },attrs:{"to":{ name: 'IncubatorTeacher' }}},[_vm._v(" 歷屆業師 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active':
                            _vm.activeMenuItem === 'IncubatorHistoryTeam',
                    },attrs:{"to":{ name: 'IncubatorHistoryTeam' }}},[_vm._v(" 歷屆團隊 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active':
                            _vm.activeMenuItem === 'IncubatorManageApply',
                    },attrs:{"to":{ name: 'IncubatorManageApply' }}},[_vm._v(" 報名資料 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active':
                            _vm.activeMenuItem === 'IncubatorSession',
                    },attrs:{"to":{ name: 'IncubatorSession' }}},[_vm._v(" 屆數 ")])],1)]):_vm._e(),(_vm.isPermission('academy'))?_c('div',{staticClass:"group"},[_c('div',{staticClass:"title"},[_vm._v(" 公益教育 ")]),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'AcademyBanner',
                    },attrs:{"to":{ name: 'AcademyBanner' }}},[_vm._v(" Banner ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'AcademyThink',
                    },attrs:{"to":{ name: 'AcademyThink' }}},[_vm._v(" 公益思塾 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'AcademyWalk',
                    },attrs:{"to":{ name: 'AcademyWalk' }}},[_vm._v(" 公益走讀 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'AcademyHistory',
                    },attrs:{"to":{ name: 'AcademyHistory' }}},[_vm._v(" 歷史活動 ")])],1)]):_vm._e(),(_vm.isPermission('study'))?_c('div',{staticClass:"group"},[_c('div',{staticClass:"title"},[_vm._v(" 公益研究 ")]),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'StudyBanner',
                    },attrs:{"to":{ name: 'StudyBanner' }}},[_vm._v(" Banner ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'SORIReport',
                    },attrs:{"to":{ name: 'SORIReport' }}},[_vm._v(" SORI報告 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'StudyReport',
                    },attrs:{"to":{ name: 'StudyReport' }}},[_vm._v(" 影響力報告書 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'StudyHistory',
                    },attrs:{"to":{ name: 'StudyHistory' }}},[_vm._v(" 歷屆團隊公益報告書 ")])],1)]):_vm._e(),(_vm.isPermission('news'))?_c('div',{staticClass:"group"},[_c('div',{staticClass:"title"},[_vm._v(" 公益資訊 ")]),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'NewsBanner',
                    },attrs:{"to":{ name: 'NewsBanner' }}},[_vm._v(" Banner ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'NewsTagging',
                    },attrs:{"to":{ name: 'NewsTagging' }}},[_vm._v(" 標籤 ")])],1),_c('div',{staticClass:"sub-menu"},[_c('router-link',{staticClass:"item",class:{
                        'router-active': _vm.activeMenuItem === 'NewsArticle',
                    },attrs:{"to":{ name: 'NewsArticle' }}},[_vm._v(" 文章 ")])],1)]):_vm._e()]),_c('div',{staticClass:"bottom"},[_c('v-btn',{staticClass:"change-password btn",attrs:{"elevation":"2","color":"#464646"},on:{"click":function($event){return _vm.goto('ChangePassword')}}},[_vm._v(" 更改密碼 ")]),_c('v-btn',{staticClass:"logout btn",attrs:{"elevation":"2","color":"#464646"},on:{"click":_vm.logout}},[_vm._v(" LOGOUT ")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":"Vision Future"}})])}]

export { render, staticRenderFns }