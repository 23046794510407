<template>
    <div class="member page">
        <div class="filter">
            <v-text-field
                v-model="filter.text"
                label="帳號/姓氏/名稱/連絡信箱/手機"
                placeholder="帳號/姓氏/名稱/連絡信箱/手機"
                outlined
                persistent-placeholder
                clearable
                class="search-input"
                @keypress.enter="search"
            />
            <div class="btn-box">
                <v-btn
                    class="btn"
                    color="primary"
                    @click="search"
                >
                    搜尋
                </v-btn>
                <v-btn
                    class="btn"
                    color="success"
                    @click="downloadExcel"
                >
                    下載所有會員資料
                </v-btn>
            </div>
        </div>
        <div class="table-block">
            <div class="total">
                總共: {{ total }} 筆資料
            </div>
            <v-data-table
                :headers="MemberHeader"
                :items="list"
                :options.sync="options"
                :items-per-page="10"
                :server-items-length="total"
                :loading="loading"
                class="data-table"
                :footer-props="footerProps"
            >
                <template v-slot:item.data.birthday="{ item }">
                    {{ dataFormate(item.data.birthday) }}
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import API from '@/api';
import { mapMutations } from 'vuex';
import MemberHeader from '@/static-data/member-manage-header.json';
import moment from 'moment';
import FileSaver from 'file-saver';

export default {
    components: {},
    data() {
        return {
            loading: true,
            MemberHeader,
            list: [],
            options: {
                page: 1,
                itemsPerPage: 10,
            },
            total: 0,
            footerProps: {
                'items-per-page-options': [5, 10, 15, 20],
            },
            filter: {
                test: null,
            },
        };
    },
    computed: {},
    watch: {
        options: {
            handler() {
                this.search();
            },
            deep: true,
        },
    },
    created() {
        this.search();
    },
    mounted() { },
    methods: {
        ...mapMutations('mNotification', ['handleError']),
        ...mapMutations('mLoading', ['setLoading']),
        async search() {
            const { page, itemsPerPage } = this.options;
            this.loading = true;
            try {
                const res = await API.UserResource.PrivateList({
                    params: {
                        offset: (page - 1) * itemsPerPage,
                        limit: itemsPerPage,
                        search: this.filter.text ? this.filter.text : undefined,
                    },
                });
                this.list = res.data.raw;
                this.total = res.data.total;
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.handleError({
                    message: e || '連線異常',
                    color: 'error',
                });
            }
        },
        dataFormate(date) {
            if (date) {
                return moment(date).format('YYYY-MM-DD');
            }
            return '';
        },
        async downloadExcel() {
            console.log('downloadExcel');
            try {
                this.setLoading(true);
                const res = await API.UserUtil.Download();
                const fileName = `會員資料_${moment().format('YYYY-MM-DD')}.xlsx`;
                FileSaver.saveAs(res.data, fileName);
                this.setLoading(false);
            } catch (e) {
                this.setLoading(false);
                this.handleError({
                    message: e || '連線異常',
                    color: 'error',
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.table-block {
    padding: 10px 20px;
}

.select-box {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .filter-select {
        margin: 0 10px;
        width: 200px;
    }
}

.search-input {
    margin: 0 10px !important;
}

.btn-box {
    margin: 0 10px;

    .btn {
        margin-right: 15px;
    }
}
</style>
