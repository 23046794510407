<template>
    <div
        class="screen"
        :style="`display: ${isOpen?'flex':'none'};`"
    >
        <div class="dialog">
            <div class="text">
                您的評分總分為：{{ total }}分<br>
                送出後將無法修改，確認送出評分嗎？
            </div>
            <div class="btn-box">
                <v-btn
                    class="back-btn"
                    color="grey"
                    @click="bake()"
                >
                    返回
                </v-btn>
                <v-btn
                    color="warning"
                    @click="submit()"
                >
                    確定送出評分
                </v-btn>
            </div>
        </div>
    </div>
</template>
<script>
import API from '@/api';

export default {
    components: { },
    props: {
        type: {
            type: String,
            default: 'incubator',
        },
        id: {
            type: String,
            default: '',
        },
        total: {
            type: Number,
            default: 0,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
        };
    },
    computed: {

    },
    created() {
    },
    mounted() {

    },
    methods: {
        bake() {
            this.$emit('closeDialog');
        },
        async submit() {
            await API.JudgeCommitUtil.SubmitCommit(this.type, this.id);
            this.$emit('toApply');
        },
    },
};
</script>
<style lang="scss" scoped>
.screen{
    width: 100vw;
    height: 100vh;
    background-color: #00000070;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    .dialog{
        width: 31%;
        min-width: 444px;
        background-color: #fff;
        border-radius: 10px;
        padding: 30px;
        .text{
            font-size: 14px;
            line-height: 1.5;
            text-align: center;
        }
        .btn-box{
            margin-top: 23.75px;
            display: flex;
            justify-content: center;
            button{
                width: 110px;
            }
            .back-btn{
                margin-right: 20px;
                color: #fff;
            }
        }
    }
}
</style>
