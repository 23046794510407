import _ from 'lodash';
import axios from 'axios';
import { v4 as uuid4 } from 'uuid';

export default (getOptions) => ({
    Submit: (id) => axios.post(`/api/incubator/apply/pvt/submit/${id}`, {}, getOptions()),
    Download: (params) => axios.get('/api/incubator/apply/pvt/download/list', _.merge(getOptions(), {
        responseType: 'blob',
        params: _.pick(params, ['filter', 'search']),
    })),
    FinalSubmit: (id, file) => {
        const form = new FormData();
        form.append('file', file, `${uuid4()}.pdf`);
        return axios.post(`/api/incubator/apply/pvt/final/submit/${id}`, form, getOptions());
    },
    RaiseStatus: (id) => axios.post(`/api/incubator/apply/pvt/raise/${id}`, {}, getOptions()),
    DowngradeStatus: (id) => axios.post(`/api/incubator/apply/pvt/downgrade/${id}`, {}, getOptions()),
    AllowJudge: (id) => axios.put(`/api/incubator/apply/pvt/allow/judge/${id}`, {}, getOptions()),
    DisallowJudge: (id) => axios.put(`/api/incubator/apply/pvt/disallow/judge/${id}`, {}, getOptions()),
});
