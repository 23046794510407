<template>
    <div class="change-password">
        <div class="form-data">
            <v-form
                ref="form"
                v-model="valid"
            >
                <v-text-field
                    v-model="oldPassword"
                    :type="showOldPWD ? 'text' : 'password'"
                    :append-icon="showOldPWD ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    label="舊密碼"
                    placeholder=""
                    hint="必填"
                    persistent-hint
                    :rules="passwordRule"
                    class="border-orange-input"
                    @click:append="showOldPWD = !showOldPWD"
                />
                <v-text-field
                    v-model="newPassword"
                    :type="showNewPWD ? 'text' : 'password'"
                    :append-icon="showNewPWD ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    label="新密碼"
                    placeholder=""
                    hint="必填"
                    persistent-hint
                    :rules="passwordRule"
                    class="border-orange-input"
                    @click:append="showNewPWD = !showNewPWD"
                />
                <v-text-field
                    v-model="rePassword"
                    :type="showRePWD ? 'text' : 'password'"
                    :append-icon="showRePWD ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    label="再次輸入新密碼"
                    placeholder=""
                    hint="必填"
                    persistent-hint
                    :rules="rePasswordRule"
                    class="border-orange-input"
                    @click:append="showRePWD = !showRePWD"
                />
            </v-form>
            <div class="action">
                <v-btn
                    elevation="2"
                    color="primary"
                    class="btn"
                    @click="changePassword"
                >
                    修改密碼
                </v-btn>
                <v-btn
                    elevation="2"
                    color="warning"
                    class="btn"
                    @click="cleanAll"
                >
                    清除
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import API from '@/api';

export default {
    components: {},
    data() {
        return {
            valid: false,
            oldPassword: '',
            newPassword: '',
            rePassword: '',
            showOldPWD: false,
            showNewPWD: false,
            showRePWD: false,
            passwordRule: [
                (v) => !!v || '此欄位為必填',
                (v) => (v && v.length >= 6) || '密碼最少6個字',
                (v) => !v.match(/\s/) || '輸入不可包含空格',
            ],
            rePasswordRule: [
                (v) => !!v || '此欄位為必填',
                (v) => v === this.newPassword || '與密碼不符',
            ],
        };
    },
    computed: {
        ...mapGetters('mAuth', ['getRole']),
    },
    created() {},
    mounted() {},
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        ...mapMutations('mLoading', ['setLoading']),
        ...mapActions('mAuth', ['Logout']),
        changePassword() {
            this.$refs.form.validate();
            if (this.valid) {
                const form = {
                    oldPassword: this.oldPassword,
                    password: this.newPassword,
                };
                this.setLoading(true);
                if (this.getRole === 'manager') {
                    API.Manager.ChangePassword(form)
                        .then((res) => {
                            console.log(res.data);
                            this.setAlert({
                                message: '更新成功，請重新登入',
                                color: 'success',
                            });
                            this.Logout();
                            this.setLoading(false);
                        })
                        .catch((e) => {
                            this.handleError({
                                message: e || '更新失敗',
                                color: 'error',
                            });
                            this.setLoading(false);
                        });
                } else if (this.getRole === 'judge') {
                    API.Judge.ChangePassword(form)
                        .then((res) => {
                            console.log(res.data);
                            this.setAlert({
                                message: '更新成功，請重新登入',
                                color: 'success',
                            });
                            this.Logout();
                            this.setLoading(false);
                        })
                        .catch((e) => {
                            this.handleError({
                                message: e || '更新失敗',
                                color: 'error',
                            });
                            this.setLoading(false);
                        });
                }
            }
        },
        cleanAll() {
            this.oldPassword = '';
            this.newPassword = '';
            this.rePassword = '';
        },
    },
};
</script>

<style lang="scss" scoped>
.form-data{
    max-width: 500px;
}

.action{
    .btn{
        margin-right: 15px;
    }
}
</style>
