<template>
    <v-card-title>
        <span class="title">{{ type }}</span><span class="comment">*必填</span>
    </v-card-title>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: () => '',
        },
    },
};
</script>
