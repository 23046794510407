import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import Notification from './modules/notification';
import Auth from './modules/auth';
import Loading from './modules/loading';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        mNotification: Notification,
        mAuth: Auth,
        mLoading: Loading,
    },
});
