var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialog"},[_c('v-dialog',{attrs:{"max-width":"80vw","persistent":"","retain-focus":false,"eager":"","scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('CardTitle',{attrs:{"type":_vm.type}}),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"*屆數(中文)","placeholder":"請輸入屆數","outlined":"","persistent-placeholder":"","clearable":"","rules":[function (v) { return !!v || '此欄位為必填'; }]},model:{value:(_vm.editData.session.zhTw),callback:function ($$v) {_vm.$set(_vm.editData.session, "zhTw", $$v)},expression:"editData.session.zhTw"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"*屆數(英文)","placeholder":"請輸入屆數","outlined":"","persistent-placeholder":"","clearable":"","rules":[function (v) { return !!v || '此欄位為必填'; }]},model:{value:(_vm.editData.session.en),callback:function ($$v) {_vm.$set(_vm.editData.session, "en", $$v)},expression:"editData.session.en"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"*報名開始日期","prepend-icon":"mdi-calendar","readonly":"","rules":[function (v) { return !!v || '此欄位為必填'; }]},model:{value:(_vm.editData.startDate),callback:function ($$v) {_vm.$set(_vm.editData, "startDate", $$v)},expression:"editData.startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{attrs:{"color":"orange","header-color":"orange"},on:{"input":function($event){_vm.startDateMenu = false}},model:{value:(_vm.editData.startDate),callback:function ($$v) {_vm.$set(_vm.editData, "startDate", $$v)},expression:"editData.startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"報名開始時間","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.editData.startTime),callback:function ($$v) {_vm.$set(_vm.editData, "startTime", $$v)},expression:"editData.startTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startTimeMenu),callback:function ($$v) {_vm.startTimeMenu=$$v},expression:"startTimeMenu"}},[(_vm.startTimeMenu)?_c('v-time-picker',{attrs:{"color":"orange","header-color":"orange","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.editData.startTime)}},model:{value:(_vm.editData.startTime),callback:function ($$v) {_vm.$set(_vm.editData, "startTime", $$v)},expression:"editData.startTime"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"*報名結束日期","prepend-icon":"mdi-calendar","readonly":"","rules":[function (v) { return !!v || '此欄位為必填'; }]},model:{value:(_vm.editData.endDate),callback:function ($$v) {_vm.$set(_vm.editData, "endDate", $$v)},expression:"editData.endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{attrs:{"color":"orange","header-color":"orange"},on:{"input":function($event){_vm.endDateMenu = false}},model:{value:(_vm.editData.endDate),callback:function ($$v) {_vm.$set(_vm.editData, "endDate", $$v)},expression:"editData.endDate"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"*報名結束時間","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.editData.endTime),callback:function ($$v) {_vm.$set(_vm.editData, "endTime", $$v)},expression:"editData.endTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endTimeMenu),callback:function ($$v) {_vm.endTimeMenu=$$v},expression:"endTimeMenu"}},[(_vm.endTimeMenu)?_c('v-time-picker',{attrs:{"full-width":"","color":"orange","header-color":"orange"},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.editData.endTime)}},model:{value:(_vm.editData.endTime),callback:function ($$v) {_vm.$set(_vm.editData, "endTime", $$v)},expression:"editData.endTime"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"*年份","placeholder":"請輸入數字","outlined":"","persistent-placeholder":"","clearable":"","rules":[function (v) { return /(?=.*[0-9])/.test(v) || '需輸入數字'; }]},model:{value:(_vm.editData.year),callback:function ($$v) {_vm.$set(_vm.editData, "year", $$v)},expression:"editData.year"}})],1),_c('v-spacer'),_c('v-spacer')],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeEdit}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.submitEdit}},[_vm._v(" 儲存 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }