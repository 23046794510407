<script>
export default {
    props: {
        editDialog: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
        };
    },
    computed: {
        show: {
            get() {
                return this.editDialog;
            },
            set(value) {
                if (!value) {
                    this.$emit('close');
                }
            },
        },
    },
    created() {},
    mounted() {},
    methods: {
        image(item) {
            if (this.type === '修改' && !item.startsWith('blob:')) {
                const file = `https://${process.env.VUE_APP_BASE_URL + item}`;
                return file;
            }
            return item;
        },
    },
};
</script>
