<template>
    <div class="add-box">
        <v-btn
            class="data-btn"
            color="warning"
            @click="editData()"
        >
            <v-icon
                left
            >
                mdi-plus
            </v-icon>
            新增列表
        </v-btn>
    </div>
</template>
<script>
export default {
    methods: {
        editData() {
            this.$emit('editData');
        },
    },
};
</script>
