var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invention-manage-apply page"},[_c('div',{staticClass:"filter"},[_c('div',{staticClass:"select-box"},[_c('v-select',{staticClass:"filter-select",attrs:{"items":_vm.levelOptions,"label":"參賽組別","item-text":"label","item-value":"value","outlined":"","clearable":""},model:{value:(_vm.filter.level),callback:function ($$v) {_vm.$set(_vm.filter, "level", $$v)},expression:"filter.level"}}),_c('v-select',{staticClass:"filter-select",attrs:{"items":_vm.themeOptions,"label":"議題","item-text":"label","item-value":"value","outlined":"","clearable":""},model:{value:(_vm.filter.theme),callback:function ($$v) {_vm.$set(_vm.filter, "theme", $$v)},expression:"filter.theme"}})],1),_c('v-text-field',{staticClass:"search-input",attrs:{"label":"團隊名稱/作品名稱","placeholder":"團隊名稱/作品名稱","outlined":"","persistent-placeholder":"","clearable":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchApply.apply(null, arguments)}},model:{value:(_vm.filter.text),callback:function ($$v) {_vm.$set(_vm.filter, "text", $$v)},expression:"filter.text"}}),_c('div',{staticClass:"btn-box"},[_c('v-btn',{staticClass:"btn",attrs:{"color":"primary"},on:{"click":_vm.searchApply}},[_vm._v(" 搜尋 ")])],1)],1),_c('div',{staticClass:"table-block"},[_c('div',{staticClass:"total"},[_vm._v(" 總共: "+_vm._s(_vm.total)+" 筆資料 ")]),_c('v-data-table',{staticClass:"data-table",attrs:{"headers":_vm.ApplyHeader,"items":_vm.list,"options":_vm.options,"items-per-page":10,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.proposal.main",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.proposal.main.split(" ")[0])+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.commit&&item.commit.total?item.commit.total:'-')+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(!item.commit)?_c('div',[_vm._v(" 尚未評分 ")]):(!item.commit.lock)?_c('div',[_vm._v(" 已儲存 ")]):_c('div',[_vm._v(" 送出評分 ")])]}},{key:"item.operate",fn:function(ref){
var item = ref.item;
return [(!item.commit)?_c('a',{staticStyle:{"color":"#1976D2"},on:{"click":function($event){return _vm.detailUrl(item.id)}}},[_vm._v("進行評分")]):(!item.commit.lock)?_c('a',{staticStyle:{"color":"#14C04F"},on:{"click":function($event){return _vm.detailUrl(item.id)}}},[_vm._v("已儲存")]):_c('a',{staticStyle:{"color":"#F78E8E"},on:{"click":function($event){return _vm.detailUrl(item.id)}}},[_vm._v("已送出")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }