<template>
    <div
        class="point-form test"
        :style="`right:${isOpen?0:type==='invention'?'-418px':'-362px'}`"
    >
        <div
            class="label-btn"
            @click="toggle()"
        >
            評 審 表 收 合
        </div>
        <v-form
            ref="form"
            v-model="valid"
            @submit.prevent
        >
            <div class="main-form">
                <v-table>
                    <thead>
                        <tr>
                            <th class="text-left">
                                項目
                            </th>
                            <th class="text-left">
                                分數
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="type==='invention'">
                        <tr>
                            <td>設計理念及主題相符性(30%)</td>
                            <td>
                                <v-text-field
                                    v-model="inventionPoints.conformity"
                                    outlined
                                    class="text-field"
                                    dense
                                    :maxlength="3"
                                    :rules="getRules(30)"
                                    :disabled="lock"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>創新(獨創)性(20%)</td>
                            <td>
                                <v-text-field
                                    v-model="inventionPoints.original"
                                    outlined
                                    class="text-field"
                                    dense
                                    :maxlength="3"
                                    :rules="getRules(20)"
                                    :disabled="lock"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>實用性(20%)</td>
                            <td>
                                <v-text-field
                                    v-model="inventionPoints.practicality"
                                    outlined
                                    class="text-field"
                                    dense
                                    :maxlength="3"
                                    :rules="getRules(20)"
                                    :disabled="lock"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>市場性(20%)</td>
                            <td>
                                <v-text-field
                                    v-model="inventionPoints.marketability"
                                    outlined
                                    class="text-field"
                                    dense
                                    :maxlength="3"
                                    :rules="getRules(20)"
                                    :disabled="lock"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>完整性(書面審查資料)(10%)</td>
                            <td>
                                <v-text-field
                                    v-model="inventionPoints.completion"
                                    outlined
                                    class="text-field"
                                    dense
                                    :maxlength="3"
                                    :rules="getRules(10)"
                                    :disabled="lock"
                                />
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="type==='incubator'">
                        <tr>
                            <td>社會影響力(20%)</td>
                            <td>
                                <v-text-field
                                    v-model="incubatorPoints.impact"
                                    outlined
                                    class="text-field"
                                    dense
                                    :maxlength="3"
                                    :rules="getRules(20)"
                                    :disabled="lock"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>創新/前瞻性(20%)</td>
                            <td>
                                <v-text-field
                                    v-model="incubatorPoints.innovation"
                                    outlined
                                    class="text-field"
                                    dense
                                    :maxlength="3"
                                    :rules="getRules(20)"
                                    :disabled="lock"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>計畫可行性(30%)</td>
                            <td>
                                <v-text-field
                                    v-model="incubatorPoints.feasibility"
                                    outlined
                                    class="text-field"
                                    dense
                                    :maxlength="3"
                                    :rules="getRules(30)"
                                    :disabled="lock"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>團隊執行力(30%)</td>
                            <td>
                                <v-text-field
                                    v-model="incubatorPoints.execution"
                                    outlined
                                    class="text-field"
                                    dense
                                    :maxlength="3"
                                    :rules="getRules(30)"
                                    :disabled="lock"
                                />
                            </td>
                        </tr>
                    </tbody>
                </v-table>
                <v-textarea
                    v-model="comments"
                    outlined
                    dense
                    height="70"
                    label="評語"
                    :disabled="lock"
                />
            </div>
            <div class="bottom-bar">
                <div class="total-points">
                    &nbsp;總分&nbsp;&nbsp;&nbsp; {{ getTotalPoints() }}
                </div>
                <div class="btn-box">
                    <v-btn
                        class="save-btn"
                        color="success"
                        :disabled="lock"
                        @click="save()"
                    >
                        儲存
                    </v-btn>
                    <v-btn
                        color="warning"
                        :disabled="lock"
                        @click="submit()"
                    >
                        送出評分
                    </v-btn>
                </div>
            </div>
        </v-form>
    </div>
</template>
<script>
import _ from 'lodash';
import API from '@/api';

export default {
    components: { },
    props: {
        type: {
            type: String,
            default: 'incubator',
        },
        id: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            valid: false,
            inventionPoints: {
                conformity: null,
                original: null,
                practicality: null,
                marketability: null,
                completion: null,
            },
            incubatorPoints: {
                impact: null,
                innovation: null,
                feasibility: null,
                execution: null,
            },
            total: null,
            comments: '',
            isOpen: true,
            lock: false,
        };
    },
    computed: {

    },
    created() {
        this.init();
    },
    mounted() {

    },
    methods: {
        getRules(Num) {
            return [(v) => (!!v && v >= 0 && v <= Num) || `請輸入介於0到${Num}之間數`, (v) => (Number.isInteger(Number(v))) || '請輸入整數'];
        },
        getTotalPoints() {
            if (this.type === 'incubator') {
                this.total = (Number(this.incubatorPoints.impact) || 0)
                + (Number(this.incubatorPoints.innovation) || 0)
                + (Number(this.incubatorPoints.feasibility) || 0)
                + (Number(this.incubatorPoints.execution) || 0);
            } else if (this.type === 'invention') {
                this.total = (Number(this.inventionPoints.conformity) || 0)
                + (Number(this.inventionPoints.original) || 0)
                + (Number(this.inventionPoints.practicality) || 0)
                + (Number(this.inventionPoints.marketability) || 0)
                + (Number(this.inventionPoints.completion) || 0);
            }

            return this.total;
        },
        toggle() {
            this.isOpen = !this.isOpen;
        },
        async init() {
            const res = await API.JudgeCommitUtil.FetchCommit(this.type, this.id);
            const data = res.data.items;
            this.lock = res.data.lock;
            if (this.type === 'incubator') {
                this.incubatorPoints.impact = data.find((item) => item.name === '社會影響力')?.score;
                this.incubatorPoints.innovation = data.find((item) => item.name === '創新/前瞻性')?.score;
                this.incubatorPoints.feasibility = data.find((item) => item.name === '計畫可行性')?.score;
                this.incubatorPoints.execution = data.find((item) => item.name === '團隊執行力')?.score;
            } else if (this.type === 'invention') {
                this.inventionPoints.conformity = data.find((item) => item.name === '設計理念及主題相符性')?.score;
                this.inventionPoints.original = data.find((item) => item.name === '創新(獨創)性')?.score;
                this.inventionPoints.practicality = data.find((item) => item.name === '實用性')?.score;
                this.inventionPoints.marketability = data.find((item) => item.name === '市場性')?.score;
                this.inventionPoints.completion = data.find((item) => item.name === '完整性(書面審查資料)')?.score;
            }
            this.comments = res.data.note;
        },
        async save() {
            if (this.$refs.form.validate()) {
                const {
                    conformity, original, practicality, marketability, completion,
                } = this.inventionPoints;
                const {
                    impact, innovation, feasibility, execution,
                } = this.incubatorPoints;
                let data;
                if (this.type === 'incubator') {
                    data = {
                        items: [{
                            name: '社會影響力',
                            score: Number(impact),
                        }, {
                            name: '創新/前瞻性',
                            score: Number(innovation),
                        }, {
                            name: '計畫可行性',
                            score: Number(feasibility),
                        }, {
                            name: '團隊執行力',
                            score: Number(execution),
                        }],
                        note: this.comments,
                        total: this.total,
                    };
                } else if (this.type === 'invention') {
                    data = {
                        items: [{
                            name: '設計理念及主題相符性',
                            score: Number(conformity),
                        }, {
                            name: '創新(獨創)性',
                            score: Number(original),
                        }, {
                            name: '實用性',
                            score: Number(practicality),
                        }, {
                            name: '市場性',
                            score: Number(marketability),
                        }, {
                            name: '完整性(書面審查資料)',
                            score: Number(completion),
                        }],
                        note: this.comments,
                        total: this.total,
                    };
                }
                await API.JudgeCommitUtil.UpdateCommit(this.type, this.id, data);
            }
        },
        submit() {
            this.save();
            if (this.$refs.form.validate()) {
                if (this.type === 'incubator') {
                    this.$emit('setDetailPoints', _.mapValues(this.incubatorPoints, (value) => parseInt(value, 10)));
                } else if (this.type === 'invention') {
                    this.$emit('setDetailPoints', _.mapValues(this.inventionPoints, (value) => parseInt(value, 10)));
                }
                this.$emit('setTotal', this.total);
                this.$emit('setComments', this.comments);
                this.$emit('openDialog');
            }
        },
    },
};
</script>
<style lang="scss">
.point-form{
    position: fixed;
    top: 30px;
    right: 0;
    border-top: 1px solid #F97E2B;
    border-bottom: 1px solid #F97E2B;
    border-left: 1px solid #F97E2B;
    background-color: #FFF;
    padding: 10px 10px 17px;
    transition: all 0.5s linear;
    cursor: pointer;
    .label-btn{
        width: 30px;
        font-size: 16px;
        font-weight: 700;
        padding: 15px 3px;
        writing-mode: vertical-rl;
        color: #FFF;
        background-color: #F97E2B;
        position: absolute;
        top:0;
        left: -30px;
    }
    .main-form{
        display: flex;
        flex-direction: column;
        th,td{
            font-size: 12px;
            padding: 5px 10px;
            border: 1px solid #D5D5D5;
        }
        input{
            font-size: 12px;
        }
        textarea{
            font-size: 12px;
            line-height: 1.2;
        }
        v-table{
            margin-bottom: 10px;
        }
        .v-label{
            font-size: 12px !important;
        }
        .text-field{
            width: 200px;
            .v-input__control{
                height: 25px;
            }
            fieldset{
                height: 30px;
            }
            .v-text-field__slot{
                height: 25px;
            }
            .v-text-field__details{
                position: absolute;
                top:50%;
                right:0;
                transform: translateY(-50%);
            }
        }
        .v-text-field.v-text-field--enclosed{
            margin-bottom: 0px !important;
        }

    }
    .bottom-bar{
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .save-btn{
            margin-right: 5px;
        }
        .total-points{
            font-size: 14px;
        }
    }
}

</style>
