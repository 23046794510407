<template>
    <div class="study-SORIreport page">
        <div class="text">
            <v-form
                ref="form"
                v-model="valid"
            >
                <div class="title-box">
                    <span class="title">SROI報告(中文)</span><span class="comment">*必填</span>
                </div>
                <v-row>
                    <v-col
                        cols="6"
                    >
                        <v-textarea
                            v-model="list.title.zhTw"
                            label="*標題"
                            outlined
                            clearable
                            no-resize
                            rows="2"
                            :rules="[v => !!v || '此欄位為必填']"
                        />
                    </v-col>
                    <v-col
                        cols="6"
                    >
                        <v-textarea
                            v-model="list.description.zhTw"
                            label="*描述"
                            outlined
                            clearable
                            no-resize
                            rows="2"
                            :rules="[v => !!v || '此欄位為必填']"
                        />
                    </v-col>
                    <v-col
                        cols="6"
                    >
                        <v-input
                            class="file-box"
                        >
                            <v-file-input
                                v-model="fileImg_zhTw"
                                prepend-icon="mdi-camera"
                                accept="image/png, image/jpeg"
                                outlined
                                class="file-input"
                                :rules="[v => !!v || '圖片不能為空']"
                                @change="(value) => selectFile(value,'zhTw')"
                            />
                            <div
                                class="file-img"
                            >
                                <v-img
                                    :src="image(list.image.zhTw)"
                                    height="210px"
                                    width="fit-content"
                                    loading="true"
                                    contain
                                    position="left"
                                    class="change-img"
                                >
                                    <v-btn
                                        v-if="showUploadImg_zhTw"
                                        width="280px"
                                        height="210px"
                                    >
                                        <v-icon>mdi-upload</v-icon>
                                        *上傳圖片(建議 W:280px H:210px)
                                    </v-btn>
                                </v-img>
                            </div>
                            <div
                                class="delete-btn"
                            >
                                <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    small
                                    color="grey"
                                    @click="clear('zhTw')"
                                >
                                    <v-icon dark>
                                        mdi-close-thick
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-input>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <p>*文章內容</p>
                        <v-input
                            v-model="list.content.zhTw"
                            :rules="[v => !!v || '此欄位為必填']"
                        >
                            <editor
                                v-model="list.content.zhTw"
                                :init="init"
                            />
                        </v-input>
                    </v-col>
                </v-row>
                <div class="line" />
                <div class="title-box">
                    <span class="title">SROI報告(英文)</span><span class="comment">*必填</span>
                </div>
                <v-row>
                    <v-col
                        cols="6"
                    >
                        <v-textarea
                            v-model="list.title.en"
                            label="*標題"
                            outlined
                            clearable
                            no-resize
                            rows="2"
                            :rules="[v => !!v || '此欄位為必填']"
                        />
                    </v-col>
                    <v-col
                        cols="6"
                    >
                        <v-textarea
                            v-model="list.description.en"
                            label="*描述"
                            outlined
                            clearable
                            no-resize
                            rows="2"
                            :rules="[v => !!v || '此欄位為必填']"
                        />
                    </v-col>
                    <v-col
                        cols="6"
                    >
                        <v-input
                            class="file-box"
                        >
                            <v-file-input
                                v-model="fileImg_en"
                                prepend-icon="mdi-camera"
                                accept="image/png, image/jpeg"
                                outlined
                                class="file-input"
                                :rules="[v => !!v || '圖片不能為空']"
                                @change="(value) => selectFile(value,'en')"
                            />
                            <div
                                class="file-img"
                            >
                                <v-img
                                    :src="image(list.image.en)"
                                    height="210px"
                                    width="fit-content"
                                    loading="true"
                                    contain
                                    position="left"
                                    class="change-img"
                                >
                                    <v-btn
                                        v-if="showUploadImg_en"
                                        width="280px"
                                        height="210px"
                                    >
                                        <v-icon>mdi-upload</v-icon>
                                        *上傳圖片(建議 W:280px H:210px)
                                    </v-btn>
                                </v-img>
                            </div>
                            <div
                                class="delete-btn"
                            >
                                <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    small
                                    color="grey"
                                    @click="clear('en')"
                                >
                                    <v-icon dark>
                                        mdi-close-thick
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-input>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <p>*文章內容</p>
                        <v-input
                            v-model="list.content.en"
                            :rules="[v => !!v || '此欄位為必填']"
                        >
                            <editor
                                v-model="list.content.en"
                                :init="init"
                            />
                        </v-input>
                    </v-col>
                </v-row>
                <div class="action">
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="submitEdit"
                    >
                        儲存
                    </v-btn>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script>
import API from '@/api';
import APIFormData from '@/api/libs/form-data';
import { mapMutations } from 'vuex';
import tinymceMixin from '@/mixins/tinymceMixin.vue';

export default {
    components: {
    },
    mixins: [tinymceMixin],
    data() {
        return {
            showUploadImg_zhTw: true,
            showUploadImg_en: true,
            valid: false,
            fileImg_zhTw: [],
            fileImg_en: [],
            list: {
                image: {
                    zhTw: '',
                    en: '',
                },
                title: {
                    zhTw: '',
                    en: '',
                },
                description: {
                    zhTw: '',
                    en: '',
                },
                content: {
                    zhTw: '',
                    en: '',
                },
                images: [],
            },
        };
    },
    created() {
        this.start();
    },
    mounted() {},
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        async start() {
            // 搜尋
            try {
                const res = await API.BookSori.PrivateFetchResource('000000000000000000000000');
                this.list = res.data;
            } catch (e) {
                this.handleError({
                    message: e || '搜尋異常',
                    color: 'error',
                });
            }
        },
        selectFile(file, data) {
            if (file && (file.constructor === File || file.constructor === Blob)) {
                this.list.image[`${data}`] = window.URL.createObjectURL(file);
                this[`fileImg_${data}`] = [{}];
                this[`showUploadImg_${data}`] = false;
            }
        },
        clear(data) {
            this.list.image[`${data}`] = '';
            this[`fileImg_${data}`] = [];
            this[`showUploadImg_${data}`] = true;
        },
        image(item) {
            if (item.split(':')[0] !== 'blob' && item) {
                const file = `https://${process.env.VUE_APP_BASE_URL + item}`;
                return file;
            }
            return item;
        },
        async submitEdit() {
            const imageBaseUrl = `https://${process.env.VUE_APP_BASE_URL}`;
            this.list.images = [
                ...this.list.content.en.match(/img src="(.*?)"/g) || [],
                ...this.list.content.zhTw.match(/img src="(.*?)"/g) || [],
            ].map((row) => row.replace(/img src="/, '').replace(/"$/, '').replace(imageBaseUrl, ''));
            this.list.content.en = this.list.content.en.replace(/blob:http/g, `${imageBaseUrl}blob:http`);
            this.list.content.zhTw = this.list.content.zhTw.replace(/blob:http/g, `${imageBaseUrl}blob:http`);
            if (this.$refs.form.validate()) {
                const data = {
                    image: {
                        zhTw: this.list.image.zhTw,
                        en: this.list.image.en,
                    },
                    title: {
                        zhTw: this.list.title.zhTw,
                        en: this.list.title.en,
                    },
                    description: {
                        zhTw: this.list.description.zhTw,
                        en: this.list.description.en,
                    },
                    content: {
                        zhTw: this.list.content.zhTw,
                        en: this.list.content.en,
                    },
                    images: this.list.images,
                };
                const form = new APIFormData(data, [{
                    urls: [
                        this.list.image.zhTw,
                        this.list.image.en,
                        ...this.list.images,
                    ],
                }]);
                try {
                    // 修改
                    await API.BookSori.PrivateUpdateResource('000000000000000000000000', form);
                    this.setAlert({
                        message: '更新成功',
                        color: 'success',
                    });
                } catch (e) {
                    this.handleError({
                        message: e || '新增修改異常',
                        color: 'error',
                    });
                }
            }
            this.start();
        },
    },
};
</script>

<style lang="scss">
.line{
    margin: 20px 0;
    border-bottom: 1px solid rgb(227, 227, 227);
}
.title-box{
    margin-bottom: 20px;
}
.comment{
    font-size: 12px;
    color: red;
    margin-left: 20px;
}
.action{
    display: flex;
    justify-content: flex-end;
}
.file-box{
    position: relative;
    .v-input__icon{
        position: absolute;
        left: 11px;
        top: 0;
        z-index: -1;
    }
    .v-input__slot{
        width: 280px;
        height: 209px;
    }
    .file-img{
        width: 280px;
        position: absolute;
        top:-23px;
        left: 9px;
        z-index: 0;
        pointer-events: none;
    }
    .v-text-field__slot{
        cursor: pointer;
    }
    .delete-btn{
        position: absolute;
        top:-40px;
        right: -40px;
    }
    .v-image__image{
        position: absolute;
        top:0;
        z-index: 2;
    }
    .change-status{
        background-color: #fff;
    }
    fieldset{
        border: none;
    }
}
</style>
