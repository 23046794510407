<template>
    <div class="incubator-detail">
        <v-form>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        參賽組別
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.team.type"
                        class="radio-group"
                        readonly
                    >
                        <v-radio
                            value="非正式立案組織（個人團隊）"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    非正式立案組織（個人團隊）
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="非營利組織"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    非營利組織
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="營利組織"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    營利組織
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        組織登記地區
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-select
                            v-model="form.team.country"
                            :items="countries"
                            outlined
                            label="國家"
                            class="form-inputform-input"
                            readonly
                            background-color="#F2F2F2"
                        />
                        <v-text-field
                            v-if="form.team.country !== '臺灣'"
                            v-model="form.team.city"
                            outlined
                            :label="`國家`"
                            placeholder=""
                            persistent-hint
                            class="form-inputform-input"
                            readonly
                            background-color="#F2F2F2"
                        />
                        <v-select
                            v-else
                            v-model="form.team.city"
                            :items="cities"
                            outlined
                            label="城市"
                            class="form-inputform-input"
                            readonly
                            background-color="#F2F2F2"
                            style="margin-left: 20px;"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        團隊資料
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.team.name"
                            outlined
                            label="團隊名稱"
                            placeholder=""
                            hint="必填"
                            persistent-hint
                            class="form-inputform-input"
                            readonly
                            background-color="#F2F2F2"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        提案資料
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.proposal.name"
                            outlined
                            label="計畫名稱"
                            placeholder=""
                            hint="必填"
                            persistent-hint
                            class="form-inputform-input"
                            readonly
                            background-color="#F2F2F2"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        團隊屬性
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.proposal.property"
                        class="radio-group"
                        readonly
                    >
                        <v-radio
                            value="臺灣團隊-孵化組"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    臺灣團隊-孵化組
                                </div>
                            </template>
                        </v-radio>
                        <div class="radio-content">
                            <ol>
                                <li>
                                    投件計畫/專案之隊長可投入一半以上工作時間（含全職）執行計畫。
                                </li>
                                <li>
                                    計畫/專案已有明確回饋社會公益之方向及目標。
                                </li>
                                <li>
                                    計畫/專案之年營收/年捐款在新台幣3百萬元以下。
                                </li>
                            </ol>
                        </div>
                        <v-radio
                            value="臺灣團隊-加速組"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    臺灣團隊-加速組
                                </div>
                            </template>
                        </v-radio>
                        <div class="radio-content">
                            <ol>
                                <li>
                                    計畫/專案已有穩定團隊成員，並期待在未來一年內擴大營運規模。
                                </li>
                                <li>
                                    計畫/專案期待在未來一年內調整與優化營運模式。
                                </li>
                                <li>
                                    計畫/專案之年營收/年捐款在新台幣3百萬元以上。
                                </li>
                            </ol>
                        </div>
                        <v-radio
                            value="亞太地區團隊"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    亞太地區團隊
                                </div>
                            </template>
                        </v-radio>
                        <div class="radio-content">
                            <ol>
                                <li>
                                    團隊所有成員皆須為40歲（含）以下青年，申請人必須符合本計畫之地區國籍。
                                </li>
                                <li>
                                    投件計畫/專案之團隊隊長與1位成員於孵化期間依計畫於臺灣進行實體培力課程、總導師與業師會議。
                                </li>
                                <li>
                                    計畫/專案已有明確回饋社會公益之方向及目標。
                                </li>
                                <li>
                                    計畫/專案期待在未來一年內調整與優化營運模式。
                                </li>
                            </ol>
                        </div>
                    </v-radio-group>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        提案內容
                    </div>
                </div>
                <div class="form-content">
                    <v-textarea
                        v-model="form.proposal.abstract"
                        rows="10"
                        counter="500"
                        label="提案內容"
                        no-resize
                        outlined
                        placeholder=""
                        hint="必填，500字以內"
                        persistent-hint
                        readonly
                        background-color="#F2F2F2"
                    />
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        議題
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.proposal.issue"
                        class="radio-group"
                        readonly
                    >
                        <v-radio
                            value="慈善創新"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    慈善創新
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="醫療照護"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    醫療照護
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="教育創新"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    教育創新
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="環境保護"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    環境保護
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="循環經濟"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    循環經濟
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="青銀共創"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    青銀共創
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="食糧供應創新"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    食糧供應創新
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="災害防救創新"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    災害防救創新
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="地方創生/社區營造"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    地方創生/社區營造
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="apply-form-block">
                    <div class="left-block">
                        <div class="block-title">
                            計畫書
                        </div>
                    </div>
                    <div class="form-content">
                        <div class="pdf-file">
                            <iframe
                                :src="getPDF($static(form.proposal.pdfFile))"
                                width="100%"
                                height="800"
                                frameborder="0"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </v-form>
        <PointsForm
            :id="$route.params.id"
            :type="'incubator'"
            @setTotal="setTotal"
            @setDetailPoints="setDetailPoints"
            @setComments="setComments"
            @openDialog="openDialog"
        />
        <PointsDialog
            :id="$route.params.id"
            :type="'incubator'"
            :total="total"
            :is-open="isOpen"
            @closeDialog="closeDialog"
            @toApply="toApply"
        />
    </div>
</template>

<script>
import API from '@/api';
import _ from 'lodash';
import { mapMutations } from 'vuex';
import PointsForm from '@/components/PointsForm.vue';
import PointsDialog from '@/components/Dialog/PointsDialog.vue';

export default {
    components: { PointsForm, PointsDialog },
    data() {
        return {
            refersTeamName: '',
            refersRecommendName: '',
            refersPlatformName: '',
            refersOther: '',
            form: {
                team: {
                    organizeName: '', // 組織名稱
                    type: '', // 參賽組別
                    country: '', // 組織登記地區-國家
                    city: '', // 組織登記地區-城市
                    taxId: '', // 統一編號
                    name: '', // 團隊資料
                    members: [{
                        name: '', // 團隊成員-姓名
                        phone: '', // 團隊成員-電話
                        email: '', // 團隊成員-email
                        department: '', // 團隊成員-單位職稱/學校系級
                        birthday: '', // 團隊成員-生日
                    },
                    ],
                },
                proposal: {
                    name: '', // 計畫名稱
                    property: '', // 團隊屬性
                    abstract: '', // 提案內容
                    issue: '', // 議題
                    pdfFile: '', // 計畫書
                    img: '', // 團隊照片
                },
                final: {
                    pdfFile: '', // 決審簡報
                },
            },
            status: '',
            countries: ['臺灣', '其他'],
            cities: ['臺北市', '新北市', '基隆市', '桃園市', '新竹市', '新竹縣', '苗栗縣', '臺中市', '彰化縣', '南投縣', '雲林縣', '嘉義市', '嘉義縣', '臺南市', '高雄市', '屏東縣', '宜蘭縣', '花蓮縣', '臺東縣', '澎湖縣', '金門縣', '連江縣'],
            isOpen: false,
            total: 0,
            points: {
                impact: 0,
                innovation: 0,
                feasibility: 0,
                execution: 0,
            },
            comments: '',
        };
    },
    computed: {},
    created() {
        this.getData();
    },
    mounted() { },
    methods: {
        ...mapMutations('mNotification', ['handleError']),
        ...mapMutations('mLoading', ['setLoading']),
        async getData() {
            const { id } = this.$route.params;
            try {
                this.setLoading(true);
                const res = await API.IncubatorApply.PrivateFetchResource(id);
                this.form = res.data;
                this.setLoading(false);
            } catch (e) {
                console.log('error', e);
                this.setLoading(false);
                this.handleError({
                    message: e || '連線異常',
                    color: 'error',
                });
            }
        },
        getPDF(url) {
            return `https://docs.google.com/viewer?url=${url}&embedded=true`;
        },
        memberNameLabel(index) {
            if (index === 0) {
                return '隊長';
            }
            return `隊員${index}`;
        },
        setTotal(value) {
            this.total = value;
        },
        setDetailPoints(array) {
            _.assign(this.points, array);
        },
        setComments(value) {
            this.comments = value;
        },
        openDialog() {
            this.isOpen = true;
        },
        closeDialog() {
            this.isOpen = false;
        },
        toApply() {
            this.$router.push({ name: 'SubmittedRatingIncubatorApply' });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/apply.scss';

.incubator-detail {
    padding: 50px 40px;
    .member-file {
        flex: 1;
        display: flex;
        align-items: center;
        margin-top: -50px;
    }

    .team-image-block {
        .preview-card {
            width: 300px;
            height: 300px;
        }
    }

    .pdf-file {
        a {
            color: $color-orange;
            font-weight: 700;
            letter-spacing: 0.1em;
            margin-left: 5px;
        }
    }

    .actions {
        justify-content: center;

        .btn:first-child {
            margin-right: 30px;
        }
    }

    .radio-content {
        padding-left: 34px;
        margin-bottom: 10px;
    }
}
.checkbox-input{
    padding-top: 0 !important;
    margin-top: 0 !important;
    width: 100%;
    flex: unset;
}
.checkbox-input input{
    padding-left: 10px;
}
@media (min-width: 768px) {
    .checkbox-input{
        max-width: 185px !important;
    }
}
</style>
