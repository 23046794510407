<template>
    <div class="invention-manage-apply page">
        <div class="filter">
            <div class="select-box">
                <v-select
                    v-model="filter.level"
                    class="filter-select"
                    :items="levelOptions"
                    label="參賽組別"
                    item-text="label"
                    item-value="value"
                    outlined
                    clearable
                />
                <v-select
                    v-model="filter.theme"
                    class="filter-select"
                    :items="themeOptions"
                    label="議題"
                    item-text="label"
                    item-value="value"
                    outlined
                    clearable
                />
            </div>
            <v-text-field
                v-model="filter.text"
                label="團隊名稱/作品名稱"
                placeholder="團隊名稱/作品名稱"
                outlined
                persistent-placeholder
                clearable
                class="search-input"
                @keypress.enter="searchApply"
            />
            <div class="btn-box">
                <v-btn
                    class="btn"
                    color="primary"
                    @click="searchApply"
                >
                    搜尋
                </v-btn>
            </div>
        </div>
        <div class="table-block">
            <div class="total">
                總共: {{ total }} 筆資料
            </div>
            <v-data-table
                :headers="ApplyHeader"
                :items="list"
                :options.sync="options"
                :items-per-page="10"
                :server-items-length="total"
                :loading="loading"
                class="data-table"
                :footer-props="footerProps"
            >
                <template v-slot:item.proposal.main="{ item }">
                    {{ item.proposal.main.split(" ")[0] }}
                </template>
                <template v-slot:item.total="{ item }">
                    {{ item.commit&&item.commit.total?item.commit.total:'-' }}
                </template>
                <template v-slot:item.status="{ item }">
                    <div
                        v-if="!item.commit"
                    >
                        尚未評分
                    </div>
                    <div
                        v-else-if="!item.commit.lock"
                    >
                        已儲存
                    </div>
                    <div
                        v-else
                    >
                        送出評分
                    </div>
                </template>
                <template v-slot:item.operate="{ item }">
                    <a
                        v-if="!item.commit"
                        style="color:#1976D2;"
                        @click="detailUrl(item.id)"
                    >進行評分</a>
                    <a
                        v-else-if="!item.commit.lock"
                        style="color:#14C04F;"
                        @click="detailUrl(item.id)"
                    >已儲存</a>
                    <a
                        v-else
                        style="color:#F78E8E;"
                        @click="detailUrl(item.id)"
                    >已送出</a>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import API from '@/api';
import { mapMutations } from 'vuex';
import ApplyHeader from '@/static-data/submitted-rating-invention-apply-header.json';

export default {
    components: {},
    data() {
        return {
            loading: true,
            ApplyHeader,
            list: [],
            options: {
                page: 1,
                itemsPerPage: 10,
            },
            total: 0,
            footerProps: {
                'items-per-page-options': [5, 10, 15, 20],
            },
            filter: {
                status: null,
                level: null,
                text: null,
                theme: null,
            },
            themeOptions: [
                { label: '慈善', value: '慈善' },
                { label: '醫療', value: '醫療' },
                { label: '全部', value: null },
            ],
            levelOptions: [
                { label: '大專校院組', value: '大專校院組' },
                { label: '高中職組', value: '高中職組' },
                { label: '全部', value: null },
            ],
        };
    },
    computed: {
    },
    watch: {
        options: {
            handler() {
                this.searchApply();
            },
            deep: true,
        },
    },

    created() {
        this.searchApply();
    },
    mounted() {
    },
    methods: {
        ...mapMutations('mNotification', ['handleError']),
        ...mapMutations('mLoading', ['setLoading']),
        ...mapMutations('mAuth', ['setType']),
        detailUrl(id) {
            this.$router.push({ name: 'SubmittedRatingInventionDetail', params: { id } });
        },
        async searchApply() {
            const { page, itemsPerPage } = this.options;
            this.loading = true;
            try {
            // 串API
                const res = await API.InventionApply.PrivateList({
                    params: {
                        offset: (page - 1) * itemsPerPage,
                        limit: itemsPerPage,
                        group: this.filter.level,
                        issue: this.filter.theme,
                        search: this.filter.text ? this.filter.text : undefined,
                    },
                });
                this.list = res.data.raw;
                this.total = res.data.total;
                if (this.total === 0) {
                    this.setType('incubator');
                    this.$router.push({ name: 'SubmittedRatingIncubatorApply' });
                } else {
                    this.setType('invention');
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.list = [];
                this.total = 0;
                if (e.response.data.message === 'invalid issue selected') {
                    this.handleError({
                        message: `沒有 "${this.filter.theme}" 議題`,
                        color: 'error',
                    });
                } else if (e.response.data.message === 'invalid group selected') {
                    this.handleError({
                        message: `沒有 "${this.filter.level}" 組別`,
                        color: 'error',
                    });
                } else {
                    this.handleError({
                        message: e || '連線異常',
                        color: 'error',
                    });
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.table-block {
    padding: 10px 20px;
}

.select-box {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .filter-select {
        margin: 0 10px;
        width: 200px;
    }
}

.search-input {
    margin: 0 10px !important;
}

.btn-box {
    margin: 0 10px;

    .btn {
        margin-right: 15px;
    }
}
.tooltips{
    color: #000;
    background-color: #fff;
    border-radius: 40px;
    text-align: center;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
    white-space: pre-wrap;
}
</style>
