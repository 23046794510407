<template>
    <div class="dialog">
        <v-dialog
            v-model="show"
            max-width="80vw"
            persistent
            :retain-focus="false"
            eager
            scrollable
        >
            <v-card>
                <CardTitle
                    :type="type"
                />
                <v-card-text>
                    <v-container>
                        <v-form
                            ref="form"
                            v-model="valid"
                        >
                            <v-row>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="editData.session.zhTw"
                                        label="*屆數(中文)"
                                        placeholder="請輸入屆數"
                                        outlined
                                        persistent-placeholder
                                        clearable
                                        :rules="[v => !!v || '此欄位為必填']"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="editData.session.en"
                                        label="*屆數(英文)"
                                        placeholder="請輸入屆數"
                                        outlined
                                        persistent-placeholder
                                        clearable
                                        :rules="[v => !!v || '此欄位為必填']"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-menu
                                        v-model="startDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="editData.startDate"
                                                label="*報名開始日期"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                :rules="[v => !!v || '此欄位為必填']"
                                                v-on="on"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="editData.startDate"
                                            color="orange"
                                            header-color="orange"
                                            @input="startDateMenu = false"
                                        />
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-menu
                                        ref="menu"
                                        v-model="startTimeMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="editData.startTime"
                                                label="報名開始時間"
                                                prepend-icon="mdi-clock-time-four-outline"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            />
                                        </template>
                                        <v-time-picker
                                            v-if="startTimeMenu"
                                            v-model="editData.startTime"
                                            color="orange"
                                            header-color="orange"
                                            full-width
                                            @click:minute="$refs.menu.save(editData.startTime)"
                                        />
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-menu
                                        v-model="endDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="editData.endDate"
                                                label="*報名結束日期"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                :rules="[v => !!v || '此欄位為必填']"
                                                v-on="on"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="editData.endDate"
                                            color="orange"
                                            header-color="orange"
                                            @input="endDateMenu = false"
                                        />
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-menu
                                        ref="menu"
                                        v-model="endTimeMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="editData.endTime"
                                                label="*報名結束時間"
                                                prepend-icon="mdi-clock-time-four-outline"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            />
                                        </template>
                                        <v-time-picker
                                            v-if="endTimeMenu"
                                            v-model="editData.endTime"
                                            full-width
                                            color="orange"
                                            header-color="orange"
                                            @click:minute="$refs.menu.save(editData.endTime)"
                                        />
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="editData.year"
                                        label="*年份"
                                        placeholder="請輸入數字"
                                        outlined
                                        persistent-placeholder
                                        clearable
                                        :rules="[v => /(?=.*[0-9])/.test(v) || '需輸入數字']"
                                    />
                                </v-col>
                                <v-spacer />
                                <v-spacer />
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions
                    class="justify-end"
                >
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeEdit"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="submitEdit"
                    >
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CardTitle from '@/components/UI/CardTitle.vue';
import dialogMixin from '@/mixins/dialogMixin.vue';
import moment from 'moment';

export default {
    components: { CardTitle },
    mixins: [dialogMixin],
    data() {
        return {
            type: '',
            valid: false,
            startDateMenu: false,
            endDateMenu: false,
            startTimeMenu: false,
            endTimeMenu: false,
            editData: {
                year: '',
                startDate: '',
                endDate: '',
                startTime: '',
                endTime: '',
                session: {
                    zhTw: '',
                    en: '',
                },
            },
        };
    },
    computed: {
    },
    created() {},
    mounted() {
    },
    methods: {
        editItem(data) {
            if (data) {
                const startTime = new Date(data.startTime);
                const endTime = new Date(data.endTime);
                this.type = '修改';
                this.editData = {
                    id: data.id,
                    year: data.year,
                    startDate: moment(startTime).format('YYYY-MM-DD'),
                    endDate: moment(endTime).format('YYYY-MM-DD'),
                    startTime: moment(startTime).format('HH:mm'),
                    endTime: moment(endTime).format('HH:mm'),
                    session: {
                        zhTw: data.session,
                        en: data.sessionEN,
                    },
                };
            } else {
                this.type = '新增';
                this.editData = {
                    year: '',
                    startDate: '',
                    endDate: '',
                    startTime: '',
                    endTime: '',
                    session: {
                        zhTw: '',
                        en: '',
                    },
                };
            }
        },
        closeEdit() {
            this.show = false;
            this.$emit('search');
        },
        submitEdit() {
            if (this.$refs.form.validate()) {
                this.$emit('saveData', this.editData);
                this.show = false;
            }
        },
    },
};
</script>

<style lang="scss">
</style>
