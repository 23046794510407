<template>
    <div class="study-report page">
        <Search
            :text="'標題/描述'"
            @search="search"
        />
        <Add
            @editData="editData"
        />
        <div class="table-block">
            <div class="total">
                總共: {{ total }} 筆資料
            </div>
            <v-data-table
                :headers="ApplyHeader"
                :items="list"
                :options.sync="options"
                :items-per-page="10"
                :server-items-length="total"
                :loading="loading"
                class="data-table"
                :footer-props="footerProps"
            >
                <template v-slot:item.image.zhTw="{ item }">
                    <v-img
                        :src="image(item.image.zhTw)"
                        class="data-img"
                    />
                </template>
                <template v-slot:item.image.en="{ item }">
                    <v-img
                        :src="image(item.image.en)"
                        class="data-img"
                    />
                </template>
                <template v-slot:item.pdf.zhTw="{ item }">
                    <a
                        :href="item.pdf.zhTw"
                        target="_blank"
                    >{{ item.pdf.zhTw }}
                    </a>
                </template>
                <template v-slot:item.pdf.en="{ item }">
                    <a
                        :href="item.pdf.en"
                        target="_blank"
                    >{{ item.pdf.en }}
                    </a>
                </template>
                <template v-slot:item.operate="{ item }">
                    <v-btn
                        class="mx-1"
                        dark
                        small
                        depressed
                        :disabled="isOperate"
                        color="green"
                        @click="editData(item)"
                    >
                        <v-icon dark>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                    <v-btn
                        class="mx-1 delete-btn"
                        dark
                        small
                        depressed
                        :disabled="isOperate"
                        color="red"
                        @click="deleteData(item)"
                    >
                        <v-icon dark>
                            mdi-trash-can
                        </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </div>
        <RouterDialog
            ref="child"
            :show-pdf="true"
            :edit-dialog="editDialog"
            @close="closeEdit"
            @saveEdit="saveEdit"
            @saveData="saveData"
            @search="search"
        />
    </div>
</template>

<script>
import API from '@/api';
import Mixin from '@/mixins/Mixin.vue';
import APIFormData from '@/api/libs/form-data';
import ApplyHeader from '@/static-data/study-manage-report-apply-header.json';
import RouterDialog from '@/components/Dialog/RouterDialog.vue';

export default {
    components: { RouterDialog },
    mixins: [Mixin],
    data() {
        return {
            ApplyHeader,
        };
    },
    watch: {
        options: {
            handler() {
                this.search();
            },
            deep: true,
        },
    },
    created() {
        this.search();
    },
    mounted() {},
    methods: {
        async deleteData(item) {
            this.isOperate = true;
            try {
                // 刪除
                await API.BookReport.PrivateDeleteResource(item.id);
            } catch (e) {
                this.handleError({
                    message: e || '刪除異常',
                    color: 'error',
                });
            }
            this.search();
        },
        async search(filterItem) {
            // 搜尋
            const { page, itemsPerPage } = this.options;
            const { text } = filterItem ?? {};
            this.loading = true;
            try {
                const res = await API.BookReport.PrivateList({
                    params: {
                        offset: (page - 1) * itemsPerPage,
                        limit: itemsPerPage,
                        search: text || undefined,
                    },
                });
                this.list = res.data.raw;
                this.total = res.data.total;
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.handleError({
                    message: e || '搜尋異常',
                    color: 'error',
                });
            }
            this.isOperate = false;
        },
        async saveData(item) {
            const data = {
                image: {
                    zhTw: item.image.zhTw,
                    en: item.image.en,
                },
                title: {
                    zhTw: item.title.zhTw,
                    en: item.title.en,
                },
                description: {
                    zhTw: item.description.zhTw,
                    en: item.description.en,
                },
                content: {
                    zhTw: item.content.zhTw,
                    en: item.content.en,
                },
                pdf: {
                    zhTw: item.pdf.zhTw,
                    en: item.pdf.en,
                },
                images: item.images,
            };
            const form = new APIFormData(data, [{
                urls: [
                    item.image.zhTw,
                    item.image.en,
                    ...item.images,
                ],
            }]);
            try {
                if (item.id) {
                // 修改
                    await API.BookReport.PrivateUpdateResource(item.id, form);
                    this.setAlert({
                        message: '更新成功',
                        color: 'success',
                    });
                } else {
                // 新增
                    await API.BookReport.PrivateCreateResource(form);
                    this.setAlert({
                        message: '新增成功',
                        color: 'success',
                    });
                }
            } catch (e) {
                this.handleError({
                    message: e || '新增修改異常',
                    color: 'error',
                });
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
