<template>
    <div class="home">
        Home
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    name: 'Home',
    components: {
    },
    computed: {
        ...mapState('mAuth', ['token']),
        ...mapGetters('mAuth', ['getRole']),
    },
    created() {
        if (this.getRole === 'manager') {
            this.$router.push({ name: 'Member' });
        } else if (this.getRole === 'judge') {
        // 給token取得評審評分資料放入store
            this.$router.push({ name: 'SubmittedRatingInventionApply', params: { id: this.token } });
        }
    },

};
</script>
