<template>
    <div class="invention-content page">
        <div class="text">
            <v-form
                ref="form"
                v-model="valid"
            >
                <div class="title-box">
                    <span class="title">慈悲科技競賽內容(中文)</span><span class="comment">*必填</span>
                </div>
                <v-row>
                    <v-col
                        cols="12"
                    >
                        <p>*圖片_電腦版</p>
                        <br>
                        <v-input
                            class="file-banner-box Des"
                        >
                            <v-file-input
                                v-model="fileImgDes_zhTw"
                                prepend-icon="mdi-camera"
                                accept="image/png, image/jpeg"
                                outlined
                                class="file-input"
                                :rules="[v => v.length===1 || '圖片不能為空']"
                                @change="(value) => selectFileDes(value,'zhTw')"
                            />
                            <div
                                class="file-img"
                            >
                                <v-img
                                    :src="image(list.imageDes.zhTw)"
                                    width="576px"
                                    loading="true"
                                    contain
                                    position="left"
                                    class="change-img"
                                >
                                    <v-btn
                                        v-if="showUploadImgDes_zhTw"
                                        width="576px"
                                        height="120px"
                                    >
                                        <v-icon>mdi-upload</v-icon>
                                        *上傳圖片(建議 W:1440px H:300px)
                                    </v-btn>
                                </v-img>
                            </div>
                            <div
                                class="delete-btn"
                            >
                                <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    small
                                    color="grey"
                                    @click="clearDes('zhTw')"
                                >
                                    <v-icon dark>
                                        mdi-close-thick
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-input>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <p>*圖片_手機版</p>
                        <br>
                        <v-input
                            class="file-banner-box Mob"
                        >
                            <v-file-input
                                v-model="fileImgMob_zhTw"
                                prepend-icon="mdi-camera"
                                accept="image/png, image/jpeg"
                                outlined
                                class="file-input"
                                :rules="[v => v.length===1 || '圖片不能為空']"
                                @change="(value) => selectFileMob(value,'zhTw')"
                            />
                            <div
                                class="file-mob-img"
                            >
                                <v-img
                                    :src="image(list.imageMob.zhTw)"
                                    width="375px"
                                    loading="true"
                                    contain
                                    position="left"
                                    class="change-img"
                                >
                                    <v-btn
                                        v-if="showUploadImgMob_zhTw"
                                        width="375px"
                                        height="200px"
                                    >
                                        <v-icon>mdi-upload</v-icon>
                                        *上傳圖片(建議 W:375px H:200px)
                                    </v-btn>
                                </v-img>
                            </div>
                            <div
                                class="delete-btn"
                            >
                                <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    small
                                    color="grey"
                                    @click="clearMob('zhTw')"
                                >
                                    <v-icon dark>
                                        mdi-close-thick
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-input>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="list.video.zhTw"
                            label="*競賽說明影片(連結)"
                            outlined
                            persistent-placeholder
                            clearable
                            :rules="[(v) => !!v || '此欄位為必填',(v) => v.includes('https://www.youtube.com/watch?v=')||'必需為youtube連結']"
                        />
                    </v-col>
                    <v-col cols="12">
                        <p>*競賽目的</p>
                        <v-input
                            v-model="list.purpose.zhTw"
                            :rules="[(v) => !!v || '此欄位為必填']"
                        >
                            <editor
                                v-model="list.purpose.zhTw"
                                :init="init"
                            />
                        </v-input>
                    </v-col>
                    <v-col cols="12">
                        <p>*報名資格</p>
                        <v-input
                            v-model="list.qualifications.zhTw"
                            :rules="[(v) => !!v || '此欄位為必填']"
                        >
                            <editor
                                v-model="list.qualifications.zhTw"
                                :init="init"
                            />
                        </v-input>
                    </v-col>
                    <v-col cols="12">
                        <p>*評選辦法</p>
                        <v-input
                            v-model="list.judgeMethod.zhTw"
                            :rules="[(v) => !!v || '此欄位為必填']"
                        >
                            <editor
                                v-model="list.judgeMethod.zhTw"
                                :init="init"
                            />
                        </v-input>
                    </v-col>
                    <v-col cols="12">
                        <p>*重要時程</p>
                        <v-input
                            v-model="list.schedule.zhTw"
                            :rules="[(v) => !!v || '此欄位為必填']"
                        >
                            <editor
                                v-model="list.schedule.zhTw"
                                :init="init"
                            />
                        </v-input>
                    </v-col>
                    <v-col cols="12">
                        <p>*證明書</p>
                        <v-input
                            v-model="list.certificate.zhTw"
                            :rules="[(v) => !!v || '此欄位為必填']"
                        >
                            <editor
                                v-model="list.certificate.zhTw"
                                :init="init"
                            />
                        </v-input>
                    </v-col>
                    <v-col cols="12">
                        <p>*聯絡我們</p>
                        <v-input
                            v-model="list.contact.zhTw"
                            :rules="[(v) => !!v || '此欄位為必填']"
                        >
                            <editor
                                v-model="list.contact.zhTw"
                                :init="init"
                            />
                        </v-input>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="list.planExample.zhTw"
                            label="*計畫書範本(連結)"
                            outlined
                            persistent-placeholder
                            clearable
                            :rules="[(v) => !!v || '此欄位為必填',(v) => v.includes('https://drive.google.com')||'必需為google drive連結']"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="list.universityDoc.zhTw"
                            label="*活動簡章-大專(連結)"
                            outlined
                            persistent-placeholder
                            clearable
                            :rules="[(v) => !!v || '此欄位為必填',(v) => v.includes('https://drive.google.com')||'必需為google drive連結']"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="list.heighSchoolDoc.zhTw"
                            label="*活動簡章-高中職(連結)"
                            outlined
                            persistent-placeholder
                            clearable
                            :rules="[(v) => !!v || '此欄位為必填',(v) => v.includes('https://drive.google.com')||'必需為google drive連結']"
                        />
                    </v-col>
                </v-row>
                <div class="line" />
                <div class="title-box">
                    <span class="title">慈悲科技競賽內容(英文)</span><span class="comment">*必填</span>
                </div>
                <v-row>
                    <v-col
                        cols="12"
                    >
                        <p>*圖片_電腦版</p>
                        <br>
                        <v-input
                            class="file-banner-box Des"
                        >
                            <v-file-input
                                v-model="fileImgDes_en"
                                prepend-icon="mdi-camera"
                                accept="image/png, image/jpeg"
                                outlined
                                class="file-input"
                                :rules="[v => v.length===1 || '圖片不能為空']"
                                @change="(value) => selectFileDes(value,'en')"
                            />
                            <div
                                class="file-img"
                            >
                                <v-img
                                    :src="image(list.imageDes.en)"
                                    width="576px"
                                    loading="true"
                                    contain
                                    position="left"
                                    class="change-img"
                                >
                                    <v-btn
                                        v-if="showUploadImgDes_en"
                                        width="576px"
                                        height="120px"
                                    >
                                        <v-icon>mdi-upload</v-icon>
                                        *上傳圖片(建議 W:1440px H:300px)
                                    </v-btn>
                                </v-img>
                            </div>
                            <div
                                class="delete-btn"
                            >
                                <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    small
                                    color="grey"
                                    @click="clearDes('en')"
                                >
                                    <v-icon dark>
                                        mdi-close-thick
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-input>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <p>*圖片_手機版</p>
                        <br>
                        <v-input
                            class="file-banner-box Mob"
                        >
                            <v-file-input
                                v-model="fileImgMob_en"
                                prepend-icon="mdi-camera"
                                accept="image/png, image/jpeg"
                                outlined
                                class="file-input"
                                :rules="[v => v.length===1 || '圖片不能為空']"
                                @change="(value) => selectFileMob(value,'en')"
                            />
                            <div
                                class="file-mob-img"
                            >
                                <v-img
                                    :src="image(list.imageMob.en)"
                                    width="375px"
                                    loading="true"
                                    contain
                                    position="left"
                                    class="change-img"
                                >
                                    <v-btn
                                        v-if="showUploadImgMob_en"
                                        width="375px"
                                        height="200px"
                                    >
                                        <v-icon>mdi-upload</v-icon>
                                        *上傳圖片(建議 W:375px H:200px)
                                    </v-btn>
                                </v-img>
                            </div>
                            <div
                                class="delete-btn"
                            >
                                <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    small
                                    color="grey"
                                    @click="clearMob('en')"
                                >
                                    <v-icon dark>
                                        mdi-close-thick
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-input>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="list.video.en"
                            label="*競賽說明影片(連結)"
                            outlined
                            persistent-placeholder
                            clearable
                            :rules="[(v) => !!v || '此欄位為必填',(v) => v.includes('https://www.youtube.com/watch?v=')||'必需為youtube連結']"
                        />
                    </v-col>
                    <v-col cols="12">
                        <p>*競賽目的</p>
                        <v-input
                            v-model="list.purpose.en"
                            :rules="[(v) => !!v || '此欄位為必填']"
                        >
                            <editor
                                v-model="list.purpose.en"
                                :init="init"
                            />
                        </v-input>
                    </v-col>
                    <v-col cols="12">
                        <p>*報名資格</p>
                        <v-input
                            v-model="list.qualifications.en"
                            :rules="[(v) => !!v || '此欄位為必填']"
                        >
                            <editor
                                v-model="list.qualifications.en"
                                :init="init"
                            />
                        </v-input>
                    </v-col>
                    <v-col cols="12">
                        <p>*評選辦法</p>
                        <v-input
                            v-model="list.judgeMethod.en"
                            :rules="[(v) => !!v || '此欄位為必填']"
                        >
                            <editor
                                v-model="list.judgeMethod.en"
                                :init="init"
                            />
                        </v-input>
                    </v-col>
                    <v-col cols="12">
                        <p>*重要時程</p>
                        <v-input
                            v-model="list.schedule.en"
                            :rules="[(v) => !!v || '此欄位為必填']"
                        >
                            <editor
                                v-model="list.schedule.en"
                                :init="init"
                            />
                        </v-input>
                    </v-col>
                    <v-col cols="12">
                        <p>*證明書</p>
                        <v-input
                            v-model="list.certificate.en"
                            :rules="[(v) => !!v || '此欄位為必填']"
                        >
                            <editor
                                v-model="list.certificate.en"
                                :init="init"
                            />
                        </v-input>
                    </v-col>
                    <v-col cols="12">
                        <p>*聯絡我們</p>
                        <v-input
                            v-model="list.contact.en"
                            :rules="[(v) => !!v || '此欄位為必填']"
                        >
                            <editor
                                v-model="list.contact.en"
                                :init="init"
                            />
                        </v-input>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="list.planExample.en"
                            label="*計畫書範本"
                            outlined
                            persistent-placeholder
                            clearable
                            :rules="[(v) => !!v || '此欄位為必填',(v) => v.includes('https://drive.google.com')||'必需為google drive連結']"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="list.universityDoc.en"
                            label="*活動簡章-大專"
                            outlined
                            persistent-placeholder
                            clearable
                            :rules="[(v) => !!v || '此欄位為必填',(v) => v.includes('https://drive.google.com')||'必需為google drive連結']"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="list.heighSchoolDoc.en"
                            label="*活動簡章-高中職"
                            outlined
                            persistent-placeholder
                            clearable
                            :rules="[(v) => !!v || '此欄位為必填',(v) => v.includes('https://drive.google.com')||'必需為google drive連結']"
                        />
                    </v-col>
                </v-row>
                <div class="action">
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="submitEdit"
                    >
                        儲存
                    </v-btn>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script>
import API from '@/api';
import APIFormData from '@/api/libs/form-data';
import { mapMutations } from 'vuex';
import tinymceMixin from '@/mixins/tinymceMixin.vue';

export default {
    components: {
    },
    mixins: [tinymceMixin],
    data() {
        return {
            showUploadImgDes_zhTw: true,
            showUploadImgDes_en: true,
            showUploadImgMob_zhTw: true,
            showUploadImgMob_en: true,
            valid: false,
            list: {
                imageDes: {
                    zhTw: '',
                    en: '',
                },
                imageMob: {
                    zhTw: '',
                    en: '',
                },
                video: {
                    zhTw: '',
                    en: '',
                },
                purpose: {
                    zhTw: '',
                    en: '',
                },
                qualifications: {
                    zhTw: '',
                    en: '',
                },
                judgeMethod: {
                    zhTw: '',
                    en: '',
                },
                schedule: {
                    zhTw: '',
                    en: '',
                },
                certificate: {
                    zhTw: '',
                    en: '',
                },
                contact: {
                    zhTw: '',
                    en: '',
                },
                planExample: {
                    zhTw: '',
                    en: '',
                },
                universityDoc: {
                    zhTw: '',
                    en: '',
                },
                heighSchoolDoc: {
                    zhTw: '',
                    en: '',
                },
                images: [],
            },
            fileImgDes_zhTw: [{}],
            fileImgDes_en: [{}],
            fileImgMob_zhTw: [{}],
            fileImgMob_en: [{}],
        };
    },
    computed: {},
    created() {
        this.start();
    },
    mounted() {},
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        async start() {
            try {
                const res = await API.InventionContent.PrivateFetchResource('000000000000000000000000');
                this.list = res.data;
            } catch (e) {
                this.handleError({
                    message: e || '資料異常',
                    color: 'error',
                });
            }
        },
        selectFileDes(file, data) {
            if (file && (file.constructor === File || file.constructor === Blob)) {
                this.list.imageDes[`${data}`] = window.URL.createObjectURL(file);
                this[`fileImgDes_${data}`] = [{}];
                this[`showUploadImgDes_${data}`] = false;
            }
        },
        selectFileMob(file, data) {
            if (file && (file.constructor === File || file.constructor === Blob)) {
                this.list.imageMob[`${data}`] = window.URL.createObjectURL(file);
                this[`fileImgMob_${data}`] = [{}];
                this[`showUploadImgMob_${data}`] = false;
            }
        },
        clearDes(data) {
            this.list.imageDes[`${data}`] = '';
            this[`fileImgDes_${data}`] = [];
            this[`showUploadImgDes_${data}`] = true;
        },
        clearMob(data) {
            this.list.imageMob[`${data}`] = '';
            this[`fileImgMob_${data}`] = [];
            this[`showUploadImgMob_${data}`] = true;
        },
        image(item) {
            if (item.split(':')[0] !== 'blob' && item) {
                const file = `https://${process.env.VUE_APP_BASE_URL + item}`;
                return file;
            }
            return item;
        },
        async submitEdit() {
            const imageBaseUrl = `https://${process.env.VUE_APP_BASE_URL}`;
            this.list.images = [
                ...this.list.purpose.en.match(/img src="(.*?)"/g) || [],
                ...this.list.purpose.zhTw.match(/img src="(.*?)"/g) || [],
                ...this.list.qualifications.en.match(/img src="(.*?)"/g) || [],
                ...this.list.qualifications.zhTw.match(/img src="(.*?)"/g) || [],
                ...this.list.judgeMethod.en.match(/img src="(.*?)"/g) || [],
                ...this.list.judgeMethod.zhTw.match(/img src="(.*?)"/g) || [],
                ...this.list.schedule.en.match(/img src="(.*?)"/g) || [],
                ...this.list.schedule.zhTw.match(/img src="(.*?)"/g) || [],
                ...this.list.certificate.en.match(/img src="(.*?)"/g) || [],
                ...this.list.certificate.zhTw.match(/img src="(.*?)"/g) || [],
                ...this.list.contact.en.match(/img src="(.*?)"/g) || [],
                ...this.list.contact.zhTw.match(/img src="(.*?)"/g) || [],
            ].map((row) => row.replace(/img src="/, '').replace(/"$/, '').replace(imageBaseUrl, ''));
            this.list.purpose.en = this.list.purpose.en.replace(/blob:http/g, `${imageBaseUrl}blob:http`);
            this.list.purpose.zhTw = this.list.purpose.zhTw.replace(/blob:http/g, `${imageBaseUrl}blob:http`);
            this.list.qualifications.en = this.list.qualifications.en.replace(/blob:http/g, `${imageBaseUrl}blob:http`);
            this.list.qualifications.zhTw = this.list.qualifications.zhTw.replace(/blob:http/g, `${imageBaseUrl}blob:http`);
            this.list.judgeMethod.en = this.list.judgeMethod.en.replace(/blob:http/g, `${imageBaseUrl}blob:http`);
            this.list.judgeMethod.zhTw = this.list.judgeMethod.zhTw.replace(/blob:http/g, `${imageBaseUrl}blob:http`);
            this.list.schedule.en = this.list.schedule.en.replace(/blob:http/g, `${imageBaseUrl}blob:http`);
            this.list.schedule.zhTw = this.list.schedule.zhTw.replace(/blob:http/g, `${imageBaseUrl}blob:http`);
            this.list.certificate.en = this.list.certificate.en.replace(/blob:http/g, `${imageBaseUrl}blob:http`);
            this.list.certificate.zhTw = this.list.certificate.zhTw.replace(/blob:http/g, `${imageBaseUrl}blob:http`);
            this.list.contact.en = this.list.contact.en.replace(/blob:http/g, `${imageBaseUrl}blob:http`);
            this.list.contact.zhTw = this.list.contact.zhTw.replace(/blob:http/g, `${imageBaseUrl}blob:http`);
            if (this.$refs.form.validate()) {
                const data = {
                    imageDes: {
                        zhTw: this.list.imageDes.zhTw,
                        en: this.list.imageDes.en,
                    },
                    imageMob: {
                        zhTw: this.list.imageMob.zhTw,
                        en: this.list.imageMob.en,
                    },
                    video: {
                        zhTw: this.list.video.zhTw,
                        en: this.list.video.en,
                    },
                    purpose: {
                        zhTw: this.list.purpose.zhTw,
                        en: this.list.purpose.en,
                    },
                    qualifications: {
                        zhTw: this.list.qualifications.zhTw,
                        en: this.list.qualifications.en,
                    },
                    judgeMethod: {
                        zhTw: this.list.judgeMethod.zhTw,
                        en: this.list.judgeMethod.en,
                    },
                    schedule: {
                        zhTw: this.list.schedule.zhTw,
                        en: this.list.schedule.en,
                    },
                    certificate: {
                        zhTw: this.list.certificate.zhTw,
                        en: this.list.certificate.en,
                    },
                    contact: {
                        zhTw: this.list.contact.zhTw,
                        en: this.list.contact.en,
                    },
                    planExample: {
                        zhTw: this.list.planExample.zhTw,
                        en: this.list.planExample.en,
                    },
                    universityDoc: {
                        zhTw: this.list.universityDoc.zhTw,
                        en: this.list.universityDoc.en,
                    },
                    heighSchoolDoc: {
                        zhTw: this.list.heighSchoolDoc.zhTw,
                        en: this.list.heighSchoolDoc.en,
                    },
                    images: this.list.images,
                };
                const form = new APIFormData(data, [{
                    urls: [
                        ...this.list.images,
                        this.list.imageDes.zhTw,
                        this.list.imageDes.en,
                        this.list.imageMob.zhTw,
                        this.list.imageMob.en,
                    ],
                }]);
                try {
                    // 修改
                    await API.InventionContent.PrivateUpdateResource('000000000000000000000000', form);
                    this.setAlert({
                        message: '更新成功',
                        color: 'success',
                    });
                } catch (e) {
                    this.handleError({
                        message: e || '新增修改異常',
                        color: 'error',
                    });
                }
            }
            this.start();
        },
    },
};
</script>

<style lang="scss">
.line {
    margin: 20px 0;
    border-bottom: 1px solid rgb(227, 227, 227);
}
.title-box {
    margin-bottom: 20px;
}
.comment {
    font-size: 12px;
    color: red;
    margin-left: 20px;
}
.action {
    display: flex;
    justify-content: flex-end;
}
.Des{
    .v-input__slot{
        width: 576px;
        height: 120px;
        // overflow: hidden;
    }
}
.Mob{
    .v-input__slot{
        width: 375px;
        height: 200px;
    }
}
.file-banner-box{
    position: relative;

    .v-input__icon{
        position: absolute;
        left: 11px;
        top: 0;
        z-index: -1;
    }
    .file-img{
        width: 576px;
        height: 120px;
        overflow: hidden;
        position: absolute;
        top:-23px;
        left: 9px;
        z-index: 0;
        pointer-events: none;
    }
    .file-mob-img{
        width: 375px;
        height: 200px;
        overflow: hidden;
        position: absolute;
        top:-23px;
        left: 9px;
        z-index: 0;
        pointer-events: none;
    }
    .v-text-field__slot{
        cursor: pointer;
    }
    .delete-btn{
        position: absolute;
        top:-40px;
        right: -40px;
    }
    .v-image__image{
        position: absolute;
        top:0;
        z-index: 2;
    }
    .change-status{
        background-color: #fff;
    }
    fieldset{
        border: none;
    }
}
</style>
