<template>
    <div class="login">
        <div class="main-title">
            Vision Future 後台登入
        </div>
        <div class="login-block">
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <div class="type">
                    <v-radio-group
                        v-model="type"
                        :column="false"
                    >
                        <v-radio
                            label="管理員"
                            value="manager"
                            class="radio"
                        />
                        <v-radio
                            label="評審"
                            value="judge"
                            class="radio"
                        />
                    </v-radio-group>
                </div>
                <v-text-field
                    v-model="loginForm.account"
                    outlined
                    label="帳號"
                    placeholder=""
                    hint=""
                    :rules="emptyRule"
                    persistent-hint
                    class="border-orange-input"
                    @keypress.enter="login"
                />
                <v-text-field
                    v-model="loginForm.password"
                    :type="showPWD ? 'text' : 'password'"
                    :append-icon="showPWD ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    label="密碼"
                    placeholder=""
                    hint=""
                    persistent-hint
                    :rules="passwordRule"
                    class="border-orange-input"
                    @keypress.enter="login"
                    @click:append="showPWD = !showPWD"
                />
            </v-form>
            <div class="action">
                <v-btn
                    color="primary"
                    @click="login"
                >
                    登入
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    components: {},
    data() {
        return {
            valid: false,
            emptyRule: [(v) => !!v || '此欄位為必填'],
            type: 'manager',
            loginForm: {
                account: '',
                password: '',
            },
            passwordRule: [
                (v) => !!v || '此欄位為必填',
                (v) => (v && v.length >= 6) || '密碼最少6個字',
                (v) => !v.match(/\s/) || '輸入不可包含空格',
            ],
            showPWD: false,
        };
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {
        ...mapActions('mAuth', ['LoginManager', 'LoginJudge']),
        async login() {
            this.$refs.form.validate();
            if (this.valid) {
                try {
                    if (this.type === 'manager') {
                        this.LoginManager(this.loginForm);
                    } else {
                        this.LoginJudge(this.loginForm);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.login {
    background-color: rgba($color: #000000, $alpha: 0.8);
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .main-title {
        color: white;
        font-weight: bold;
        font-size: 36px;
        display: flex;
        justify-content: center;
        margin-top: 100px;
    }
    .login-block {
        width: 600px;
        padding: 15px;
        background-color: white;
        margin-top: 100px;
    }

    .type {
        .radio:not(:last-child) {
            margin-right: 20px;
        }
    }
}
</style>
