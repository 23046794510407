import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/backstage-account',
        name: 'BackstageAccount',
        component: () => import('../views/BackstageAccount.vue'),
    },
    {
        path: '/member-manage',
        name: 'Member',
        component: () => import('../views/MemberManage/Member.vue'),
    },
    {
        path: '/change-password',
        name: 'ChangePassword',
        component: () => import('../views/ChangePassword.vue'),
    },
    // 徵件評分
    {
        path: '/submitted-rating/invention',
        name: 'SubmittedRatingInventionApply',
        component: () => import('../views/SubmittedRating/InventionApply.vue'),
    },
    {
        path: '/submitted-rating/invention/detail/:id',
        name: 'SubmittedRatingInventionDetail',
        component: () => import('../views/SubmittedRating/InventionDetail.vue'),
    },
    {
        path: '/submitted-rating/incubator/detail/:id',
        name: 'SubmittedRatingIncubatorDetail',
        component: () => import('../views/SubmittedRating/IncubatorDetail.vue'),
    },
    {
        path: '/submitted-rating/incubator',
        name: 'SubmittedRatingIncubatorApply',
        component: () => import('../views/SubmittedRating/IncubatorApply.vue'),
    },

    // 評審帳號管理
    {
        path: '/judge/invention',
        name: 'JudgeInvention',
        component: () => import('../views/Judge/Invention.vue'),
    },
    {
        path: '/judge/incubator',
        name: 'JudgeIncubator',
        component: () => import('../views/Judge/Incubator.vue'),
    },
    // 慈悲科技
    {
        path: '/invention-manage-apply',
        name: 'InventionManageApply',
        component: () => import('../views/InventionManage/Apply.vue'),
    },
    {
        path: '/invention-detail/:id',
        name: 'InventionDetail',
        component: () => import('../views/InventionManage/Detail.vue'),
    },
    {
        path: '/invention/session',
        name: 'InventionSession',
        component: () => import('../views/InventionManage/Session.vue'),
    },
    {
        path: '/invention/content',
        name: 'InventionContent',
        component: () => import('../views/InventionManage/Content.vue'),
    },
    {
        path: '/invention/prize',
        name: 'InventionPrize',
        component: () => import('../views/InventionManage/Prize.vue'),
    },
    {
        path: '/invention/qa',
        name: 'InventionQA',
        component: () => import('../views/InventionManage/QA.vue'),
    },
    {
        path: '/invention/recommend',
        name: 'InventionRecommend',
        component: () => import('../views/InventionManage/Recommend.vue'),
    },
    {
        path: '/invention/history-team',
        name: 'InventionHistoryTeam',
        component: () => import('../views/InventionManage/HistoryTeam.vue'),
    },
    // 青年公益
    {
        path: '/incubator/content',
        name: 'IncubatorContent',
        component: () => import('../views/IncubatorManage/Content.vue'),
    },
    {
        path: '/incubator/recommend',
        name: 'IncubatorRecommend',
        component: () => import('../views/IncubatorManage/Recommend.vue'),
    },
    {
        path: '/incubator/teacher',
        name: 'IncubatorTeacher',
        component: () => import('../views/IncubatorManage/Teacher.vue'),
    },
    {
        path: '/incubator/history-team',
        name: 'IncubatorHistoryTeam',
        component: () => import('../views/IncubatorManage/HistoryTeam.vue'),
    },
    {
        path: '/incubator/qa',
        name: 'IncubatorQA',
        component: () => import('../views/IncubatorManage/QA.vue'),
    },
    {
        path: '/incubator-manage-apply',
        name: 'IncubatorManageApply',
        component: () => import('../views/IncubatorManage/Apply.vue'),
    },
    {
        path: '/incubator-detail/:id',
        name: 'IncubatorDetail',
        component: () => import('../views/IncubatorManage/Detail.vue'),
    },
    {
        path: '/incubator/session',
        name: 'IncubatorSession',
        component: () => import('../views/IncubatorManage/Session.vue'),
    },
    // 首頁
    {
        path: '/home/banner',
        name: 'HomeBanner',
        component: () => import('../views/Home/Banner.vue'),
    },
    {
        path: '/home/recommend',
        name: 'HomeRecommend',
        component: () => import('../views/Home/Recommend.vue'),
    },
    // 公益教育
    {
        path: '/academy/banner',
        name: 'AcademyBanner',
        component: () => import('../views/Academy/Banner.vue'),
    },
    {
        path: '/academy/think',
        name: 'AcademyThink',
        component: () => import('../views/Academy/Think.vue'),
    },
    {
        path: '/academy/walk',
        name: 'AcademyWalk',
        component: () => import('../views/Academy/Walk.vue'),
    },
    {
        path: '/academy/history',
        name: 'AcademyHistory',
        component: () => import('../views/Academy/History.vue'),
    },
    // 公益研究
    {
        path: '/study/banner',
        name: 'StudyBanner',
        component: () => import('../views/Study/Banner.vue'),
    },
    {
        path: '/study/sori',
        name: 'SORIReport',
        component: () => import('../views/Study/SORIReport.vue'),
    },
    {
        path: '/study/report',
        name: 'StudyReport',
        component: () => import('../views/Study/Report.vue'),
    },
    {
        path: '/study/history',
        name: 'StudyHistory',
        component: () => import('../views/Study/History.vue'),
    },
    // 公益資訊
    {
        path: '/news/banner',
        name: 'NewsBanner',
        component: () => import('../views/News/Banner.vue'),
    },
    {
        path: '/news/tagging',
        name: 'NewsTagging',
        component: () => import('../views/News/Tagging.vue'),
    },
    {
        path: '/news/article',
        name: 'NewsArticle',
        component: () => import('../views/News/Article.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
