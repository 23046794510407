<template>
    <div class="academy-walk page">
        <Search
            :show-type="true"
            :text="'標題/描述'"
            @search="search"
        />
        <Add
            @editData="editData"
        />
        <div class="table-block">
            <div class="total">
                總共: {{ total }} 筆資料
            </div>
            <v-data-table
                :headers="ApplyHeader"
                :items="list"
                :options.sync="options"
                :items-per-page="10"
                :server-items-length="total"
                :loading="loading"
                class="data-table"
                :footer-props="footerProps"
            >
                <template v-slot:item.type="{ item }">
                    {{ DataType(item.type) }}
                </template>
                <template v-slot:item.image.zhTw="{ item }">
                    <v-img
                        :src="image(item.image.zhTw)"
                        class="data-img"
                    />
                </template>
                <template v-slot:item.image.en="{ item }">
                    <v-img
                        :src="image(item.image.en)"
                        class="data-img"
                    />
                </template>
                <template v-slot:item.link.zhTw="{ item }">
                    <a
                        :href="item.link.zhTw"
                        target="_blank"
                    >{{ item.link.zhTw }}
                    </a>
                </template>
                <template v-slot:item.link.en="{ item }">
                    <a
                        :href="item.link.en"
                        target="_blank"
                    >{{ item.link.en }}
                    </a>
                </template>
                <template v-slot:item.operate="{ item }">
                    <v-btn
                        class="mx-1"
                        dark
                        small
                        depressed
                        :disabled="isOperate"
                        color="green"
                        @click="editData(item)"
                    >
                        <v-icon dark>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                    <v-btn
                        class="mx-1 delete-btn"
                        dark
                        small
                        depressed
                        :disabled="isOperate"
                        color="red"
                        @click="deleteData(item)"
                    >
                        <v-icon dark>
                            mdi-trash-can
                        </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </div>
        <LinkDialog
            ref="child"
            :edit-dialog="editDialog"
            @close="closeEdit"
            @saveEdit="saveEdit"
            @saveData="saveData"
            @search="search"
        />
    </div>
</template>

<script>
import API from '@/api';
import Mixin from '@/mixins/Mixin.vue';
import APIFormData from '@/api/libs/form-data';
import _ from 'lodash';
import ApplyHeader from '@/static-data/academy-manage-link-header.json';
import LinkDialog from '@/components/Dialog/LinkDialog.vue';

export default {
    components: { LinkDialog },
    mixins: [Mixin],
    data() {
        return {
            ApplyHeader,
            typeOptions: [
                { label: '正在報名的活動', value: 'onGoing' },
                { label: '過往活動', value: 'past' },
                { label: '全部', value: null },
            ],
        };
    },
    watch: {
        options: {
            handler() {
                this.search();
            },
            deep: true,
        },
    },
    methods: {
        DataType(type) {
            const number = _.findIndex(this.typeOptions, { value: type });
            return this.typeOptions[number].label;
        },
        async deleteData(item) {
            this.isOperate = true;
            try {
                // 刪除
                await API.EducationWalk.PrivateDeleteResource(item.id);
            } catch (e) {
                this.handleError({
                    message: e || '刪除異常',
                    color: 'error',
                });
            }
            this.search();
        },
        async search(filterItem = '') {
            // 搜尋
            const { page, itemsPerPage } = this.options;
            const { type, text } = filterItem;
            this.loading = true;
            const filter = [];
            if (type) {
                filter.push(`type:${type}`);
            }
            try {
                const res = await API.EducationWalk.PrivateList({
                    params: {
                        offset: (page - 1) * itemsPerPage,
                        limit: itemsPerPage,
                        filter: filter.join(';'),
                        search: text || undefined,
                    },
                });
                this.list = res.data.raw;
                this.total = res.data.total;
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.handleError({
                    message: e || '搜尋異常',
                    color: 'error',
                });
            }
            this.isOperate = false;
        },
        async saveData(item) {
            const data = {
                type: item.type,
                image: {
                    zhTw: item.image.zhTw,
                    en: item.image.en,
                },
                title: {
                    zhTw: item.title.zhTw,
                    en: item.title.en,
                },
                description: {
                    zhTw: item.description.zhTw,
                    en: item.description.en,
                },
                link: {
                    zhTw: item.link.zhTw,
                    en: item.link.en,
                },
            };
            const form = new APIFormData(data, [{ urls: [item.image.zhTw, item.image.en] }]);
            try {
                if (item.id) {
                // 修改
                    await API.EducationWalk.PrivateUpdateResource(item.id, form);
                    this.setAlert({
                        message: '更新成功',
                        color: 'success',
                    });
                } else {
                // 新增
                    await API.EducationWalk.PrivateCreateResource(form);
                    this.setAlert({
                        message: '新增成功',
                        color: 'success',
                    });
                }
            } catch (e) {
                this.handleError({
                    message: e || '新增修改異常',
                    color: 'error',
                });
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
