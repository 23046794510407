<template>
    <div class="filter">
        <div class="select-box">
            <v-select
                v-if="showThinkType"
                v-model="filter.thinkType"
                class="filter-select"
                :items="thinkOptions"
                label="類型"
                item-text="label"
                item-value="value"
                outlined
                clearable
            />
            <v-select
                v-if="showArticleType"
                v-model="filter.articleType"
                class="filter-select"
                :items="articleOptions"
                label="類型"
                item-text="label"
                item-value="value"
                outlined
                clearable
                multiple
            />
            <v-select
                v-if="showInventionType"
                v-model="filter.inventionType"
                class="filter-select"
                :items="inventionOptions"
                label="類型"
                item-text="label"
                item-value="value"
                outlined
                clearable
            />
            <v-select
                v-if="showBookSession"
                v-model="filter.bookSession"
                class="filter-select"
                :items="bookSessionOptions"
                label="屆數"
                item-text="label"
                item-value="value"
                outlined
                clearable
            />
            <v-select
                v-if="showInventionSession"
                v-model="filter.inventionSession"
                class="filter-select"
                :items="inventionSessionOptions"
                label="屆數"
                item-text="label"
                item-value="value"
                outlined
                clearable
            />
            <v-text-field
                v-if="text"
                v-model="filter.text"
                :label="`${text} 關鍵字`"
                :placeholder="`${text} 關鍵字`"
                outlined
                persistent-placeholder
                clearable
                class="search-input"
                @keypress.enter="search"
            />
            <v-select
                v-if="showTags"
                v-model="filter.tags"
                class="filter-select"
                :items="tagOptions"
                label="*標籤(中/英)"
                item-text="label"
                item-value="value"
                outlined
                multiple
                clearable
            />
            <v-text-field
                v-if="showTagText"
                v-model="filter.tagZhTw"
                class="filter-select"
                label="標籤(中文)"
                placeholder="請輸入完整標籤"
                outlined
                persistent-placeholder
                clearable
                @keypress.enter="search"
            />
            <v-text-field
                v-if="showTagText"
                v-model="filter.tagEn"
                class="filter-select"
                label="標籤(英文)"
                placeholder="請輸入完整標籤"
                outlined
                persistent-placeholder
                clearable
                @keypress.enter="search"
            />
        </div>
        <div class="btn-box">
            <v-btn
                class="btn"
                color="primary"
                @click="search"
            >
                搜尋
            </v-btn>
        </div>
    </div>
</template>
<script>
import API from '@/api';
import { mapMutations } from 'vuex';

export default {
    props: {
        showThinkType: {
            type: Boolean,
            default: () => false,
        },
        showArticleType: {
            type: Boolean,
            default: () => false,
        },
        showInventionType: {
            type: Boolean,
            default: () => false,
        },
        showBookSession: {
            type: Boolean,
            default: () => false,
        },
        showInventionSession: {
            type: Boolean,
            default: () => false,
        },
        showTags: {
            type: Boolean,
            default: () => false,
        },
        showTagText: {
            type: Boolean,
            default: () => false,
        },
        text: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            filter: {
                thinkType: null,
                inventionType: null,
                bookSession: null,
                inventionSession: null,
                text: null,
                tagZhTw: null,
                tagEn: null,
                articleType: [],
                tags: [],
            },
            thinkOptions: [
                { label: '正在報名的活動', value: 'onGoing' },
                { label: '過往活動', value: 'past' },
                { label: '全部', value: null },
            ],
            articleOptions: [
                { label: '最新資訊', value: '最新資訊' },
                { label: '社創專欄', value: '社創專欄' },
                { label: '團隊專欄', value: '團隊專欄' },
            ],
            inventionOptions: [
                { label: '大專組', value: 'college' },
                { label: '高中職組', value: 'senior' },
                { label: '全部', value: null },
            ],
            tagOptions: [],
            bookSessionOptions: [],
            inventionSessionOptions: [],
        };
    },
    watch: {
        options: {
            handler() {
                this.search();
            },
            deep: true,
        },
    },
    created() {
        this.search();
    },
    mounted() {
        this.getTags();
        this.getBookSessions();
        this.getInventionSessions();
    },
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        search() {
            this.$emit('search', this.filter);
        },
        async getTags() {
            try {
                const res = await API.ArticleTag.PrivateList();
                const tagsZH = [];
                const Options = [];
                for (let i = 0; i < res.data.total; i += 1) {
                    if (!tagsZH.includes(res.data.raw[i].value.zhTw)) {
                        tagsZH.push(res.data.raw[i].value.zhTw);
                        Options.push({
                            label: `${res.data.raw[i].value.zhTw}/${res.data.raw[i].value.en}`,
                            value: res.data.raw[i].value.zhTw,
                        });
                    }
                }
                this.tagOptions = Options;
            } catch (e) {
                this.handleError({
                    message: e || '標籤數據異常',
                    color: 'error',
                });
            }
        },
        async getBookSessions() {
            try {
                const res = await API.BookHistory.PrivateList();
                const session = [];
                const Options = [];
                for (let i = 0; i < res.data.total; i += 1) {
                    if (!session.includes(res.data.raw[i].session.zhTw)) {
                        session.push(res.data.raw[i].session.zhTw);
                        Options.push({
                            label: res.data.raw[i].session.zhTw,
                            value: res.data.raw[i].session.zhTw,
                        });
                    }
                }
                this.bookSessionOptions = Options;
            } catch (e) {
                this.handleError({
                    message: e || '屆數數據異常',
                    color: 'error',
                });
            }
        },
        async getInventionSessions() {
            try {
                const res = await API.InventionTeam.PrivateList();
                const session = [];
                const Options = [{ label: '全部', value: null }];
                for (let i = 0; i < res.data.total; i += 1) {
                    if (!session.includes(res.data.raw[i].session.zhTw)) {
                        session.push(res.data.raw[i].session.zhTw);
                        Options.push({
                            label: res.data.raw[i].session.zhTw,
                            value: res.data.raw[i].session.zhTw,
                        });
                    }
                }
                this.inventionSessionOptions = Options;
            } catch (e) {
                this.handleError({
                    message: e || '屆數數據異常',
                    color: 'error',
                });
            }
        },
    },
};
</script>
