<template>
    <div class="invention-qa page">
        <Search
            :show-type="true"
            :text="'標題/內容'"
            @search="search"
        />
        <Add
            @editData="editData"
        />
        <div class="table-block">
            <div class="total">
                總共: {{ total }} 筆資料
            </div>
            <v-data-table
                :headers="ApplyHeader"
                :items="list"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :loading="loading"
                class="data-table"
                :footer-props="footerProps"
            >
                <template v-slot:item.operate="{ item }">
                    <v-btn
                        class="mx-1"
                        dark
                        small
                        depressed
                        :disabled="isOperate"
                        color="green"
                        @click="editData(item)"
                    >
                        <v-icon dark>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                    <v-btn
                        class="mx-1 delete-btn"
                        dark
                        small
                        depressed
                        :disabled="isOperate"
                        color="red"
                        @click="deleteData(item)"
                    >
                        <v-icon dark>
                            mdi-trash-can
                        </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </div>
        <ListDialog
            ref="child"
            :show-title="true"
            :edit-dialog="editDialog"
            @close="closeEdit"
            @saveEdit="saveEdit"
            @saveData="saveData"
            @search="search"
        />
    </div>
</template>

<script>
import API from '@/api';
import Mixin from '@/mixins/Mixin.vue';
import ApplyHeader from '@/static-data/invention-manage-qa-apply-header.json';
import ListDialog from '@/components/Dialog/ListDialog.vue';

export default {
    components: { ListDialog },
    mixins: [Mixin],
    data() {
        return {
            ApplyHeader,
        };
    },
    watch: {
        options: {
            handler() {
                this.search();
            },
            deep: true,
        },
    },
    created() {
        this.search();
    },
    mounted() {},
    methods: {
        editData(item) {
            this.isOperate = true;
            this.$refs.child.editItem(item);
            this.editDialog = true;
        },
        async deleteData(item) {
            this.isOperate = true;
            try {
                // 刪除
                await API.InventionQA.PrivateDeleteResource(item.id);
            } catch (e) {
                this.handleError({
                    message: e || '刪除異常',
                    color: 'error',
                });
            }
            this.search();
        },
        async search(filterItem) {
            // 搜尋
            const { page, itemsPerPage } = this.options;
            const { text } = filterItem ?? {};
            this.loading = true;
            try {
                const res = await API.InventionQA.PrivateList({
                    params: {
                        offset: (page - 1) * itemsPerPage,
                        limit: itemsPerPage,
                        search: text || undefined,
                    },
                });
                this.list = res.data.raw;
                this.total = res.data.total;
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.handleError({
                    message: e || '搜尋異常',
                    color: 'error',
                });
            }
            this.isOperate = false;
        },
        async saveData(item) {
            const data = {
                title: {
                    zhTw: item.title.zhTw,
                    en: item.title.en,
                },
                content: {
                    zhTw: item.content.zhTw,
                    en: item.content.en,
                },
            };
            try {
                if (item.id) {
                // 修改
                    await API.InventionQA.PrivateUpdateResource(item.id, data);
                    this.setAlert({
                        message: '更新成功',
                        color: 'success',
                    });
                } else {
                // 新增
                    await API.InventionQA.PrivateCreateResource(data);
                    this.setAlert({
                        message: '新增成功',
                        color: 'success',
                    });
                }
            } catch (e) {
                this.handleError({
                    message: e || '新增修改異常',
                    color: 'error',
                });
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
