<template>
    <div class="nav">
        <div class="logo">
            <img
                src="../assets/logo.png"
                alt="Vision Future"
            >
        </div>
        <div class="menu">
            <div
                class="group"
            >
                <div class="title">
                    徵件評分
                </div>
                <div class="sub-menu">
                    <router-link
                        v-if="type==='invention'"
                        :to="{ name: 'SubmittedRatingInventionApply' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'SubmittedRatingInventionApply',
                        }"
                    >
                        慈悲科技
                    </router-link>
                    <router-link
                        v-if="type==='incubator'"
                        :to="{ name: 'SubmittedRatingIncubatorApply' }"
                        class="item"
                        :class="{
                            'router-active': activeMenuItem === 'SubmittedRatingIncubatorApply',
                        }"
                    >
                        青年公益
                    </router-link>
                </div>
            </div>
        </div>
        <div class="bottom">
            <v-btn
                elevation="2"
                color="#464646"
                class="change-password btn"
                @click="goto('ChangePassword')"
            >
                更改密碼
            </v-btn>
            <v-btn
                elevation="2"
                color="#464646"
                class="logout btn"
                @click="logout"
            >
                LOGOUT
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    components: {},
    data() {
        return {
            activeMenuItem: '',
        };
    },
    computed: {
        ...mapState('mAuth', ['type']),
        ...mapGetters('mAuth', ['permissions']),
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        $route(to, from) {
            this.checkActiveRouter();
        },
    },
    created() {
        this.checkActiveRouter();
    },
    mounted() { },
    methods: {
        ...mapActions('mAuth', ['Logout']),
        isPermission(value) {
            return this.permissions.includes(value) || this.permissions.includes('*');
        },
        checkActiveRouter() {
            this.activeMenuItem = this.$route.name;
        },
        logout() {
            this.Logout();
            this.goto('Login');
        },
        goto(routerName) {
            this.$router.push({ name: routerName });
        },
    },
};
</script>

<style lang="scss" scoped>
.nav {
    width: 300px;
    height: 100vh;
    overflow: auto;
    // min-height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0,0,0,0.3) 3px 0 10px;

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 1rem;
        margin: 15px;
        padding: 20px;
        img{
            width: 100%;
        }
    }

    .menu {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        .group {
            width: 100%;
        }

        .title {
            color: white;
            font-size: 24px;
            line-height: 1.2;
            letter-spacing: 5px;
            padding: 15px 20px;
        }

        .sub-menu {
            .item {
                width: 100%;
                color: white;
                text-decoration: none;
                font-weight: 400;
                font-size: 20px;
                line-height: 1.2;
                letter-spacing: 5px;
                padding: 10px 40px;
                display: block;
            }
            .item:not(:last-child) {
                margin-bottom: 10px;
            }

            .router-active {
                background-color: white;
                color: black;
            }
        }
    }
    .bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        margin-bottom: 40px;
        margin-top: 40px;
        .btn {
            width: 245px;
            color: white;
        }

        .btn:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}
</style>
