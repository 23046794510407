<template>
    <div class="dialog">
        <v-dialog
            v-model="show"
            max-width="80vw"
            persistent
            scrollable
        >
            <v-card>
                <CardTitle
                    :type="type"
                />
                <v-card-text>
                    <v-container>
                        <v-form
                            ref="form"
                            v-model="valid"
                        >
                            <div class="title-box">
                                <span class="title">中文版本</span>
                            </div>
                            <v-row
                                class="text-box"
                            >
                                <v-col
                                    cols="12"
                                >
                                    <p>*圖片_電腦版</p>
                                    <br>
                                    <v-input
                                        class="file-banner-box Des"
                                    >
                                        <v-file-input
                                            v-model="fileImgDes_zhTw"
                                            prepend-icon="mdi-camera"
                                            accept="image/png, image/jpeg"
                                            outlined
                                            class="file-input"
                                            :rules="[v => v.length===1 || '圖片不能為空']"
                                            @change="(value) => selectFileDes(value,'zhTw')"
                                        />
                                        <div
                                            class="file-img"
                                        >
                                            <v-img
                                                :src="image(editData.imageDes.zhTw)"
                                                width="576px"
                                                loading="true"
                                                contain
                                                position="left"
                                                class="change-img"
                                            >
                                                <v-btn
                                                    v-if="showUploadImgDes_zhTw"
                                                    width="576px"
                                                    height="120px"
                                                >
                                                    <v-icon>mdi-upload</v-icon>
                                                    *上傳圖片(建議 W:1440px H:300px)
                                                </v-btn>
                                            </v-img>
                                        </div>
                                        <div
                                            class="image-delete-btn"
                                        >
                                            <v-btn
                                                class="mx-2"
                                                fab
                                                dark
                                                small
                                                color="grey"
                                                @click="clearDes('zhTw')"
                                            >
                                                <v-icon dark>
                                                    mdi-close-thick
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-input>
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                    <p>*圖片_手機版</p>
                                    <br>
                                    <v-input
                                        class="file-banner-box Mob"
                                    >
                                        <v-file-input
                                            v-model="fileImgMob_zhTw"
                                            prepend-icon="mdi-camera"
                                            accept="image/png, image/jpeg"
                                            outlined
                                            class="file-input"
                                            :rules="[v => v.length===1 || '圖片不能為空']"
                                            @change="(value) => selectFileMob(value,'zhTw')"
                                        />
                                        <div
                                            class="file-mob-img"
                                        >
                                            <v-img
                                                :src="image(editData.imageMob.zhTw)"
                                                width="375px"
                                                loading="true"
                                                contain
                                                position="left"
                                                class="change-img"
                                            >
                                                <v-btn
                                                    v-if="showUploadImgMob_zhTw"
                                                    width="375px"
                                                    height="200px"
                                                >
                                                    <v-icon>mdi-upload</v-icon>
                                                    *上傳圖片(建議 W:375px H:200px)
                                                </v-btn>
                                            </v-img>
                                        </div>
                                        <div
                                            class="image-delete-btn"
                                        >
                                            <v-btn
                                                class="mx-2"
                                                fab
                                                dark
                                                small
                                                color="grey"
                                                @click="clearMob('zhTw')"
                                            >
                                                <v-icon dark>
                                                    mdi-close-thick
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-input>
                                </v-col>
                            </v-row>
                            <div class="title-box">
                                <span class="title">英文版本</span>
                            </div>
                            <v-row
                                class="text-box"
                            >
                                <v-col
                                    cols="12"
                                >
                                    <p>*圖片_電腦版</p>
                                    <br>
                                    <v-input
                                        class="file-banner-box Des"
                                    >
                                        <v-file-input
                                            v-model="fileImgDes_en"
                                            prepend-icon="mdi-camera"
                                            accept="image/png, image/jpeg"
                                            outlined
                                            class="file-input"
                                            :rules="[v => v.length===1 || '圖片不能為空']"
                                            @change="(value) => selectFileDes(value,'en')"
                                        />
                                        <div
                                            class="file-img"
                                        >
                                            <v-img
                                                :src="image(editData.imageDes.en)"
                                                width="576px"
                                                loading="true"
                                                contain
                                                position="left"
                                                class="change-img"
                                            >
                                                <v-btn
                                                    v-if="showUploadImgDes_en"
                                                    width="576px"
                                                    height="120px"
                                                >
                                                    <v-icon>mdi-upload</v-icon>
                                                    *上傳圖片(建議 W:1440px H:300px)
                                                </v-btn>
                                            </v-img>
                                        </div>
                                        <div
                                            class="image-delete-btn"
                                        >
                                            <v-btn
                                                class="mx-2"
                                                fab
                                                dark
                                                small
                                                color="grey"
                                                @click="clearDes('en')"
                                            >
                                                <v-icon dark>
                                                    mdi-close-thick
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-input>
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                    <p>*圖片_手機版</p>
                                    <br>
                                    <v-input
                                        class="file-banner-box Mob"
                                    >
                                        <v-file-input
                                            v-model="fileImgMob_en"
                                            prepend-icon="mdi-camera"
                                            accept="image/png, image/jpeg"
                                            outlined
                                            class="file-input"
                                            :rules="[v => v.length===1 || '圖片不能為空']"
                                            @change="(value) => selectFileMob(value,'en')"
                                        />
                                        <div
                                            class="file-mob-img"
                                        >
                                            <v-img
                                                :src="image(editData.imageMob.en)"
                                                width="375px"
                                                loading="true"
                                                contain
                                                position="left"
                                                class="change-img"
                                            >
                                                <v-btn
                                                    v-if="showUploadImgMob_en"
                                                    width="375px"
                                                    height="200px"
                                                >
                                                    <v-icon>mdi-upload</v-icon>
                                                    *上傳圖片(建議 W:375px H:200px)
                                                </v-btn>
                                            </v-img>
                                        </div>
                                        <div
                                            class="image-delete-btn"
                                        >
                                            <v-btn
                                                class="mx-2"
                                                fab
                                                dark
                                                small
                                                color="grey"
                                                @click="clearMob('en')"
                                            >
                                                <v-icon dark>
                                                    mdi-close-thick
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-input>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions
                    class="justify-end"
                >
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeEdit"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="submitEdit"
                    >
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CardTitle from '@/components/UI/CardTitle.vue';
import dialogMixin from '@/mixins/dialogMixin.vue';

export default {
    components: { CardTitle },
    mixins: [dialogMixin],
    data() {
        return {
            valid: false,
            showUploadImgDes_zhTw: true,
            showUploadImgDes_en: true,
            showUploadImgMob_zhTw: true,
            showUploadImgMob_en: true,
            editData: {
                imageDes: {
                    zhTw: '',
                    en: '',
                },
                imageMob: {
                    zhTw: '',
                    en: '',
                },
            },
            type: '',
            fileImgDes_zhTw: [],
            fileImgDes_en: [],
            fileImgMob_zhTw: [],
            fileImgMob_en: [],
        };
    },
    computed: {
    },
    created() {},
    mounted() {},
    methods: {
        editItem(data) {
            if (data) {
                this.type = '修改';
                this.editData = data;
                this.showUploadImgDes_zhTw = false;
                this.showUploadImgDes_en = false;
                this.showUploadImgMob_zhTw = false;
                this.showUploadImgMob_en = false;
                this.fileImgDes_zhTw = [{}];
                this.fileImgDes_en = [{}];
                this.fileImgMob_zhTw = [{}];
                this.fileImgMob_en = [{}];
            } else {
                this.type = '新增';
                this.editData = {
                    imageDes: {
                        zhTw: '',
                        en: '',
                    },
                    imageMob: {
                        zhTw: '',
                        en: '',
                    },
                };
            }
        },
        closeEdit() {
            this.show = false;
            this.$emit('search');
            this.fileImgDes_zhTw = [];
            this.fileImgDes_en = [];
            this.fileImgMob_zhTw = [];
            this.fileImgMob_en = [];
        },
        selectFileDes(file, data) {
            if (file && (file.constructor === File || file.constructor === Blob)) {
                this.editData.imageDes[`${data}`] = window.URL.createObjectURL(file);
                this[`fileImgDes_${data}`] = [{}];
                this[`showUploadImgDes_${data}`] = false;
            }
        },
        selectFileMob(file, data) {
            if (file && (file.constructor === File || file.constructor === Blob)) {
                this.editData.imageMob[`${data}`] = window.URL.createObjectURL(file);
                this[`fileImgMob_${data}`] = [{}];
                this[`showUploadImgMob_${data}`] = false;
            }
        },
        clearDes(data) {
            this.editData.imageDes[`${data}`] = '';
            this[`fileImgDes_${data}`] = [];
            this[`showUploadImgDes_${data}`] = true;
        },
        clearMob(data) {
            this.editData.imageMob[`${data}`] = '';
            this[`fileImgMob_${data}`] = [];
            this[`showUploadImgMob_${data}`] = true;
        },
        submitEdit() {
            if (this.$refs.form.validate()) {
                this.$emit('saveData', this.editData);
                this.show = false;
            }
        },
    },
};
</script>

<style lang="scss">
</style>
