<template>
    <v-app>
        <v-main>
            <div class="main">
                <ManagerNav v-if="isLogin && getRole==='manager'" />
                <JudgeNav v-if="isLogin && getRole==='judge'" />
                <router-view class="page-view" />
            </div>
        </v-main>
        <Alert />
        <Loading />
    </v-app>
</template>

<script>
import Alert from '@/components/UI/Alert.vue';
import Loading from '@/components/UI/Loading.vue';
import { mapGetters } from 'vuex';
import ManagerNav from '@/components/ManagerNav.vue';
import JudgeNav from '@/components/JudgeNav.vue';

export default {
    name: 'App',
    components: {
        ManagerNav,
        JudgeNav,
        Alert,
        Loading,
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters('mAuth', ['isLogin', 'getRole']),
    },
};
</script>

<style lang="scss">
* {
    font-family: 'Noto Sans TC', sans-serif;
    box-sizing: border-box !important;
}

.main {
    display: flex;
}

.page-view {
    width: calc(100vw - 300px);
    padding: 30px 20px;
    height: 100vh;
    overflow:scroll;
}
</style>
