<template>
    <div class="invention-detail">
        <v-form>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        團隊資訊
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.team.name"
                            outlined
                            label="團隊名稱"
                            placeholder=""
                            class="form-input"
                            readonly
                            background-color="#F2F2F2"
                        />
                        <v-text-field
                            v-model="form.team.school"
                            outlined
                            label="學校名稱"
                            placeholder=""
                            class="form-input"
                            readonly
                            background-color="#F2F2F2"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        指導老師
                    </div>
                    <div class="tips">
                        限定1-3人，最少填寫1人
                    </div>
                </div>
                <div class="form-content">
                    <div class="teacher-list">
                        <div
                            v-for="(item, index) in form.team.teachers"
                            :key="index"
                            class="teacher-item"
                        >
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.name"
                                    outlined
                                    :label="`老師${index + 1}`"
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                                <v-text-field
                                    v-model="item.phone"
                                    outlined
                                    label="手機號碼"
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                            </div>
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.title"
                                    outlined
                                    label="單位職稱"
                                    placeholder=""
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                                <v-text-field
                                    v-model="item.email"
                                    outlined
                                    label="電子信箱"
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        團隊成員
                    </div>
                    <div class="tips">
                        限定2-6人，第1人為隊長，隊長為主要聯絡人
                    </div>
                </div>
                <div class="form-content">
                    <div class="teacher-list">
                        <div
                            v-for="(item, index) in form.team.members"
                            :key="index"
                            class="member-item"
                        >
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.name"
                                    outlined
                                    :label="memberNameLabel(index)"
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                                <v-text-field
                                    v-model="item.phone"
                                    outlined
                                    label="手機號碼"
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                            </div>
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.department"
                                    outlined
                                    label="學校科系"
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                                <v-text-field
                                    v-model="item.email"
                                    outlined
                                    label="電子信箱"
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                            </div>
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.grade"
                                    outlined
                                    label="年級"
                                    readonly
                                    background-color="#F2F2F2"
                                    class="form-input"
                                />
                                <div class="member-file pdf-file">
                                    <v-icon class="icon">
                                        mdi-paperclip
                                    </v-icon>
                                    <a
                                        :href="$static(item.pdfFile)"
                                        target="_blank"
                                        class="preview-link"
                                    >在學證明或有效學生證（預覽）</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="apply-form-block team-image-block">
                <div class="left-block">
                    <div class="block-title">
                        團隊照片
                    </div>
                </div>
                <div class="form-content">
                    <v-card
                        elevation="4"
                        class="preview-card rounded-lg"
                    >
                        <v-img
                            height="300px"
                            :src="$static(form.team.previewImg)"
                            class="team-image"
                        />
                    </v-card>
                </div>
            </div>
            <!-- 報名來源 -->
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        報名來源
                    </div>
                </div>
                <div class="form-content">
                    <v-checkbox
                        v-model="form.team.refers"
                        label="學校活動公告(學校官網、海報、育成中心等)"
                        value="學校活動公告(學校官網、海報、育成中心等)"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        label="獎金獵人平台"
                        value="獎金獵人平台"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        label="FUN大視野官網"
                        value="FUN大視野官網"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        label="FUN大視野FACEBOOK"
                        value="FUN大視野FACEBOOK"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        label="FUN大視野INSTAGRAM"
                        value="FUN大視野INSTAGRAM"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            label="歷屆團隊推薦，團隊名:"
                            value="歷屆團隊推薦，團隊名:"
                            color="#898989"
                            class="checkbox"
                            readonly
                        />
                        <v-text-field
                            v-model="refersTeamName"
                            readonly
                            class="checkbox-input border-orange-input form-input"
                        />
                    </div>
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            label="親朋好友推薦，推薦人:"
                            value="親朋好友推薦，推薦人:"
                            color="#898989"
                            class="checkbox"
                            readonly
                        />
                        <v-text-field
                            v-model="refersRecommendName"
                            readonly
                            class="checkbox-input border-orange-input form-input"
                        />
                    </div>
                    <v-checkbox
                        v-model="form.team.refers"
                        label="曾經提案過"
                        value="曾經提案過"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        label="原本就知道"
                        value="原本就知道"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        label="慈濟基金會宣傳平台(官網、IG、LINE、大愛台等)"
                        value="慈濟基金會宣傳平台(官網、IG、LINE、大愛台等)"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            label="其他:"
                            value="其他:"
                            color="#898989"
                            class="checkbox"
                            readonly
                        />
                        <v-text-field
                            v-model="refersOther"
                            readonly
                            class="checkbox-input border-orange-input form-input"
                        />
                    </div>
                </div>
            </div>
            <!-- 提案 -->
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        提案
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.proposal.name"
                            outlined
                            label="作品名稱"
                            class="form-input"
                            readonly
                            background-color="#F2F2F2"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        參賽組別
                    </div>
                    <div class="tips">
                        自選1項
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.proposal.level"
                        class="radio-group"
                        readonly
                    >
                        <v-radio
                            value="大專校院組"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    大專校院組<span
                                        class="radio-tips"
                                    >：全國大專院校在學學生，含五專1-5
                                        年級、碩博士班。</span>
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="高中職組"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    高中職組<span
                                        class="radio-tips"
                                    >：全國高中職在學學生。</span>
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </div>
            </div>
            <!-- 自選主題 -->
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        自選主題
                    </div>
                    <div class="tips">
                        自選1項
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.proposal.main"
                        class="radio-group"
                        readonly
                    >
                        <div class="radio-type">
                            <div class="label">
                                慈善
                            </div>
                            <div class="block">
                                <v-radio
                                    value="慈善 1.以賑災為主，研發備災，賑災所需相關產品。"
                                    color="#898989"
                                >
                                    <template v-slot:label>
                                        <div class="radio-label">
                                            1.以賑災為主，研發備災，賑災所需相關產品。
                                        </div>
                                    </template>
                                </v-radio>
                                <v-radio
                                    value="慈善 2.以人文關懷為主，研發可以改善弱勢或偏鄉地區之民生或日常問題相關產品。"
                                    color="#898989"
                                >
                                    <template v-slot:label>
                                        <div class="radio-label">
                                            2.以人文關懷為主，研發可以改善弱勢或偏鄉地區之民生或日常問題相關產品。
                                        </div>
                                    </template>
                                </v-radio>
                            </div>
                        </div>
                        <div class="type">
                            <div class="label">
                                醫療
                            </div>
                            <div class="block">
                                <v-radio
                                    value="醫療 1.以長照2.0為主，研發可幫助解決銀髮族或失能之身心障礙者的生理健康或日常照護之產品。"
                                    color="#898989"
                                >
                                    <template v-slot:label>
                                        <div class="radio-label">
                                            1.以長照2.0為主，研發可幫助解決銀髮族或失能之身心障礙者的生理健康或日常照護之產品。
                                        </div>
                                    </template>
                                </v-radio>
                                <v-radio
                                    value="醫療 2.研發可以改善醫療職業環境，提升病人安全及居家照者之產品。"
                                    color="#898989"
                                >
                                    <template v-slot:label>
                                        <div class="radio-label">
                                            2.研發可以改善醫療職業環境，提升病人安全及居家照者之產品。
                                        </div>
                                    </template>
                                </v-radio>
                            </div>
                        </div>
                    </v-radio-group>
                </div>
            </div>
            <!-- 自選主題 -->
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        選擇主題
                    </div>
                    <div class="tips">
                        需符合SDGs中至少1項目標
                    </div>
                </div>
                <div class="form-content">
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs1"
                        value="SDGs1"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs2"
                        value="SDGs2"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs3"
                        value="SDGs3"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs4"
                        value="SDGs4"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs5"
                        value="SDGs5"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs6"
                        value="SDGs6"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs7"
                        value="SDGs7"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs8"
                        value="SDGs8"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs9"
                        value="SDGs9"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs10"
                        value="SDGs10"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs11"
                        value="SDGs11"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs12"
                        value="SDGs12"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs13"
                        value="SDGs13"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs14"
                        value="SDGs14"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs15"
                        value="SDGs15"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs16"
                        value="SDGs16"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        label="SDGs17"
                        value="SDGs17"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                </div>
            </div>
            <!-- 計劃書摘要 -->
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        計畫書摘要
                    </div>
                </div>
                <div class="form-content">
                    <v-textarea
                        v-model="form.proposal.abstract"
                        rows="10"
                        counter="300"
                        label="計畫摘要"
                        no-resize
                        outlined
                        readonly
                        background-color="#F2F2F2"
                    />
                </div>
            </div>
            <!-- 計畫書 -->
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        計畫書
                    </div>
                </div>
                <div class="form-content">
                    <div class="pdf-file">
                        <v-icon class="icon">
                            mdi-paperclip
                        </v-icon>
                        <a
                            :href="$static(form.proposal.pdfFile)"
                            target="_blank"
                            class="preview-link"
                        >團隊計畫書（預覽）</a>
                    </div>
                </div>
            </div>
            <v-btn
                v-if="!form.allowJudge && form.status==='submit'"
                class="mx-1 submit-btn"
                dark
                depressed
                color="green"
                @click="toSubmit()"
            >
                進入審核
            </v-btn>
            <!-- 評審員狀態列表 -->
            <JudgeLists
                :id="$route.params.id"
                :type="'invention'"
                :data="scorekeeper"
                @getData="getData"
            />
        </v-form>
    </div>
</template>

<script>
import API from '@/api';
import { mapMutations } from 'vuex';
import JudgeLists from '@/components/JudgeLists.vue';

export default {
    components: { JudgeLists },
    data() {
        return {
            refersTeamName: '',
            refersRecommendName: '',
            refersOther: '',
            form: {
                team: {
                    name: '',
                    previewImg: '',
                    school: '',
                    refers: [],
                    teachers: [{
                        name: '',
                        title: '',
                        phone: '',
                        email: '',
                    }],
                    members: [{
                        name: '',
                        phone: '',
                        email: '',
                        department: '',
                        grade: '',
                        pdfFile: '',
                    },
                    {
                        name: '',
                        phone: '',
                        email: '',
                        department: '',
                        grade: '',
                        pdfFile: '',
                    }],
                },
                proposal: {
                    name: '',
                    level: '',
                    main: '',
                    subs: [],
                    abstract: '',
                    pdfFile: '',
                },
                status: '',
            },
            scorekeeper: [],
        };
    },
    computed: {},
    created() {
        this.getData();
    },
    mounted() {
    },
    methods: {
        ...mapMutations('mNotification', ['handleError']),
        ...mapMutations('mLoading', ['setLoading']),
        updateRefers() {
            // 修改報名來源資料
            const option1 = '歷屆團隊推薦，團隊名:';
            const option2 = '親朋好友推薦，推薦人:';
            const option3 = '其他:';
            this.form.team.refers = this.form.team.refers.map((item) => {
                if (item.includes(option1)) {
                    this.refersTeamName = item.split(option1)[1] ? item.split(option1)[1] : '';
                    return option1;
                } if (item.includes(option2)) {
                    this.refersRecommendName = item.split(option2)[1] ? item.split(option2)[1] : '';
                    return option2;
                } if (item.includes(option3)) {
                    this.refersOther = item.split(option3)[1] ? item.split(option3)[1] : '';
                    return option3;
                }
                return item;
            });
        },
        async getData() {
            const { id } = this.$route.params;
            try {
                this.setLoading(true);
                const res = await API.InventionApply.PrivateFetchResource(id);
                this.form = res.data;
                this.updateRefers();
                this.setLoading(false);
            } catch (e) {
                console.log('error', e);
                this.setLoading(false);
                this.handleError({
                    message: e || '連線異常',
                    color: 'error',
                });
            }
            try {
                const res = await API.JudgeCommitUtil.ListSubmitCommits('invention', id);
                this.scorekeeper = res.data;
            } catch (e) {
                console.log('error', e);
                this.handleError({
                    message: e || '連線異常',
                    color: 'error',
                });
            }
        },
        memberNameLabel(index) {
            if (index === 0) {
                return '隊長';
            }
            return `隊員${index}`;
        },
        async toSubmit() {
            const { id } = this.$route.params;
            await API.InventionApplyUtil.AllowJudge(id);
            this.getData();
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/apply.scss';

.invention-detail {
    padding: 50px 40px;
    .submit-btn{
        width: 164.38px;
        height: 38px;
        border-radius: 8px;
        font-size: 20px;
        font-weight: 400;
    }
    .member-file {
        flex: 1;
        display: flex;
        align-items: center;
        margin-top: -50px;
    }

    .team-image-block {
        .preview-card {
            width: 300px;
            height: 300px;
        }
    }

    .pdf-file {
        a {
            color: $color-orange;
            font-weight: 700;
            letter-spacing: 0.1em;
            margin-left: 5px;
        }
    }

    .actions {
        justify-content: center;

        .btn:first-child {
            margin-right: 30px;
        }
    }
}
.checkbox-input{
    padding-top: 0 !important;
    margin-top: 0 !important;
    width: 100%;
    flex: unset;
}
.checkbox-input input{
    padding-left: 10px;
}
@media (min-width: 768px) {
    .checkbox-input{
        max-width: 185px !important;
    }
}
</style>
