<template>
    <div class="incubator-detail">
        <v-form>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        組織資料
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.team.organizeName"
                            outlined
                            label="組織名稱"
                            placeholder=""
                            hint="請填寫立案登記名稱"
                            persistent-hint
                            readonly
                            background-color="#F2F2F2"
                            class="form-input"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        參賽組別
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.team.type"
                        class="radio-group"
                        readonly
                    >
                        <v-radio
                            value="非正式立案組織（個人團隊）"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    非正式立案組織（個人團隊）
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="非營利組織"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    非營利組織
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="營利組織"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    營利組織
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        組織登記地區
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-select
                            v-model="form.team.country"
                            :items="countries"
                            outlined
                            label="國家"
                            class="form-input"
                            readonly
                            background-color="#F2F2F2"
                        />
                        <v-text-field
                            v-if="form.team.country !== '臺灣'"
                            v-model="form.team.city"
                            outlined
                            :label="`國家`"
                            placeholder=""
                            persistent-hint
                            class="form-input"
                            readonly
                            background-color="#F2F2F2"
                        />
                        <v-select
                            v-else
                            v-model="form.team.city"
                            :items="cities"
                            outlined
                            label="城市"
                            class="form-input"
                            readonly
                            background-color="#F2F2F2"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        統一編號
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.team.taxId"
                            outlined
                            label="統一編號"
                            placeholder=""
                            hint="選填"
                            persistent-hint
                            class="form-input"
                            readonly
                            background-color="#F2F2F2"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        團隊資料
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.team.name"
                            outlined
                            label="團隊名稱"
                            placeholder=""
                            hint="必填"
                            persistent-hint
                            class="form-input"
                            readonly
                            background-color="#F2F2F2"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        團隊成員
                    </div>
                    <div class="tips">
                        限定1-10人，第1人為隊長，<br>隊長為主要聯絡人。
                    </div>
                </div>
                <div class="form-content">
                    <div class="member-list">
                        <div
                            v-for="(item, index) in form.team.members"
                            :key="index"
                            class="member-item"
                        >
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.name"
                                    outlined
                                    :label="memberNameLabel(index)"
                                    placeholder=""
                                    hint="必填"
                                    persistent-hint
                                    class="form-input"
                                    readonly
                                    background-color="#F2F2F2"
                                />
                                <v-text-field
                                    v-model="item.phone"
                                    outlined
                                    label="聯絡電話"
                                    placeholder=""
                                    hint="必填"
                                    persistent-hint
                                    class="form-input"
                                    readonly
                                    background-color="#F2F2F2"
                                />
                            </div>
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.department"
                                    outlined
                                    label="單位職稱/學校系級"
                                    placeholder=""
                                    hint="必填"
                                    persistent-hint
                                    class="form-input"
                                    readonly
                                    background-color="#F2F2F2"
                                />

                                <v-text-field
                                    v-model="item.birthday"
                                    outlined
                                    label="出生年月日"
                                    class="form-input"
                                    placeholder="出生年月日"
                                    hint="必填"
                                    persistent-hint
                                    readonly
                                    background-color="#F2F2F2"
                                    append-icon="mdi-calendar"
                                />
                            </div>
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.email"
                                    outlined
                                    label="聯絡信箱"
                                    placeholder=""
                                    hint="必填"
                                    persistent-hint
                                    class=""
                                    readonly
                                    background-color="#F2F2F2"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 報名來源 -->
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        報名來源
                    </div>
                </div>
                <div class="form-content">
                    <v-checkbox
                        v-model="form.team.refers"
                        label="FUN大視野官網"
                        value="FUN大視野官網"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        label="FUN大視野FACEBOOK"
                        value="FUN大視野FACEBOOK"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        label="FUN大視野INSTAGRAM"
                        value="FUN大視野INSTAGRAM"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        label="FUN大視野電子報"
                        value="FUN大視野電子報"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        label="學校活動公告(學校官網、海報、育成中心等)"
                        value="學校活動公告(學校官網、海報、育成中心等)"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        label="縣市政府宣傳平台"
                        value="縣市政府宣傳平台"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        label="社會創新平台官網"
                        value="社會創新平台官網"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        label="獎金獵人平台"
                        value="獎金獵人平台"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            label="歷屆團隊推薦，團隊名:"
                            value="歷屆團隊推薦，團隊名:"
                            color="#898989"
                            class="checkbox"
                            readonly
                        />
                        <v-text-field
                            v-model="refersTeamName"
                            class="checkbox-input form-input"
                            readonly
                        />
                    </div>
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            label="親朋好友推薦，推薦人:"
                            value="親朋好友推薦，推薦人:"
                            color="#898989"
                            class="checkbox"
                            readonly
                        />
                        <v-text-field
                            v-model="refersRecommendName"
                            class="checkbox-input form-input"
                            readonly
                        />
                    </div>
                    <v-checkbox
                        v-model="form.team.refers"
                        label="曾經提案過"
                        value="曾經提案過"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        label="原本就知道"
                        value="原本就知道"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        label="IMPACT HUB TAIPEI宣傳平台(官網、電子報、口頭邀約等)"
                        value="IMPACT HUB TAIPEI宣傳平台(官網、電子報、口頭邀約等)"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        label="慈濟基金會宣傳平台(官網、IG、LINE、大愛台等)"
                        value="慈濟基金會宣傳平台(官網、IG、LINE、大愛台等)"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            label="媒體宣傳平台，平台名:"
                            value="媒體宣傳平台，平台名:"
                            color="#898989"
                            class="checkbox"
                            readonly
                        />
                        <v-text-field
                            v-model="refersPlatformName
                            "
                            class="checkbox-input form-input"
                            readonly
                        />
                    </div>
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            label="其他:"
                            value="其他:"
                            color="#898989"
                            class="checkbox"
                            readonly
                        />
                        <v-text-field
                            v-model="refersOther"
                            class="checkbox-input form-input"
                            readonly
                        />
                    </div>
                </div>
            </div>
            <!-- 提案 -->
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        提案資料
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.proposal.name"
                            outlined
                            label="計畫名稱"
                            placeholder=""
                            hint="必填"
                            persistent-hint
                            class="form-input"
                            readonly
                            background-color="#F2F2F2"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        團隊屬性
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.proposal.property"
                        class="radio-group"
                        readonly
                    >
                        <v-radio
                            value="臺灣團隊-孵化組"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    臺灣團隊-孵化組
                                </div>
                            </template>
                        </v-radio>
                        <div class="radio-content">
                            <ol>
                                <li>
                                    投件計畫/專案之隊長可投入一半以上工作時間（含全職）執行計畫。
                                </li>
                                <li>
                                    計畫/專案已有明確回饋社會公益之方向及目標。
                                </li>
                                <li>
                                    計畫/專案之年營收/年捐款在新台幣3百萬元以下。
                                </li>
                            </ol>
                        </div>
                        <v-radio
                            value="臺灣團隊-加速組"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    臺灣團隊-加速組
                                </div>
                            </template>
                        </v-radio>
                        <div class="radio-content">
                            <ol>
                                <li>
                                    計畫/專案已有穩定團隊成員，並期待在未來一年內擴大營運規模。
                                </li>
                                <li>
                                    計畫/專案期待在未來一年內調整與優化營運模式。
                                </li>
                                <li>
                                    計畫/專案之年營收/年捐款在新台幣3百萬元以上。
                                </li>
                            </ol>
                        </div>
                        <v-radio
                            value="亞太地區團隊"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    亞太地區團隊
                                </div>
                            </template>
                        </v-radio>
                        <div class="radio-content">
                            <ol>
                                <li>
                                    團隊所有成員皆須為40歲（含）以下青年，申請人必須符合本計畫之地區國籍。
                                </li>
                                <li>
                                    投件計畫/專案之團隊隊長與1位成員於孵化期間依計畫於臺灣進行實體培力課程、總導師與業師會議。
                                </li>
                                <li>
                                    計畫/專案已有明確回饋社會公益之方向及目標。
                                </li>
                                <li>
                                    計畫/專案期待在未來一年內調整與優化營運模式。
                                </li>
                            </ol>
                        </div>
                    </v-radio-group>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        提案內容
                    </div>
                </div>
                <div class="form-content">
                    <v-textarea
                        v-model="form.proposal.abstract"
                        rows="10"
                        counter="500"
                        label="提案內容"
                        no-resize
                        outlined
                        placeholder=""
                        hint="必填，500字以內"
                        persistent-hint
                        readonly
                        background-color="#F2F2F2"
                    />
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        議題
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.proposal.issue"
                        class="radio-group"
                        readonly
                    >
                        <v-radio
                            value="慈善創新"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    慈善創新
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="醫療照護"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    醫療照護
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="教育創新"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    教育創新
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="環境保護"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    環境保護
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="循環經濟"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    循環經濟
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="青銀共創"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    青銀共創
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="食糧供應創新"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    食糧供應創新
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="災害防救創新"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    災害防救創新
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="地方創生/社區營造"
                            color="#898989"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    地方創生/社區營造
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </div>
            </div>
            <div class="apply-form-block team-image-block">
                <div class="left-block">
                    <div class="block-title">
                        團隊照片
                    </div>
                </div>
                <div class="form-content">
                    <v-card
                        elevation="4"
                        class="preview-card rounded-lg"
                    >
                        <v-img
                            height="300px"
                            :src="$static(form.proposal.img)"
                            class="team-image"
                        />
                    </v-card>
                </div>
            </div>
            <div class="apply-form-block">
                <!-- 計畫書 -->
                <div class="apply-form-block">
                    <div class="left-block">
                        <div class="block-title">
                            計畫書
                        </div>
                    </div>
                    <div class="form-content">
                        <div class="pdf-file">
                            <v-icon class="icon">
                                mdi-paperclip
                            </v-icon>
                            <a
                                :href="$static(form.proposal.pdfFile)"
                                target="_blank"
                                class="preview-link"
                            >
                                計畫書（預覽）</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <!-- 決審簡報 -->
                <div class="apply-form-block">
                    <div class="left-block">
                        <div class="block-title">
                            決審簡報
                        </div>
                    </div>
                    <div class="form-content">
                        <div
                            v-if="form.final && form.final.pdfFile"
                            class="pdf-file"
                        >
                            <v-icon class="icon">
                                mdi-paperclip
                            </v-icon>
                            <a
                                :href="$static(form.final.pdfFile)"
                                target="_blank"
                                class="preview-link"
                            >
                                決審簡報（預覽）</a>
                        </div>
                        <div
                            v-else
                            class=""
                        >
                            無資料
                        </div>
                    </div>
                </div>
            </div>
            <v-btn
                v-if="!form.allowJudge && form.status==='submit'"
                class="mx-1 submit-btn"
                dark
                depressed
                color="green"
                @click="toSubmit()"
            >
                進入審核
            </v-btn>
            <!-- 評審員狀態列表 -->
            <JudgeLists
                :id="$route.params.id"
                :type="'incubator'"
                :data="scorekeeper"
                @getData="getData"
            />
        </v-form>
    </div>
</template>

<script>
import API from '@/api';
import { mapMutations } from 'vuex';
import JudgeLists from '@/components/JudgeLists.vue';

export default {
    components: { JudgeLists },
    data() {
        return {
            refersTeamName: '',
            refersRecommendName: '',
            refersPlatformName: '',
            refersOther: '',
            form: {
                team: {
                    organizeName: '', // 組織名稱
                    type: '', // 參賽組別
                    country: '', // 組織登記地區-國家
                    city: '', // 組織登記地區-城市
                    taxId: '', // 統一編號
                    name: '', // 團隊資料
                    members: [{
                        name: '', // 團隊成員-姓名
                        phone: '', // 團隊成員-電話
                        email: '', // 團隊成員-email
                        department: '', // 團隊成員-單位職稱/學校系級
                        birthday: '', // 團隊成員-生日
                    },
                    ],
                },
                proposal: {
                    name: '', // 計畫名稱
                    property: '', // 團隊屬性
                    abstract: '', // 提案內容
                    issue: '', // 議題
                    pdfFile: '', // 計畫書
                    img: '', // 團隊照片
                },
                final: {
                    pdfFile: '', // 決審簡報
                },
            },
            status: '',
            countries: ['臺灣', '其他'],
            cities: ['臺北市', '新北市', '基隆市', '桃園市', '新竹市', '新竹縣', '苗栗縣', '臺中市', '彰化縣', '南投縣', '雲林縣', '嘉義市', '嘉義縣', '臺南市', '高雄市', '屏東縣', '宜蘭縣', '花蓮縣', '臺東縣', '澎湖縣', '金門縣', '連江縣'],
            scorekeeper: [],
        };
    },
    computed: {},
    created() {
        this.getData();
    },
    mounted() { },
    methods: {
        ...mapMutations('mNotification', ['handleError']),
        ...mapMutations('mLoading', ['setLoading']),
        updateRefers() {
            // 修改報名來源資料
            const option1 = '歷屆團隊推薦，團隊名:';
            const option2 = '親朋好友推薦，推薦人:';
            const option3 = '媒體宣傳平台，平台名:';
            const option4 = '其他:';
            this.form.team.refers = this.form.team.refers.map((item) => {
                if (item.includes(option1)) {
                    this.refersTeamName = item.split(option1)[1] ? item.split(option1)[1] : '';
                    return option1;
                } if (item.includes(option2)) {
                    this.refersRecommendName = item.split(option2)[1] ? item.split(option2)[1] : '';
                    return option2;
                } if (item.includes(option3)) {
                    this.refersPlatformName = item.split(option3)[1] ? item.split(option3)[1] : '';
                    return option3;
                } if (item.includes(option4)) {
                    this.refersOther = item.split(option4)[1] ? item.split(option4)[1] : '';
                    return option4;
                }
                return item;
            });
        },
        async getData() {
            const { id } = this.$route.params;
            try {
                this.setLoading(true);
                const res = await API.IncubatorApply.PrivateFetchResource(id);
                this.form = res.data;
                this.updateRefers();
                console.log('PrivateFetchResource', res);
                this.setLoading(false);
            } catch (e) {
                console.log('error', e);
                this.setLoading(false);
                this.handleError({
                    message: e || '連線異常',
                    color: 'error',
                });
            }
            try {
                const res = await API.JudgeCommitUtil.ListSubmitCommits('incubator', id);
                this.scorekeeper = res.data;
            } catch (e) {
                console.log('error', e);
                this.handleError({
                    message: e || '連線異常',
                    color: 'error',
                });
            }
        },
        memberNameLabel(index) {
            if (index === 0) {
                return '隊長';
            }
            return `隊員${index}`;
        },
        async toSubmit() {
            const { id } = this.$route.params;
            await API.IncubatorApplyUtil.AllowJudge(id);
            this.getData();
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/apply.scss';

.incubator-detail {
    padding: 50px 40px;
    .submit-btn{
        width: 164.38px;
        height: 38px;
        border-radius: 8px;
        font-size: 20px;
        font-weight: 400;
    }
    .member-file {
        flex: 1;
        display: flex;
        align-items: center;
        margin-top: -50px;
    }

    .team-image-block {
        .preview-card {
            width: 300px;
            height: 300px;
        }
    }

    .pdf-file {
        a {
            color: $color-orange;
            font-weight: 700;
            letter-spacing: 0.1em;
            margin-left: 5px;
        }
    }

    .actions {
        justify-content: center;

        .btn:first-child {
            margin-right: 30px;
        }
    }

    .radio-content {
        padding-left: 34px;
        margin-bottom: 10px;
    }
}
.checkbox-input{
    padding-top: 0 !important;
    margin-top: 0 !important;
    width: 100%;
    flex: unset;
}
.checkbox-input input{
    padding-left: 10px;
}
@media (min-width: 768px) {
    .checkbox-input{
        max-width: 185px !important;
    }
}
</style>
