<template>
    <div class="dialog">
        <v-dialog
            v-model="show"
            max-width="80vw"
            persistent
            scrollable
        >
            <v-card>
                <CardTitle
                    :type="type"
                />
                <v-card-text>
                    <v-container>
                        <v-form
                            ref="form"
                            v-model="valid"
                        >
                            <div class="title-box">
                                <span class="title">中文版本</span>
                            </div>
                            <v-row
                                class="text-box"
                            >
                                <v-col
                                    cols="12"
                                >
                                    <v-text-field
                                        v-model="editData.value.zhTw"
                                        label="*標籤"
                                        outlined
                                        persistent-placeholder
                                        clearable
                                        :rules="[v => !!v || '此欄位為必填']"
                                    />
                                </v-col>
                            </v-row>
                            <div class="title-box">
                                <span class="title">英文版本</span>
                            </div>
                            <v-row
                                class="text-box"
                            >
                                <v-col
                                    cols="12"
                                >
                                    <v-text-field
                                        v-model="editData.value.en"
                                        label="*標籤"
                                        outlined
                                        persistent-placeholder
                                        clearable
                                        :rules="[v => !!v || '此欄位為必填']"
                                    />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions
                    class="justify-end"
                >
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeEdit"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="submitEdit"
                    >
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CardTitle from '@/components/UI/CardTitle.vue';
import dialogMixin from '@/mixins/dialogMixin.vue';

export default {
    components: { CardTitle },
    mixins: [dialogMixin],
    data() {
        return {
            valid: false,
            editData: {
                value: {
                    zhTw: '',
                    en: '',
                },
            },
            type: '',
        };
    },
    computed: {
    },
    created() {},
    mounted() {},
    methods: {
        editItem(data) {
            if (data) {
                this.type = '修改';
                this.editData = data;
            } else {
                this.type = '新增';
                this.editData = {
                    value: {
                        zhTw: '',
                        en: '',
                    },
                };
            }
        },
        closeEdit() {
            this.show = false;
            this.$emit('search');
        },
        submitEdit() {
            if (this.$refs.form.validate()) {
                this.$emit('saveData', this.editData);
                this.show = false;
            }
        },
    },
};
</script>

<style lang="scss">
</style>
