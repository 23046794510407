<template>
    <div class="dialog">
        <v-dialog
            v-model="show"
            max-width="80vw"
            persistent
            :retain-focus="false"
            eager
            scrollable
        >
            <v-card>
                <CardTitle
                    :type="type"
                />
                <v-card-text>
                    <v-container>
                        <v-form
                            ref="form"
                            v-model="valid"
                        >
                            <v-row>
                                <v-col
                                    cols="6"
                                >
                                    <v-select
                                        v-model="editData.category"
                                        :items="typeOptions"
                                        label="*類型"
                                        item-text="label"
                                        item-value="value"
                                        outlined
                                        clearable
                                        :rules="[v => !!v || '此欄位為必填']"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-select
                                        v-model="chooseTag"
                                        :items="tagsOptions"
                                        label="*標籤(中/英)"
                                        item-text="label"
                                        item-value="value"
                                        outlined
                                        clearable
                                        multiple
                                        :rules="[v => !!v || '此欄位為必填']"
                                    />
                                </v-col>
                            </v-row>
                            <div class="title-box">
                                <span class="title">中文版本</span>
                            </div>
                            <v-row
                                class="text-box"
                            >
                                <v-col
                                    cols="6"
                                >
                                    <v-textarea
                                        v-model="editData.title.zhTw"
                                        label="*標題"
                                        outlined
                                        clearable
                                        no-resize
                                        rows="2"
                                        :rules="[v => !!v || '此欄位為必填']"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-textarea
                                        v-model="editData.description.zhTw"
                                        label="*描述"
                                        outlined
                                        clearable
                                        no-resize
                                        rows="2"
                                        :rules="[v => !!v || '此欄位為必填']"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-input
                                        class="file-box"
                                    >
                                        <v-file-input
                                            v-model="fileImg_zhTw"
                                            prepend-icon="mdi-camera"
                                            accept="image/png, image/jpeg"
                                            outlined
                                            class="file-input"
                                            :rules="[v => v.length===1 || '圖片不能為空']"
                                            @change="(value) => selectFile(value,'zhTw')"
                                        />
                                        <div
                                            class="file-img"
                                        >
                                            <v-img
                                                :src="image(editData.image.zhTw)"
                                                height="210px"
                                                width="fit-content"
                                                loading="true"
                                                contain
                                                position="left"
                                                class="change-img"
                                            >
                                                <v-btn
                                                    v-if="showUploadImg_zhTw"
                                                    width="280px"
                                                    height="210px"
                                                >
                                                    <v-icon>mdi-upload</v-icon>
                                                    *上傳圖片(建議 W:280px H:210px)
                                                </v-btn>
                                            </v-img>
                                        </div>
                                        <div
                                            class="image-delete-btn"
                                        >
                                            <v-btn
                                                class="mx-2"
                                                fab
                                                dark
                                                small
                                                color="grey"
                                                @click="clear('zhTw')"
                                            >
                                                <v-icon dark>
                                                    mdi-close-thick
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-input>
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                    <p>*文章內容</p>
                                    <v-input
                                        v-model="editData.content.zhTw"
                                        :rules="[v => !!v || '此欄位為必填']"
                                    >
                                        <editor
                                            v-if="editDialog"
                                            v-model="editData.content.zhTw"
                                            :init="init"
                                        />
                                    </v-input>
                                </v-col>
                            </v-row>
                            <div class="title-box">
                                <span class="title">英文版本</span>
                            </div>
                            <v-row
                                class="text-box"
                            >
                                <v-col
                                    cols="6"
                                >
                                    <v-textarea
                                        v-model="editData.title.en"
                                        label="*標題"
                                        outlined
                                        clearable
                                        no-resize
                                        rows="2"
                                        :rules="[v => !!v || '此欄位為必填']"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-textarea
                                        v-model="editData.description.en"
                                        label="*描述"
                                        outlined
                                        clearable
                                        no-resize
                                        rows="2"
                                        :rules="[v => !!v || '此欄位為必填']"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-input
                                        class="file-box"
                                    >
                                        <v-file-input
                                            v-model="fileImg_en"
                                            prepend-icon="mdi-camera"
                                            accept="image/png, image/jpeg"
                                            outlined
                                            class="file-input"
                                            :rules="[v => v.length===1 || '圖片不能為空']"
                                            @change="(value) => selectFile(value,'en')"
                                        />
                                        <div
                                            class="file-img"
                                        >
                                            <v-img
                                                :src="image(editData.image.en)"
                                                height="210px"
                                                width="fit-content"
                                                loading="true"
                                                contain
                                                position="left"
                                                class="change-img"
                                            >
                                                <v-btn
                                                    v-if="showUploadImg_en"
                                                    width="280px"
                                                    height="210px"
                                                >
                                                    <v-icon>mdi-upload</v-icon>
                                                    *上傳圖片(建議 W:280px H:210px)
                                                </v-btn>
                                            </v-img>
                                        </div>
                                        <div
                                            class="image-delete-btn"
                                        >
                                            <v-btn
                                                class="mx-2"
                                                fab
                                                dark
                                                small
                                                color="grey"
                                                @click="clear('en')"
                                            >
                                                <v-icon dark>
                                                    mdi-close-thick
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-input>
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                    <p>*文章內容</p>
                                    <v-input
                                        v-model="editData.content.en"
                                        :rules="[v => !!v || '此欄位為必填']"
                                    >
                                        <editor
                                            v-if="editDialog"
                                            v-model="editData.content.en"
                                            :init="init"
                                        />
                                    </v-input>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions
                    class="justify-end"
                >
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeEdit"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="submitEdit"
                    >
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import API from '@/api';
import tinymceMixin from '@/mixins/tinymceMixin.vue';
import { mapMutations } from 'vuex';
import CardTitle from '@/components/UI/CardTitle.vue';
import dialogMixin from '@/mixins/dialogMixin.vue';

export default {
    components: {
        CardTitle,
    },
    mixins: [tinymceMixin, dialogMixin],
    data() {
        return {
            showUploadImg_zhTw: true,
            showUploadImg_en: true,
            valid: false,
            editData: {
                category: '',
                image: {
                    zhTw: '',
                    en: '',
                },
                title: {
                    zhTw: '',
                    en: '',
                },
                description: {
                    zhTw: '',
                    en: '',
                },
                content: {
                    zhTw: '',
                    en: '',
                },
                tags: {
                    zhTw: [],
                    en: [],
                },
                images: [],
            },
            chooseTag: [],
            type: '',
            fileImg_zhTw: [],
            fileImg_en: [],
            typeOptions: [
                { label: '最新資訊', value: '最新資訊' },
                { label: '社創專欄', value: '社創專欄' },
                { label: '團隊專欄', value: '團隊專欄' },
            ],
            tagsOptions: [],
        };
    },
    computed: {
    },
    created() {},
    mounted() {
        this.tags();
    },
    methods: {
        ...mapMutations('mNotification', ['handleError']),
        editItem(data) {
            if (data) {
                this.chooseTag = data.tags.zhTw;
                this.type = '修改';
                this.editData = data;
                this.showUploadImg_zhTw = false;
                this.showUploadImg_en = false;
                this.fileImg_zhTw = [{}];
                this.fileImg_en = [{}];
            } else {
                this.type = '新增';
                this.editData = {
                    category: '',
                    image: {
                        zhTw: '',
                        en: '',
                    },
                    title: {
                        zhTw: '',
                        en: '',
                    },
                    description: {
                        zhTw: '',
                        en: '',
                    },
                    content: {
                        zhTw: '',
                        en: '',
                    },
                    tags: {
                        zhTw: [],
                        en: [],
                    },
                    images: [],
                };
            }
        },
        closeEdit() {
            this.show = false;
            this.$emit('search');
            this.fileImg_zhTw = [];
            this.fileImg_en = [];
        },
        selectFile(file, data) {
            if (file && (file.constructor === File || file.constructor === Blob)) {
                this.editData.image[`${data}`] = window.URL.createObjectURL(file);
                this[`fileImg_${data}`] = [{}];
                this[`showUploadImg_${data}`] = false;
            }
        },
        clear(data) {
            this.editData.image[`${data}`] = '';
            this[`fileImg_${data}`] = [];
            this[`showUploadImg_${data}`] = true;
        },
        async tags() {
            try {
                const res = await API.ArticleTag.PrivateList();
                const tagsZH = [];
                const Options = [];
                for (let i = 0; i < res.data.total; i += 1) {
                    if (!tagsZH.includes(res.data.raw[i].value.zhTw)) {
                        tagsZH.push(res.data.raw[i].value.zhTw);
                        Options.push({
                            label: `${res.data.raw[i].value.zhTw}/${res.data.raw[i].value.en}`,
                            value: res.data.raw[i].value.zhTw,
                            en: res.data.raw[i].value.en,
                        });
                    }
                }
                this.tagsOptions = Options;
            } catch (e) {
                this.handleError({
                    message: e || '屆數數據異常',
                    color: 'error',
                });
            }
        },
        async submitEdit() {
            const imageBaseUrl = `https://${process.env.VUE_APP_BASE_URL}`;
            this.editData.images = [
                ...this.editData.content.en.match(/img src="(.*?)"/g) || [],
                ...this.editData.content.zhTw.match(/img src="(.*?)"/g) || [],
            ].map((row) => row.replace(/img src="/, '').replace(/"$/, '').replace(imageBaseUrl, ''));
            this.editData.content.en = this.editData.content.en.replace(/blob:http/g, `${imageBaseUrl}blob:http`);
            this.editData.content.zhTw = this.editData.content.zhTw.replace(/blob:http/g, `${imageBaseUrl}blob:http`);
            this.editData.tags.zhTw = [];
            this.editData.tags.en = [];
            for (let i = 0; i < this.chooseTag.length; i += 1) {
                for (let j = 0; j < this.tagsOptions.length; j += 1) {
                    if (this.tagsOptions[j].value === this.chooseTag[i]) {
                        this.editData.tags.zhTw.push(this.tagsOptions[j].value);
                        this.editData.tags.en.push(this.tagsOptions[j].en);
                    }
                }
            }
            if (this.$refs.form.validate()) {
                this.$emit('saveData', this.editData);
                this.show = false;
            }
        },
    },
};
</script>

<style lang="scss">
</style>
