<template>
    <div class="dialog">
        <v-dialog
            v-model="show"
            max-width="80vw"
            persistent
            scrollable
        >
            <v-card>
                <CardTitle
                    :type="type"
                />

                <v-card-text>
                    <v-container>
                        <v-form
                            ref="form"
                            v-model="valid"
                        >
                            <div
                                v-if="type==='修改'"
                                class="title-box"
                            >
                                <span class="title">帳號：{{ editData.account }}</span>
                            </div>
                            <div class="title-box">
                                <span class="title">個人資料</span>
                            </div>
                            <v-row
                                class="text-box"
                            >
                                <v-col
                                    cols="4"
                                >
                                    <v-text-field
                                        v-model="editData.name"
                                        label="*姓名"
                                        outlined
                                        persistent-placeholder
                                        clearable
                                        :rules="emptyRule"
                                    />
                                </v-col>
                                <v-col
                                    v-if="type==='新增'"
                                    cols="4"
                                >
                                    <v-text-field
                                        v-model="editData.account"
                                        label="*帳號"
                                        outlined
                                        persistent-placeholder
                                        clearable
                                        :rules="emptyRule"
                                    />
                                </v-col>
                                <v-col
                                    v-if="type==='新增'"
                                    cols="4"
                                >
                                    <v-text-field
                                        v-model="editData.password"
                                        label="*密碼"
                                        outlined
                                        :type="showPWD ? 'text' : 'password'"
                                        :append-icon="showPWD ? 'mdi-eye' : 'mdi-eye-off'"
                                        persistent-placeholder
                                        clearable
                                        :rules="passwordRule"
                                        @click:append="showPWD = !showPWD"
                                    />
                                </v-col>
                            </v-row>
                            <div v-if="title==='invention'">
                                <div class="title-box">
                                    <span class="title">大專校院組</span>
                                </div>
                                <v-row
                                    class="text-box"
                                >
                                    <v-col
                                        cols="6"
                                    >
                                        <v-combobox
                                            v-model="editData.group.universities"
                                            :items="inventionItems"
                                            label="內容"
                                            chips
                                            outlined
                                            multiple
                                        />
                                    </v-col>
                                </v-row>
                                <div class="title-box">
                                    <span class="title">高中職組</span>
                                </div>
                                <v-row
                                    class="text-box"
                                >
                                    <v-col
                                        cols="6"
                                    >
                                        <v-combobox
                                            v-model="editData.group.senior"
                                            :items="inventionItems"
                                            label="內容"
                                            chips
                                            outlined
                                            multiple
                                        />
                                    </v-col>
                                </v-row>
                            </div>
                            <div v-if="title==='incubator'">
                                <div class="title-box">
                                    <span class="title">孵化組</span>
                                </div>
                                <v-row
                                    class="text-box"
                                >
                                    <v-col
                                        cols="6"
                                    >
                                        <v-combobox
                                            v-model="editData.group.incubation"
                                            :items="incubatorItems"
                                            label="內容"
                                            chips
                                            outlined
                                            multiple
                                        />
                                    </v-col>
                                </v-row>
                                <div class="title-box">
                                    <span class="title">加速組</span>
                                </div>
                                <v-row
                                    class="text-box"
                                >
                                    <v-col
                                        cols="6"
                                    >
                                        <v-combobox
                                            v-model="editData.group.acceleration"
                                            :items="incubatorItems"
                                            label="內容"
                                            chips
                                            outlined
                                            multiple
                                        />
                                    </v-col>
                                </v-row>
                                <div class="title-box">
                                    <span class="title">亞太地區</span>
                                </div>
                                <v-row
                                    class="text-box"
                                >
                                    <v-col
                                        cols="6"
                                    >
                                        <v-combobox
                                            v-model="editData.group.asia"
                                            :items="incubatorItems"
                                            label="內容"
                                            chips
                                            outlined
                                            multiple
                                        />
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions
                    class="justify-end"
                >
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeEdit"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="submitEdit"
                    >
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CardTitle from '@/components/UI/CardTitle.vue';
import dialogMixin from '@/mixins/dialogMixin.vue';

export default {
    components: { CardTitle },
    mixins: [dialogMixin],
    props: {
        title: {
            type: String,
            default: 'invention',
        },
    },
    data() {
        return {
            showPWD: false,
            valid: false,
            emptyRule: [(v) => !!v || '此欄位為必填'],
            passwordRule: [
                (v) => !!v || '此欄位為必填',
                (v) => (v && v.length >= 6) || '密碼最少6個字',
                (v) => !v.match(/\s/) || '輸入不可包含空格',
            ],
            editData: {
                account: '',
                password: '',
                name: '',
                group: {
                    universities: '',
                    senior: '',
                    incubation: '',
                    acceleration: '',
                    asia: '',
                },
            },
            type: '',
            incubatorItems: ['慈善創新', '醫療照護', '教育創新', '環境保護', '青銀共創', '循環經濟', '食糧供應創新', '災害防救創新', '地方創生/社區營造'],
            inventionItems: ['慈善', '醫療'],
        };
    },
    computed: {
    },
    created() {},
    mounted() {},
    methods: {
        editItem(data) {
            if (data) {
                this.type = '修改';
                this.editData = {
                    id: data.id,
                    account: data.account,
                    name: data.data.name,
                    group: {
                        universities: data.data.groupIssues['大專校院組'],
                        senior: data.data.groupIssues['高中職組'],
                        incubation: data.data.groupIssues['臺灣團隊-孵化組'],
                        acceleration: data.data.groupIssues['臺灣團隊-加速組'],
                        asia: data.data.groupIssues['亞太地區團隊'],
                    },
                };
            } else {
                this.type = '新增';
                this.editData = {
                    account: '',
                    password: '',
                    name: '',
                    group: {
                        universities: '',
                        senior: '',
                        incubation: '',
                        acceleration: '',
                        asia: '',
                    },
                };
            }
        },
        closeEdit() {
            this.show = false;
            this.$emit('search');
        },
        submitEdit() {
            if (this.$refs.form.validate()) {
                this.$emit('saveData', this.editData);
                this.show = false;
            }
        },
    },
};
</script>

<style lang="scss">
</style>
