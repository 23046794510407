<template>
    <div class="study-banner page">
        <Add
            @editData="editData"
        />
        <div class="table-block">
            <div class="total">
                總共: {{ total }} 筆資料
            </div>
            <v-data-table
                :headers="ApplyHeader"
                :items="list"
                :options.sync="options"
                :items-per-page="10"
                :server-items-length="total"
                :loading="loading"
                class="data-table"
                :footer-props="footerProps"
            >
                <template v-slot:item.imageDes.zhTw="{ item }">
                    <div class="hidden-img">
                        <v-img
                            :src="image(item.imageDes.zhTw)"
                            class="data-img"
                        />
                    </div>
                </template>
                <template v-slot:item.imageMob.zhTw="{ item }">
                    <div class="hidden-mob-img">
                        <v-img
                            :src="image(item.imageMob.zhTw)"
                            class="data-img"
                        />
                    </div>
                </template>
                <template v-slot:item.imageDes.en="{ item }">
                    <div class="hidden-img">
                        <v-img
                            :src="image(item.imageDes.en)"
                            class="data-img"
                        />
                    </div>
                </template>
                <template v-slot:item.imageMob.en="{ item }">
                    <div class="hidden-mob-img">
                        <v-img
                            :src="image(item.imageMob.en)"
                            class="data-img"
                        />
                    </div>
                </template>
                <template v-slot:item.operate="{ item }">
                    <v-btn
                        class="mx-1"
                        dark
                        small
                        depressed
                        :disabled="isOperate"
                        color="green"
                        @click="editData(item)"
                    >
                        <v-icon dark>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                    <v-btn
                        class="mx-1 delete-btn"
                        dark
                        small
                        depressed
                        :disabled="isOperate"
                        color="red"
                        @click="deleteData(item)"
                    >
                        <v-icon dark>
                            mdi-trash-can
                        </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </div>
        <BannerDialog
            ref="child"
            :edit-dialog="editDialog"
            @close="closeEdit"
            @saveEdit="saveEdit"
            @saveData="saveData"
            @search="search"
        />
    </div>
</template>

<script>
import API from '@/api';
import Mixin from '@/mixins/Mixin.vue';
import APIFormData from '@/api/libs/form-data';
import ApplyHeader from '@/static-data/banner-apply-header.json';
import BannerDialog from '@/components/Dialog/BannerDialog.vue';

export default {
    components: { BannerDialog },
    mixins: [Mixin],
    data() {
        return {
            ApplyHeader,
        };
    },
    watch: {
        options: {
            handler() {
                this.search();
            },
            deep: true,
        },
    },
    created() {
        this.search();
    },
    mounted() {},
    methods: {
        async deleteData(item) {
            this.isOperate = true;
            try {
                // 刪除
                await API.BookBanner.PrivateDeleteResource(item.id);
            } catch (e) {
                this.handleError({
                    message: e || '刪除異常',
                    color: 'error',
                });
            }
            this.search();
        },
        async search() {
            const { page, itemsPerPage } = this.options;
            this.loading = true;
            try {
                const res = await API.BookBanner.PrivateList({
                    params: {
                        offset: (page - 1) * itemsPerPage,
                        limit: itemsPerPage,
                    },
                });
                this.list = res.data.raw;
                this.total = res.data.total;
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.handleError({
                    message: e || '資料異常',
                    color: 'error',
                });
            }
            this.isOperate = false;
        },
        async saveData(item) {
            const data = {
                imageDes: {
                    zhTw: item.imageDes.zhTw,
                    en: item.imageDes.en,
                },
                imageMob: {
                    zhTw: item.imageMob.zhTw,
                    en: item.imageMob.en,
                },
            };
            const form = new APIFormData(data, [{
                urls: [
                    item.imageDes.zhTw,
                    item.imageDes.en,
                    item.imageMob.zhTw,
                    item.imageMob.en,
                ],
            }]);
            try {
                if (item.id) {
                // 修改
                    await API.BookBanner.PrivateUpdateResource(item.id, form);
                    this.setAlert({
                        message: '更新成功',
                        color: 'success',
                    });
                } else {
                // 新增
                    await API.BookBanner.PrivateCreateResource(form);
                    this.setAlert({
                        message: '新增成功',
                        color: 'success',
                    });
                }
            } catch (e) {
                this.handleError({
                    message: e || '新增修改異常',
                    color: 'error',
                });
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
.study-banner{
    .table-block{
        .data-img{
            width: 100%;
        }
    }
}

</style>
