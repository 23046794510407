<template>
    <div class="dialog">
        <v-dialog
            v-model="show"
            max-width="80vw"
            persistent
            scrollable
        >
            <v-card>
                <CardTitle
                    :type="type"
                />
                <v-card-text>
                    <v-container>
                        <v-form
                            ref="form"
                            v-model="valid"
                        >
                            <div class="title-box">
                                <span class="title">中文版本</span>
                            </div>
                            <v-row
                                class="text-box"
                            >
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="editData.name.zhTw"
                                        label="*姓名"
                                        outlined
                                        persistent-placeholder
                                        clearable
                                        :rules="[v => !!v || '此欄位為必填']"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="editData.unit.zhTw"
                                        label="*單位"
                                        outlined
                                        persistent-placeholder
                                        clearable
                                        :rules="[v => !!v || '此欄位為必填']"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="editData.position.zhTw"
                                        label="*職位"
                                        outlined
                                        persistent-placeholder
                                        clearable
                                        :rules="[v => !!v || '此欄位為必填']"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-input
                                        class="file-box"
                                    >
                                        <v-file-input
                                            v-model="fileImg_zhTw"
                                            prepend-icon="mdi-camera"
                                            accept="image/png, image/jpeg"
                                            outlined
                                            class="file-input"
                                            :rules="[v => v.length===1 || '圖片不能為空']"
                                            @change="(value) => selectFile(value,'zhTw')"
                                        />
                                        <div
                                            class="file-img"
                                        >
                                            <v-img
                                                :src="image(editData.image.zhTw)"
                                                height="280px"
                                                width="fit-content"
                                                loading="true"
                                                contain
                                                position="left"
                                                class="change-img"
                                            >
                                                <v-btn
                                                    v-if="showUploadImg_zhTw"
                                                    width="280px"
                                                    height="280px"
                                                >
                                                    <v-icon>mdi-upload</v-icon>
                                                    *上傳圖片(建議 W:280px H:280px)
                                                </v-btn>
                                            </v-img>
                                        </div>
                                        <div
                                            class="image-delete-btn"
                                        >
                                            <v-btn
                                                class="mx-2"
                                                fab
                                                dark
                                                small
                                                color="grey"
                                                @click="clear('zhTw')"
                                            >
                                                <v-icon dark>
                                                    mdi-close-thick
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-input>
                                </v-col>
                            </v-row>
                            <div class="title-box">
                                <span class="title">英文版本</span>
                            </div>
                            <v-row
                                class="text-box"
                            >
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="editData.name.en"
                                        label="*姓名"
                                        outlined
                                        persistent-placeholder
                                        clearable
                                        :rules="[v => !!v || '此欄位為必填']"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="editData.unit.en"
                                        label="*單位"
                                        outlined
                                        persistent-placeholder
                                        clearable
                                        :rules="[v => !!v || '此欄位為必填']"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="editData.position.en"
                                        label="*職位"
                                        outlined
                                        persistent-placeholder
                                        clearable
                                        :rules="[v => !!v || '此欄位為必填']"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-input
                                        class="file-box"
                                    >
                                        <v-file-input
                                            v-model="fileImg_en"
                                            prepend-icon="mdi-camera"
                                            accept="image/png, image/jpeg"
                                            outlined
                                            class="file-input"
                                            :rules="[v => v.length===1 || '圖片不能為空']"
                                            @change="(value) => selectFile(value,'en')"
                                        />
                                        <div
                                            class="file-img"
                                        >
                                            <v-img
                                                :src="image(editData.image.en)"
                                                height="280px"
                                                width="fit-content"
                                                loading="true"
                                                contain
                                                position="left"
                                                class="change-img"
                                            >
                                                <v-btn
                                                    v-if="showUploadImg_en"
                                                    width="280px"
                                                    height="280px"
                                                >
                                                    <v-icon>mdi-upload</v-icon>
                                                    *上傳圖片(建議 W:280px H:280px)
                                                </v-btn>
                                            </v-img>
                                        </div>
                                        <div
                                            class="image-delete-btn"
                                        >
                                            <v-btn
                                                class="mx-2"
                                                fab
                                                dark
                                                small
                                                color="grey"
                                                @click="clear('en')"
                                            >
                                                <v-icon dark>
                                                    mdi-close-thick
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-input>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions
                    class="justify-end"
                >
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeEdit"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="submitEdit"
                    >
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CardTitle from '@/components/UI/CardTitle.vue';
import dialogMixin from '@/mixins/dialogMixin.vue';

export default {
    components: { CardTitle },
    mixins: [dialogMixin],
    data() {
        return {
            showUploadImg_zhTw: true,
            showUploadImg_en: true,
            valid: false,
            editData: {
                image: {
                    zhTw: '',
                    en: '',
                },
                name: {
                    zhTw: '',
                    en: '',
                },
                unit: {
                    zhTw: '',
                    en: '',
                },
                position: {
                    zhTw: '',
                    en: '',
                },
            },
            type: '',
            fileImg_zhTw: [],
            fileImg_en: [],
        };
    },
    computed: {
    },
    created() {},
    mounted() {},
    methods: {
        editItem(data) {
            if (data) {
                this.type = '修改';
                this.editData = data;
                this.showUploadImg_zhTw = false;
                this.showUploadImg_en = false;
                this.fileImg_zhTw = [{}];
                this.fileImg_en = [{}];
            } else {
                this.type = '新增';
                this.editData = {
                    image: {
                        zhTw: '',
                        en: '',
                    },
                    name: {
                        zhTw: '',
                        en: '',
                    },
                    unit: {
                        zhTw: '',
                        en: '',
                    },
                    position: {
                        zhTw: '',
                        en: '',
                    },
                };
            }
        },
        closeEdit() {
            this.show = false;
            this.$emit('search');
            this.fileImg_zhTw = [];
            this.fileImg_en = [];
        },
        selectFile(file, data) {
            if (file && (file.constructor === File || file.constructor === Blob)) {
                this.editData.image[`${data}`] = window.URL.createObjectURL(file);
                this[`fileImg_${data}`] = [{}];
                this[`showUploadImg_${data}`] = false;
            }
        },
        clear(data) {
            this.editData.image[`${data}`] = '';
            this[`fileImg_${data}`] = [];
            this[`showUploadImg_${data}`] = true;
        },
        submitEdit() {
            if (this.$refs.form.validate()) {
                this.$emit('saveData', this.editData);
                this.show = false;
            }
        },
    },
};
</script>

<style lang="scss">
.file-box .v-input__slot {
    height: 279px !important;
}
</style>
