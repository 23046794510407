import Vue from 'vue';
import API from '@/api';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.prototype.$bus = new Vue();
Vue.prototype.$bus.$on('updateToken', (token) => {
    store.dispatch('mAuth/', token);
});
Vue.prototype.$static = API.Static;
store.dispatch('mAuth/Sync', localStorage.getItem('token'));

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
