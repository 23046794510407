var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"study-report page"},[_c('Search',{attrs:{"text":'標題/描述'},on:{"search":_vm.search}}),_c('Add',{on:{"editData":_vm.editData}}),_c('div',{staticClass:"table-block"},[_c('div',{staticClass:"total"},[_vm._v(" 總共: "+_vm._s(_vm.total)+" 筆資料 ")]),_c('v-data-table',{staticClass:"data-table",attrs:{"headers":_vm.ApplyHeader,"items":_vm.list,"options":_vm.options,"items-per-page":10,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.image.zhTw",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"data-img",attrs:{"src":_vm.image(item.image.zhTw)}})]}},{key:"item.image.en",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"data-img",attrs:{"src":_vm.image(item.image.en)}})]}},{key:"item.pdf.zhTw",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.pdf.zhTw,"target":"_blank"}},[_vm._v(_vm._s(item.pdf.zhTw)+" ")])]}},{key:"item.pdf.en",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.pdf.en,"target":"_blank"}},[_vm._v(_vm._s(item.pdf.en)+" ")])]}},{key:"item.operate",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"dark":"","small":"","depressed":"","disabled":_vm.isOperate,"color":"green"},on:{"click":function($event){return _vm.editData(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"mx-1 delete-btn",attrs:{"dark":"","small":"","depressed":"","disabled":_vm.isOperate,"color":"red"},on:{"click":function($event){return _vm.deleteData(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-trash-can ")])],1)]}}])})],1),_c('RouterDialog',{ref:"child",attrs:{"show-pdf":true,"edit-dialog":_vm.editDialog},on:{"close":_vm.closeEdit,"saveEdit":_vm.saveEdit,"saveData":_vm.saveData,"search":_vm.search}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }