<template>
    <div class="invention-manage-apply page">
        <div class="filter">
            <div class="select-box">
                <v-select
                    v-model="filter.level"
                    class="filter-select"
                    :items="levelOptions"
                    label="參賽組別"
                    item-text="label"
                    item-value="value"
                    outlined
                    clearable
                />
                <v-select
                    v-model="filter.status"
                    class="filter-select"
                    :items="statusOptions"
                    label="狀態"
                    item-text="label"
                    item-value="value"
                    outlined
                    clearable
                />
                <v-select
                    v-model="filter.session"
                    class="filter-select"
                    :items="sessionOptions"
                    label="屆數"
                    item-text="label"
                    item-value="value"
                    outlined
                    clearable
                />
            </div>
            <v-text-field
                v-model="filter.text"
                label="團隊名稱/學校名稱/作品名稱"
                placeholder="團隊名稱/學校名稱/作品名稱"
                outlined
                persistent-placeholder
                clearable
                class="search-input"
                @keypress.enter="searchApply"
            />
            <div class="btn-box">
                <v-btn
                    class="btn"
                    color="primary"
                    @click="searchApply"
                >
                    搜尋
                </v-btn>
                <v-btn
                    class="btn"
                    color="success"
                    @click="downloadExcel"
                >
                    下載 Excel
                </v-btn>
            </div>
        </div>
        <div class="table-block">
            <div class="total">
                總共: {{ total }} 筆資料
            </div>
            <v-data-table
                :headers="ApplyHeader"
                :items="list"
                :options.sync="options"
                :items-per-page="10"
                :server-items-length="total"
                :loading="loading"
                class="data-table"
                :footer-props="footerProps"
            >
                <template v-slot:item.proposal.main="{ item }">
                    {{ item.proposal.main.split(" ")[0] }}
                </template>
                <template v-slot:item.total="{ item }">
                    {{ item.judges.total?item.judges.total:'-' }}
                </template>
                <template v-slot:item.allowJudge="{ item }">
                    {{ item.allowJudge&&item.status==='submit'?'v':'' }}
                </template>
                <template v-slot:item.status="{ item }">
                    <v-tooltip
                        v-if="item.status==='submit' && item.allowJudge"
                        bottom
                        content-class="tooltips"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                text
                                class="status"
                                v-bind="attrs"
                                style="padding: 10px;min-width: auto;"
                                v-on="on"
                            >
                                {{ statusFilter(item) }}
                            </v-btn>
                        </template>
                        <span>{{ getJudges(item) }}</span>
                    </v-tooltip>
                    <div v-else-if="item.status==='submit' && !item.allowJudge">
                        送出審核
                    </div>
                    <div
                        v-else
                        style="padding: 10px;"
                    >
                        {{ statusFilter(item) }}
                    </div>
                </template>
                <template v-slot:item.detail="{ item }">
                    <a
                        @click="detailUrl(item.id)"
                    >詳細內容</a>
                </template>
                <template v-slot:item.operate="{ item }">
                    <v-btn
                        v-if="showDowngradeBtn(item.status)"
                        class="mx-1"
                        color="warning"
                        @click="downgrade(item.id)"
                    >
                        {{ downgradeName(item.status) }}
                    </v-btn>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import API from '@/api';
import ApplyHeader from '@/static-data/invention-manage-apply-header.json';
import { mapMutations } from 'vuex';
import FileSaver from 'file-saver';

export default {
    components: {},
    data() {
        return {
            loading: true,
            ApplyHeader,
            list: [],
            options: {
                page: 1,
                itemsPerPage: 10,
            },
            total: 0,
            footerProps: {
                'items-per-page-options': [5, 10, 15, 20],
            },
            filter: {
                status: null,
                level: null,
                text: null,
                session: null,
            },
            statusOptions: [
                { label: '草稿', value: 'draft' },
                { label: '送出審核', value: 'submit' },
                { label: '已評分', value: 'allow' },
                { label: '全部', value: null },
            ],
            levelOptions: [
                { label: '大專校院組', value: '大專校院組' },
                { label: '高中職組', value: '高中職組' },
                { label: '全部', value: null },
            ],
            sessionOptions: [],
        };
    },
    computed: {
    },
    watch: {
        options: {
            handler() {
                this.searchApply();
            },
            deep: true,
        },
    },

    created() {
        this.searchApply();
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapMutations('mNotification', ['handleError']),
        ...mapMutations('mLoading', ['setLoading']),
        showDowngradeBtn(status) {
            switch (status) {
                case 'submit':
                    return true;
                case 'final':
                    return true;
                default:
                    return false;
            }
        },
        detailUrl(id) {
            this.$router.push({ name: 'InventionDetail', params: { id } });
        },
        statusFilter(item) {
            switch (item.status) {
                case 'draft':
                    return '草稿';
                case 'submit':
                    return `已評分(${item.judges.submit ? item.judges.submit?.length : 0}/${item.judges.all ? item.judges.all?.length : 0})`;
                default:
                    return '';
            }
        },
        getJudges(item) {
            const { judges } = item;
            if (judges.all) {
                const judged = judges.all.filter((judge) => judges.submit.includes(judge));
                const notJudged = judges.all.filter((judge) => !judges.submit.includes(judge));
                const judgedStr = judged.map((judge) => `${judge} 已評分`).join(', ');
                const notJudgedStr = notJudged.map((judge) => `${judge} 未評分`).join(', ');
                let result = '';
                if (judgedStr) result += judgedStr;
                if (notJudgedStr) {
                    if (result) result += `, ${notJudgedStr}`;
                    else result += notJudgedStr;
                }
                return result;
            }
            return '';
        },
        async init() {
            try {
                const res = await API.InventionApply.PrivateList();
                const session = [];
                const Options = [{
                    label: '全部',
                    value: null,
                }];
                for (let i = 0; i < res.data.total; i += 1) {
                    if (res.data.raw[i]) {
                        if (res.data.raw[i].session) {
                            if (!session.includes(res.data.raw[i].session)) {
                                session.push(res.data.raw[i].session);
                                Options.push({
                                    label: res.data.raw[i].session,
                                    value: res.data.raw[i].session,
                                });
                            }
                        }
                    }
                }
                this.sessionOptions = Options;
            } catch (e) {
                this.handleError({
                    message: e || '屆數數據異常',
                    color: 'error',
                });
            }
        },
        async searchApply() {
            const { page, itemsPerPage } = this.options;
            this.loading = true;
            const filter = [];
            if (this.filter.level) {
                filter.push(`proposal.level:${this.filter.level}`);
            }
            if (this.filter.status) {
                if (this.filter.status === 'allow') {
                    filter.push('status:submit');
                    filter.push('allowJudge:true');
                } else if (this.filter.status === 'submit') {
                    filter.push('status:submit');
                    filter.push('allowJudge:json:{"$ne":true}');
                } else {
                    filter.push(`status:${this.filter.status}`);
                }
            }
            if (this.filter.session) {
                filter.push(`session:${this.filter.session}`);
            }
            try {
                const res = await API.InventionApply.PrivateList({
                    params: {
                        offset: (page - 1) * itemsPerPage,
                        limit: itemsPerPage,
                        filter: filter.join(';'),
                        search: this.filter.text ? this.filter.text : undefined,
                    },
                });
                this.list = res.data.raw;
                this.total = res.data.total;
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.handleError({
                    message: e || '連線異常',
                    color: 'error',
                });
            }
        },
        async downloadExcel() {
            try {
                this.searchApply();
                this.setLoading(true);
                const filter = [];
                if (this.filter.level) {
                    filter.push(`proposal.level:${this.filter.level}`);
                }
                if (this.filter.status) {
                    if (this.filter.status === 'allow') {
                        filter.push('status:submit');
                        filter.push('allowJudge:true');
                    } else if (this.filter.status === 'submit') {
                        filter.push('status:submit');
                        filter.push('allowJudge:json:{"$ne":true}');
                    } else {
                        filter.push(`status:${this.filter.status}`);
                    }
                }
                if (this.filter.session) {
                    filter.push(`session:${this.filter.session}`);
                }
                const res = await API.InventionApplyUtil.Download({
                    filter: filter.join(';'),
                    search: this.filter.text ? this.filter.text : undefined,
                });
                const fileName = `慈悲科技競賽報名資料_${moment().format('YYYY-MM-DD')}.xlsx`;
                FileSaver.saveAs(res.data, fileName);
                this.setLoading(false);
            } catch (e) {
                this.setLoading(false);
                this.handleError({
                    message: e || '連線異常',
                    color: 'error',
                });
            }
        },
        async downgrade(id) {
            this.setLoading(true);
            try {
                await API.InventionApplyUtil.DowngradeStatus(id);
                this.searchApply();
                this.setLoading(false);
            } catch (e) {
                console.log(e.message);
                this.handleError({
                    message: e || '連線異常',
                    color: 'error',
                });
                this.setLoading(false);
            }
        },
        downgradeName(status) {
            switch (status) {
                case 'submit':
                    return '退回草稿';
                case 'final':
                    return '退回已送出';
                default:
                    return '';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.table-block {
    padding: 10px 20px;
}

.select-box {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .filter-select {
        margin: 0 10px;
        width: 200px;
    }
}

.search-input {
    margin: 0 10px !important;
}

.btn-box {
    margin: 0 10px;

    .btn {
        margin-right: 15px;
    }
}
.tooltips{
    color: #000;
    background-color: #fff;
    border-radius: 40px;
    text-align: center;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
    max-width: 600px;
}
</style>
