<template>
    <div class="session page">
        <v-card
            class="mx-auto"
            tile
        >
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>說明：</v-list-item-title>
                    <br>
                    <v-list-item-subtitle>設定為當前屆數，此屆數當作為報名屆數。</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-card>
        <Add
            @editData="editData"
        />
        <div class="table-block">
            <div class="total">
                總共: {{ total }} 筆資料
            </div>
            <v-data-table
                :headers="ApplyHeader"
                :items="list"
                :options.sync="options"
                :items-per-page="10"
                :server-items-length="total"
                :loading="loading"
                class="data-table"
                :footer-props="footerProps"
            >
                <template v-slot:item.startTime="{ item }">
                    <span>{{ getDate(item.startTime) }}</span>
                </template>
                <template v-slot:item.endTime="{ item }">
                    <span>{{ getDate(item.endTime) }}</span>
                </template>
                <template v-slot:item.active="{ item }">
                    <v-icon
                        v-if="item.active"
                        large
                        color="green darken-2"
                    >
                        mdi-check-circle-outline
                    </v-icon>
                </template>
                <template v-slot:item.operate="{ item }">
                    <v-btn
                        class="mx-1"
                        dark
                        small
                        depressed
                        :disabled="isOperate || item.active"
                        color="blue"
                        @click="getActive(item)"
                    >
                        設定為當前屆數
                    </v-btn>
                    <v-btn
                        class="mx-1"
                        dark
                        small
                        depressed
                        :disabled="isOperate"
                        color="green"
                        @click="editData(item)"
                    >
                        <v-icon dark>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                    <v-btn
                        class="mx-1 delete-btn"
                        dark
                        small
                        depressed
                        :disabled="isOperate"
                        color="red"
                        @click="deleteData(item)"
                    >
                        <v-icon dark>
                            mdi-trash-can
                        </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </div>
        <SessionDialog
            ref="child"
            :edit-dialog="editDialog"
            @close="closeEdit"
            @saveEdit="saveEdit"
            @saveData="saveData"
            @search="search"
        />
    </div>
</template>

<script>
import moment from 'moment';
import API from '@/api';
import Mixin from '@/mixins/Mixin.vue';
import ApplyHeader from '@/static-data/incubator-manage-session-apply-header.json';
import SessionDialog from '@/components/Dialog/SessionDialog.vue';

export default {
    components: { SessionDialog },
    mixins: [Mixin],
    data() {
        return {
            ApplyHeader,
        };
    },
    watch: {
        options: {
            handler() {
                this.search();
            },
            deep: true,
        },
    },
    created() {
        this.search();
    },
    mounted() {},
    methods: {
        async deleteData(item) {
            this.isOperate = true;
            try {
                // 刪除
                await API.IncubatorSession.PrivateDeleteResource(item.id);
            } catch (e) {
                this.handleError({
                    message: e || '刪除異常',
                    color: 'error',
                });
            }
            this.search();
        },
        async search() {
            // 搜尋
            const { page, itemsPerPage } = this.options;
            this.loading = true;
            try {
                const res = await API.IncubatorSession.PrivateList({
                    params: {
                        offset: (page - 1) * itemsPerPage,
                        limit: itemsPerPage,
                    },
                });
                this.list = res.data.raw;
                this.total = res.data.total;
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.handleError({
                    message: e || '搜尋異常',
                    color: 'error',
                });
            }
            this.isOperate = false;
        },
        async saveData(item) {
            const data = {
                session: item.session.zhTw,
                sessionEN: item.session.en,
                startTime: moment(`${item.startDate} ${item.startTime}:00`),
                endTime: moment(`${item.endDate} ${item.endTime}:59`),
                active: item.active,
                year: item.year,
            };
            try {
                if (item.id) {
                // 修改
                    await API.IncubatorSession.PrivateUpdateResource(item.id, data);
                    this.setAlert({
                        message: '更新成功',
                        color: 'success',
                    });
                } else {
                // 新增
                    await API.IncubatorSession.PrivateCreateResource(data);
                    this.setAlert({
                        message: '新增成功',
                        color: 'success',
                    });
                }
            } catch (e) {
                this.handleError({
                    message: e || '新增修改異常',
                    color: 'error',
                });
            }
            this.search();
        },
        async getActive(item) {
            try {
                await API.IncubatorSessionUtil.Active(item.id);
                this.search();
            } catch (e) {
                this.loading = false;
                this.handleError({
                    message: e || '當前屆數異常',
                    color: 'error',
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
